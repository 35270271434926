import {LocalDateTime, Option} from "@utils";

export class CertificateResultInfo {
  constructor(readonly name: string) {}

  static copy(other: CertificateResultInfo): CertificateResultInfo {
    return new CertificateResultInfo(other.name);
  }
}

export class SignatureStrength {
  constructor(readonly name: string) {}

  static copy(other: CertificateResultInfo): SignatureStrength {
    switch (other.name) {
      case SignatureStrength.qualified.name: return SignatureStrength.qualified;
      case SignatureStrength.advanced.name: return SignatureStrength.advanced;
      case SignatureStrength.advancedSupportedByQualified.name: return SignatureStrength.advancedSupportedByQualified;
      case SignatureStrength.notAdvanced.name: return SignatureStrength.notAdvanced;
      case SignatureStrength.notAdvancedSupportedByQualified.name: return SignatureStrength.notAdvancedSupportedByQualified;
      case SignatureStrength.notApplicable.name: return SignatureStrength.notApplicable;
      default: throw new Error("Incorrect SignatureStrength ["+other.name+"]");
    }
  }

  static qualified = new SignatureStrength("qualified");
  static advanced = new SignatureStrength("advanced");
  static advancedSupportedByQualified = new SignatureStrength("advancedSupportedByQualified");
  static notAdvanced = new SignatureStrength("notAdvanced");
  static notAdvancedSupportedByQualified = new SignatureStrength("notAdvancedSupportedByQualified");
  static notApplicable = new SignatureStrength("notApplicable");
}

export class SignOrSeal {
  constructor(readonly name: string) {}

  static copy(other: SignOrSeal): SignOrSeal {
    switch (other.name) {
      case SignOrSeal.sign.name: return SignOrSeal.sign;
      case SignOrSeal.seal.name: return SignOrSeal.seal;
      case SignOrSeal.signOrSeal.name: return SignOrSeal.signOrSeal;
      default: throw new Error("Incorrect SignOrSeal ["+other.name+"]");
    }
  }

  static sign = new SignOrSeal("sign");
  static seal = new SignOrSeal("seal");
  static signOrSeal = new SignOrSeal("signOrSeal");
}

export class DigitalSignatureResult {
  constructor(readonly name: string) {}
  static verified = new DigitalSignatureResult("correct");
  static incorrect = new DigitalSignatureResult("incorrect");
  static unsure = new DigitalSignatureResult("unsure");

  static copy(other: DigitalSignatureResult): DigitalSignatureResult {
    switch (other.name) {
      case DigitalSignatureResult.verified.name: return DigitalSignatureResult.verified;
      case DigitalSignatureResult.incorrect.name: return DigitalSignatureResult.incorrect;
      case DigitalSignatureResult.unsure.name: return DigitalSignatureResult.unsure;
      default: throw new Error("Unsupported DigitalSignatureResult ["+other.name+"]");
    }
  }
}

export class SignatureResultInfo {
  constructor(
    readonly signatureStrength: SignatureStrength,
    readonly signOrSeal: SignOrSeal,
    readonly indetermined: boolean,
    readonly valid: boolean,
    readonly validationTime: LocalDateTime,
    readonly result: DigitalSignatureResult,
    readonly indication: string,
    readonly subIndication: Option<string>,
    readonly certificateChain: Array<CertificateResultInfo>,
    readonly signerName: string,
    readonly reason: string,
    readonly city: string,
    readonly signedBy: string,
    readonly signedTime: LocalDateTime,
    readonly format: string,
    readonly qualification: string,
    readonly infos: Array<string>,
    readonly warnings: Array<string>,
    readonly errors: Array<string>) {}

  static copy(other: SignatureResultInfo): SignatureResultInfo {
    return new SignatureResultInfo(
      SignatureStrength.copy(other.signatureStrength),
      SignOrSeal.copy(other.signOrSeal),
      other.indetermined,
      other.valid,
      LocalDateTime.copy(other.validationTime),
      DigitalSignatureResult.copy(other.result),
      other.indication,
      Option.copy(other.subIndication),
      other.certificateChain.map(CertificateResultInfo.copy),
      other.signerName,
      other.reason,
      other.city,
      other.signedBy,
      LocalDateTime.copy(other.signedTime),
      other.format,
      other.qualification,
      other.infos.slice(),
      other.warnings.slice(),
      other.errors.slice()
    )
  }


  isQualified() {
    return this.signatureStrength.name == SignatureStrength.qualified.name;
  }

  isSeal() {
    return this.signOrSeal.name === SignOrSeal.seal.name;
  }

  isOK() {
    return this.indication == "TOTAL_PASSED" || this.indication == "PASSED";
  }

  isWarn() {
    return this.indication == "INDETERMINATE";
  }

  isError() {
    return this.indication == "TOTAL_FAILED" || this.indication == "FAILED";
  }

  summaryText() {
    if(this.signerName.length > 0) {
      return this.signerName;
    }
    if(this.signedBy.length > 0) {
      return this.signedBy;
    }
    return this.reason;
  }
}
