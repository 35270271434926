import {Injectable} from "@angular/core";
import {
  NavigationBehaviorOptions,
  NavigationCancel,
  NavigationEnd,
  NavigationSkipped,
  NavigationStart,
  Params,
  Router
} from "@angular/router";
import {Location} from "@angular/common";
import {Subject} from "rxjs";
import {
  __,
  ApplicationId,
  mySetTimeoutNoAngular, ProcessId,
  randomString,
  removeHashFromUrl, ReportId,
  ResolvablePromise,
  ScreenId
} from "@utils";

interface HistoryAction {
  name: string;
  action: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  private readonly MAX_DELAY = 500;

  lastRequestedUrl: string|null = null;
  private anyNavigationSubject = new Subject<void>;
  readonly anyNavigationObservable = this.anyNavigationSubject.asObservable();

  private navigationEndSubject = new Subject<void>;
  readonly navigationEndObservable = this.navigationEndSubject.asObservable();

  private temporaryStateStack: Array<TemporaryState> = [];

  private lastUrlNoHash: string = "";

  private historyActionsQueue: Array<HistoryAction> = [];
  private actionExecutionInProgress: boolean = false;

  private navigationListener?: ResolvablePromise<void>;
  private navigationInProgress: boolean = false;

  constructor(private readonly router: Router,
              private readonly location: Location) {

    this.router.events.subscribe(event => {

      this.anyNavigationSubject.next();

      // console.log("NavigationService event", event.type, event);

      if(event instanceof NavigationSkipped && this.navigationListener && !this.navigationListener.resolved) {
        this.navigationListener.resolve();
      }

      if(event instanceof NavigationStart || event instanceof NavigationSkipped) {
        this.navigationInProgress = true;
        // NavigationSkipped happens when navigation is triggered by popstate (back/forward button)
        if(event instanceof NavigationSkipped || (event instanceof NavigationStart && event.navigationTrigger === "popstate")) {
          this.checkTemporaryState(event.url);
        }
      } else if(event instanceof NavigationEnd) {
        this.navigationInProgress = false;
        const urlNoHash = event.url.split("#@")[0];
        const hasHash = urlNoHash !== event.url;
        if(this.lastUrlNoHash !== urlNoHash) {
          this.temporaryStateStack = [];
        }
        this.lastUrlNoHash = urlNoHash;
        this.navigationEndSubject.next();


        // This is to clear remaining hash from url after navigation if it is not correlated with popup handling (e.g. page refresh)
        // if navigation happened but there is hash, but stack is empty, so we need to clear hash
        if(hasHash && this.temporaryStateStack.length === 0) {
          console.log("Replace state");
          this.location.replaceState(urlNoHash);
        }

      } else if (event instanceof NavigationCancel) {
        this.navigationInProgress = false;
      }
    });
  }

  navigateToLastRequestedPageOrMainPage(excludePage: Array<string>) {

    const lastRequestedUrl = this.lastRequestedUrl;
    if (lastRequestedUrl !== null && !excludePage.some(page => lastRequestedUrl.startsWith(page))) {
      this.router.navigateByUrl(lastRequestedUrl);
    } else {
      this.router.navigateByUrl("/");
    }
  }

  setLastRequestedUrl(url: string) {
    this.lastRequestedUrl = url;
  }

  clearLastRequestedUrl() {
    this.lastRequestedUrl = null;
  }

  /**
   * This is to refresh page, whithout reloading whole angular app
   */
  refreshPage() {
    if(!this.navigationInProgress) {

      // Refreshes current page by changing routing behavior, and reverting it after reload (tested in Angular 14)
      const currentUrl = this.router.url;
      const prevShouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
      const prevOnSameUrlNavigation = this.router.onSameUrlNavigation;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]).then(() => {
        this.router.onSameUrlNavigation = prevOnSameUrlNavigation;
        this.router.routeReuseStrategy.shouldReuseRoute = prevShouldReuseRoute;
      });
    }
  }

  /**
   * This forces full reload (initializing whole angular app)
   */
  reloadPage() {
    console.log("Navigation reload page")
    window.location.reload();
  }

  // this changes the URL, but does not trigger the route change
  replaceCurrentUrl(url: string) {
    this.location.replaceState(url);
    // window.history.replaceState({}, '', url);
  }

  navigateByUrlReplaceCurrent(url: string) {
    this.router.navigateByUrl(url, {replaceUrl: true});
  }

  currentUrlNoHash() {
    return removeHashFromUrl(this.router.url);
  }

  navigateByUrl(url: string, extras?: NavigationBehaviorOptions) {
    return this.router.navigateByUrl(url, extras);
  }

  navigateQueryParamsOnly(params: Params) {
    this.router.navigate([], {
      queryParams: params
    });
  }


  pushTemporaryState(onRemoved: () => void) {
    const id: string = randomString(8);
    // let url = this.router.url;

    this.historyActionsQueue.push({
      name: "push",
      action: () => {
        let url = location.href.substr(location.href.indexOf(location.host)+location.host.length);
        if(url.endsWith("/.")) {
          url = url.substring(0, url.length - 1);
          // override for custom navigation in documents
        }
        //console.log((Date.now() % 10000) + " pushTemporaryState " + id + " current url: " + url);

        if(this.temporaryStateStack.length > 0) {

          const lastId = __(this.temporaryStateStack).last().id;
          if(window.location.href.endsWith("#@"+ lastId)) {
            window.history.pushState({}, "", window.location.href.substring(0, window.location.href.length - lastId.length) + id);
          } else {
            window.history.pushState({}, "", window.location.href + "#@" + id);
          }

        } else {
          window.history.pushState({}, "", window.location.href + "#@" + id);
        }
        this.temporaryStateStack.push(new TemporaryState(id, url, onRemoved));

      }
    });
    if(!this.actionExecutionInProgress) {
      this.actionExecutionInProgress = true;
      this.executeHistoryActionsQueue();
    }

    return id;
  }

  removeTemporaryState(stateId: string, doNotNavigate: boolean = false) {
    if(this.temporaryStateStack.length > 0) {
        const state = this.temporaryStateStack[this.temporaryStateStack.length - 1];
        if(state.id === stateId) {
          if(doNotNavigate) {
            //console.log((Date.now() % 10000) + " removeTemporaryState " + state.id + " " + state.fromUrl + " " + stateId)
            this.temporaryStateStack.pop();
          } else {

            this.historyActionsQueue.push({
              name: "back",
              action: () => window.history.back()
            });
            if(!this.actionExecutionInProgress) {
              this.executeHistoryActionsQueue();
            }

          }
        }
    }
  }


  private executeHistoryActionsQueue() {
    this.actionExecutionInProgress = false;
    if(this.historyActionsQueue.length > 0) {
      const action = this.historyActionsQueue.shift();
      if(action) {
        // console.log((Date.now() % 10000) + " executeHistoryActionsQueue " + action.name);
        action.action();
        this.actionExecutionInProgress = true;

        this.navigationListener = new ResolvablePromise<void>();
        mySetTimeoutNoAngular(() => {
          //console.log((Date.now() % 10000) + " Execute recursive");
          if(this.navigationListener && !this.navigationListener.resolved) {
            this.navigationListener.resolve();
          }
        }, this.MAX_DELAY);

        this.navigationListener.then(() => {
          this.executeHistoryActionsQueue();
        });

      }
    }
  }

  private checkTemporaryState(url: string) {
    if(this.temporaryStateStack.length > 0) {
      const state = this.temporaryStateStack[this.temporaryStateStack.length - 1];
      //console.log((Date.now() % 10000) + " checkTemporaryState " + state.id + " " + state.fromUrl + " current url: " + url, this.temporaryStateStack);
      if(state.fromUrl === url) {
        this.temporaryStateStack.pop();
        state.onRemoved();
      }
    } else {
      //// console.log((Date.now() % 10000) + " checkTemporaryState no state " + url)
    }
  }

  canNavigateBack() {
    return window.history.length > 4; // checking for 1 sometimes doesn't work because of Neula redirects
  }

  navigateBack() {
    if(this.canNavigateBack()) {
      window.history.back();
      return true;
    } else {
      return false;
    }
  }

  urlSearchParams(): {[key: string]: string} {
    const params: {[key: string]: string} = {};
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }

  changeSearchParams(params: { [p: string]: string }) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    for(const [key, value] of Object.entries(params)) {
      urlSearchParams.set(key, value);
    }
    this.location.replaceState(window.location.pathname + "?" + urlSearchParams.toString());
  }

  navigateToApplicationComponentsEditor(applicationId: ApplicationId) {
    this.router.navigateByUrl("/designer/" + applicationId.id+"/application/components");
  }

  navigateToApplicationIntegrationsEditor(applicationId: ApplicationId) {
    this.router.navigateByUrl("/designer/" + applicationId.id+"/application/integrations");
  }

  navigateToLoginPage() {
    console.log("navigate to login")
    this.router.navigateByUrl("/login")
  }

  navigateToLoggedOutPage() {
    this.router.navigateByUrl("/logout")
  }

  navigateToMainPage() {
    this.router.navigateByUrl("/")
  }

  navigateToApplicationsPage() {
    this.router.navigateByUrl("/applications")
  }

  navigateToExternalUrl(mainApplicationUrl: string) {
    window.location.href = mainApplicationUrl;
  }

  navigateToScreenEditor(applicationId: ApplicationId, id: ScreenId) {
    this.router.navigateByUrl("/designer/" + applicationId.id + "/screen/" + id.id);
  }

  navigateBackOr(url: string) {
    if(this.canNavigateBack()) {
      window.history.back();
    } else {
      this.router.navigateByUrl(url);
    }
  }

  navigateToProcessDesignerPage(processId: ProcessId) {
    this.router.navigateByUrl("/designer/" + processId.id+"/process");
  }

  navigateToApplicationDesignerPage(applicationId: ApplicationId) {
    this.router.navigateByUrl("/designer/" + applicationId.id+"/application");
  }

  navigateToDesignerPage() {
    this.router.navigateByUrl("/designer");
  }

}

class TemporaryState {
  constructor(
    readonly id: string,
    readonly fromUrl: string,
    readonly onRemoved: () => void) {
  }
}
