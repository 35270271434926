<div class="theme-input noPadding">

  <div class="inputParamTile" [class.disabled]="disabled">

    <div class="firstColumn" *ngIf="input">

      <my-switch [(marked)]="viewModel.requiredParam"
                 [label]="'database_connections_operations_operation_parameters_parameter_required' | i18n"
                 (change)="onChange()" [disabled]="disabled" />

    </div>

    <div class="restColumns">

      <input class="paramName theme-input" type="text" [(ngModel)]="viewModel.name"
             [placeholder]="'database_connections_operations_operation_parameters_parameter_name' | i18n"
             (change)="onChange()" [disabled]="disabled" [focus-on-show]="focusOnShow">

      <hr class="theme-content-vertical-separator-small-no-margin" />

      <my-data-type-selector class="constantTypeSelector"
                             [disabled]="disabled"
                             [inline]="true"
                             [(dataType)]="viewModel.valueType"
                             (dataTypeChange)="onChange()"
                             [options]="valueTypes"
                             [detailedTypeName]="true"/>

      <ng-container *ngIf="input && !viewModel.requiredParam && defaultable">
        <hr class="theme-content-vertical-separator-small-no-margin" />
        <input class="defaultValue theme-input inline" type="text" name="defaultValue" [(ngModel)]="viewModel.defaultValue" (change)="onChange()"
               [placeholder]="'database_connections_operations_operation_parameters_parameter_defaultValue' | i18n" [disabled]="disabled">
      </ng-container>

    </div>

  </div>

</div>
