import {
  ComponentStatus,
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenComponentsState,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenFormConfig,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel,
  SizeProperty,
  SizeUtils,
  TableContainerRefState,
  TableContainerState,
} from "../..";
import {__, arrayMove, clearArray, I18nText, None, Option, required, Some, VariableId} from "@utils";
import {ScreenComponentRefId} from "@shared";
import {
  CssBuilder, CssUtils,
  LayoutAlign, LayoutStretch,
  LayoutType, RepeatableContainerDefinition,
  ScreenComponentDefinition,
  ScreenComponentRef, ScreenComponentRefIdInScreen, ScreenComponents,
  TableContainerDefinition,
  TableContainerRef, TextFont
} from "@screen-common";
import {Observable, Subject} from "rxjs";

export class ColumnLabelViewModel {

  label: string;
  css = "";
  private prefix: string;
  visible: boolean = true;
  constructor(
    readonly parentTableState: TableContainerState,
    readonly columnComponentRef: ScreenComponentRef,
    readonly columnComponentDefinition: ScreenComponentDefinition) {

    this.prefix = "child|"+columnComponentDefinition.id.id+"|";

    this.label = this.columnComponentDefinition.properties.getOptionalI18nTextProperty("label", columnComponentDefinition.defaultPropertiesProvider)
      .currentValue(() => this.parentTableState.properties.optionalI18nTextProperty(this.prefix+"label")).valueOrDefault(None()).map(t => t.getCurrentWithFallback()).getOrElse("");
  }

  updateView() {

    const unit = ScreenFormConfig.panelUnitRemSize;

    const desiredWidth = this.columnComponentRef.positionProperties.desiredWidth.currentValue(() => this.parentTableState.properties.optionalStringProperty(this.prefix+"desiredWidth")).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
    const maxWidth = this.columnComponentDefinition.sizeProperties.maxWidth.currentValue(() => this.parentTableState.properties.optionalStringProperty(this.prefix+"maxWidth")).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

    this.css = CssUtils.columnLabelWidth(desiredWidth, maxWidth);

  }
}

export class TableContainerEntryViewModel {


  css = "";
  childrenPanelDeltaHeightRem = 0;

  constructor(readonly contextId: VariableId,
              readonly serverModel: ScreenInstanceServerModel,
              readonly children: Array<ScreenComponentViewModel>,
              readonly ref: TableContainerRef,
              readonly refState: TableContainerRefState) {}

  getDeltaHeight() {
    return 0;
  }

  getComponentById(id: number): Option<ScreenComponentViewModel> {
    for(let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if(child.id == id) {
        return Some(child);
      } else if(child.isContainer()) {
        const childSuccessor = (<ScreenContainerViewModel>child).getComponentById(id);
        if(childSuccessor.isDefined()) {
          return childSuccessor;
        }
      }
    }
    return None();
  }

  updateCss(minWidthCss: string, minHeightCss: string) {

    this.children.forEach(child => {
      if(child instanceof ComponentViewModelWithLabel) {
        child.forceHideLabel(true);
      }
    })

    const cssBuilder = new CssBuilder();


    cssBuilder.addProperty("display: inline-block;");

    CssUtils.minSizes(cssBuilder, Some(minWidthCss), Some(minHeightCss));

    this.css = cssBuilder.toCss();
  }
}

export class TableContainerRow {
  constructor(readonly entryContextId: VariableId,
              readonly cells: Array<TableContainerCell>) {}
}

export class TableContainerCell {
  readonly childArray: Array<ScreenComponentViewModel>|null;
  visible: boolean = true;
  constructor(readonly entryContextId: VariableId,
              readonly child: ScreenComponentViewModel) {
    this.childArray = [child];  // so ng-include will have properly named component
  }
}


export class TableContainerViewModel extends ScreenContainerViewModel {

  override typeName = "Table";

  readonly columns: Array<ColumnLabelViewModel>;
  readonly entries: Array<TableContainerEntryViewModel>;

  public rows: Array<TableContainerRow> = [];
  // public allChildren: Array<TableContainerCell>; // flattened children for need of css grid

  public canAddEntry: boolean = false;
  public possiblyCanDeleteEntries: boolean = false;
  public possiblyCanMoveEntries: boolean = false;
  public canDeleteEntries: boolean = false;
  public canMoveEntries: boolean = false;


  public layout: string = "";
  public entriesLayout: string = "";
  public override customCssClass: string = "";
  public header: string = "";
  public headerVisible: boolean = false;
  public addLabel: string = "";


  public override layoutClass: string = "";
  public layoutAlignClass: string = "";
  public layoutStretchClass: string = "";

  public tableCss = "";
  public css = "";
  public cssClasses = "";
  public entriesCss = "";
  public childrenPlainCss = "";
  public childrenPlainCssClasses = "";

  public headerCss: string = "";
  public headerCssClasses: string = "";
  public clickableEntry: boolean = false;

  columnsCount = 0;

  private readonly reinitDragSubject: Subject<void> = new Subject<void>();
  readonly reinitDrag: Observable<void> = this.reinitDragSubject.asObservable();


  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              override readonly definition: TableContainerDefinition,
              override readonly componentScreenId: string,
              readonly ref: TableContainerRef,
              override readonly refScreenId: string,
              readonly componentState: TableContainerState,
              readonly refState: TableContainerRefState,
              readonly context: VariableId,
              readonly serverModel: ScreenInstanceServerModel,
              readonly screensComponents: {[screenId: string]: ScreenComponents},
              readonly componentsState: ScreenComponentsState,
              readonly createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {
    super(parent, componentState, refState, definition, shared);
    this.clickableEntry = definition.actionProperties.getActions("onEntryClick").length > 0;
    this.entries = [];
    this.columns = definition.columns.map(column => {
      const childRef = screensComponents[componentScreenId].getRef(column.componentRefId);
      const childDefinition = screensComponents[childRef.applicationScreen.map(s => s.id).getOrElse(componentScreenId)].getDefinition(childRef.componentId);
      return new ColumnLabelViewModel(componentState, childRef, childDefinition);
    });
    this.update();
  }

  onRowClick(row: TableContainerRow) {
    if(this.definition.actionProperties.getActions("onEntryClick").length > 0) {
      this.serverModel.executeEntryAction(this.componentRefPath(), row.entryContextId, "onEntryClick");
    }
  }

  getAllChildren(): Array<ScreenComponentViewModel> {
    return __(this.entries).flatMap(e => e.children);
  }

  override componentName(): string {
    if(this.headerVisible && this.header.trim().length > 0) {
      return this.header;
    } else {
      return super.componentName();
    }
  }

  getLayoutForComponent(componentRefId: ScreenComponentRefId): LayoutType {
    return LayoutType.horizontal;
  }

  updateContainer(deep: boolean): void {
    // must be after entries initialization

    const unit = ScreenFormConfig.panelUnitRemSize;

    if (!this.uncoveredAndVisible || this.status != ComponentStatus.initialized) {
      clearArray(this.entries);
    } else {

      const cssBuilder = CssBuilder.create();
      const headerCssBuilder = CssBuilder.create();
      const childrenPlainCssBuilder = CssBuilder.create();
      const entriesCssBuilder = CssBuilder.create();
      const tableCssBuilder = CssBuilder.create();

      const layout = LayoutType.horizontal;
      const layoutAlign = LayoutAlign.byName(this.definition.layoutsProperties.layoutAlign.currentValue(() => this.componentState.layoutsState.layoutAlign).valueOrDefault(LayoutAlign.stretch.name));
      const layoutStretch = LayoutStretch.byName(this.definition.layoutsProperties.layoutStretch.currentValue(() => this.componentState.layoutsState.layoutStretch).valueOrDefault(LayoutStretch.DEFAULT.name));
      this.layoutClass = layout.name;
      this.layoutAlignClass = layoutAlign.name;
      this.layoutStretchClass = layoutStretch.name;

      const textSize = this.definition.contentTextSize(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
      const textColor = this.definition.contentTextColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextColor).valueOrDefault(None());
      const textFont = this.definition.contentTextFont(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));

      CssUtils.fontCss(childrenPlainCssBuilder, textFont, textSize, false, false, false, false, textColor);
      ComponentViewModelUtils.toPaddingsCss(childrenPlainCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState)

      const contentMinHeight = this.definition.sizeProperties.contentMinHeight.currentValue(() => this.componentState.boxState.contentMinHeight).valueOrDefault(None());
      const contentMinWidth = this.definition.sizeProperties.contentMinWidth.currentValue(() => this.componentState.boxState.contentMinWidth).valueOrDefault(None());

      const headerOption = this.definition.header.currentValue(() => this.componentState.header).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
      this.header = headerOption.getOrElse("");
      this.headerVisible = headerOption.isDefined();

      const headerHeightValue = this.definition.headerProperties.headerMinHeight(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider)
        .currentValue(() => this.componentState.headerState.headerMinHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

      // TODO ?
      const childrenPanelDeltaHeightRem = 0;

      CssUtils.childrenPlainMinHeight(childrenPlainCssBuilder,
        contentMinHeight.map(v => SizeProperty.sizeToCss(unit, v)),
        headerHeightValue,
        this.headerVisible,
        Some(childrenPanelDeltaHeightRem + "rem")
      );

      const gapColumn = this.definition.layoutsProperties.gapColumn.currentValue(() => this.componentState.layoutsState.gapColumn).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
      const gapRow = this.definition.layoutsProperties.gapRow.currentValue(() => this.componentState.layoutsState.gapRow).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
      CssUtils.gaps(childrenPlainCssBuilder, gapRow, gapColumn);


      const backgroundColorValue = this.definition.backgroundsProperties.backgroundColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.backgroundsState.backgroundColor).valueOrDefault(None());

      CssUtils.backgroundColor(cssBuilder, backgroundColorValue);

      ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

      ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);



      const maxHeight = this.definition.sizeProperties.maxHeight.currentValue(() => this.componentState.boxState.maxHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
      const maxWidth = this.definition.sizeProperties.maxWidth.currentValue(() => this.componentState.boxState.maxWidth).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

      CssUtils.entriesMaxSizes(entriesCssBuilder, maxWidth, maxHeight, headerHeightValue, this.headerVisible);

      const innerLayout = LayoutType.byName(this.definition.layoutsProperties.layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));

      if(this.componentState.innerContext.isDefined() && !this.hasVisibilityError) {

        const existingEntries = this.entries.slice();
        clearArray(this.entries);


        this.componentState.innerContext.getOrError("No inner context available").entries.forEach(contextEntry => {

          const existing = __(existingEntries).find(e => e.contextId.id == contextEntry.id);
          if (existing.isDefined()) {
            this.entries.push(existing.get());
          } else {
            const children = this.definition.columns.map((column, index) => {
              return this.createViewModel(this, ScreenComponentRefIdInScreen.of(this.componentScreenId, column.componentRefId), Some(contextEntry));
            });
            this.entries.push(new TableContainerEntryViewModel(contextEntry, this.serverModel, children, this.ref, this.refState));
          }
        });

        this.columnsCount = this.definition.columns.length;

      } else {
        clearArray(this.entries);
      }


      this.entries.forEach(entry => entry.updateCss(contentMinWidth.getOrElse("1cm"), contentMinHeight.getOrElse("1cm")));

      this.updateAddRemoveAllowed();

      this.layout = layout.name;

      this.addLabel = this.definition.addLabel.currentValue(() => this.componentState.properties.i18nTextProperty("addLabel")).valueOrDefault(I18nText.empty()).getCurrentWithFallback();

      ComponentViewModelUtils.toHeaderCss(headerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.headerProperties, this.componentState.headerState);


      const deleteColumnWidth = this.canDeleteEntries ? (layoutAlign.isStretch() ? " 0.01fr" : " 1fr") : ""; // 0.01fr to make it minimal, 1fr to fill remaining space

      if(layoutAlign.isStretch()) {
        tableCssBuilder.addProperty("grid-template-columns: "+this.definition.columns.map(c => {
          const columnRef = this.screensComponents[this.componentScreenId].getRef(c.componentRefId);
          const desiredWidth = columnRef.positionProperties.desiredWidth.currentValue(() => this.componentState.properties.optionalStringProperty("child|"+c.componentRefId.id+"|desiredWidth")).valueOrDefault(None());
          return desiredWidth.map(v => parseFloat(v)+"fr").getOrElse("1fr");
        }).join(" ")+deleteColumnWidth+";");
      } else {
        tableCssBuilder.addProperty("grid-template-columns: "+this.definition.columns.map(c => {
          const columnRef = this.screensComponents[this.componentScreenId].getRef(c.componentRefId);
          const desiredWidth = columnRef.positionProperties.desiredWidth.currentValue(() => this.componentState.properties.optionalStringProperty("child|"+c.componentRefId.id+"|desiredWidth")).valueOrDefault(None());
          return desiredWidth.map(v => SizeProperty.sizeToCss(unit, v)).getOrElse("1fr");
        }).join(" ")+deleteColumnWidth+";");

        if(layoutAlign.isStart()) {
          tableCssBuilder.addProperty("justify-content: start;");
        } else if(layoutAlign.isCenter()) {
          tableCssBuilder.addProperty("justify-content: center;");
        } else if(layoutAlign.isEnd()) {
          tableCssBuilder.addProperty("justify-content: end;");
        }
      }

      this.columns.forEach(c => c.updateView());


      this.rows = __(this.entries).map(e => {
        const children = e.children.map(ch => new TableContainerCell(e.contextId, ch));
        return new TableContainerRow(e.contextId, children);
      });




      this.css = cssBuilder.toCss();
      this.cssClasses = cssBuilder.toCssClasses();

      this.headerCss = headerCssBuilder.toCss();
      this.headerCssClasses = headerCssBuilder.toCssClasses();

      this.tableCss = tableCssBuilder.toCss();


      this.entriesCss = entriesCssBuilder.toCss();

      this.childrenPlainCss = childrenPlainCssBuilder.toCss();
      this.childrenPlainCssClasses = childrenPlainCssBuilder.toCssClasses();

      this.reinitDragSubject.next();

    }
    super.updatePosition();
    if(this.parent instanceof ScreenContainerViewModel) {
      this.parent.updateTop();
    }
  }


  override updateContainerAfterChildren() {

    // Hide and show full columns

    this.columns.forEach(((column, index) => {
      column.visible = __(this.rows).exists(row => {
        return row.cells[index].child.visible;
      });
    }));

    this.rows.forEach(row => {
      row.cells.forEach((cell, index) => {
        cell.visible = this.columns[index].visible;
      });
    });


  }

  override isTable() {
    return true;
  }

  override updateEditableAndPreview() {
    super.updateEditableAndPreview();
    this.updateAddRemoveAllowed();
  }

  private updateAddRemoveAllowed() {
    const lengthEditable = this.ref.lengthEditable.currentValue(() => this.refState.lengthEditable).valueOrDefault(false);
    const sortable = this.ref.sortable.currentValue(() => this.refState.sortable).valueOrDefault(false);
    const minEntries = this.definition.minEntries.currentValue(() => this.componentState.minEntries).valueOrDefault(None()).getOrElse(0);
    const maxEntries = this.definition.maxEntries.currentValue(() => this.componentState.maxEntries).valueOrDefault(None()).getOrElse(1000000);
    this.canAddEntry = !this.disabled && lengthEditable && this.entries.length < maxEntries;
    this.possiblyCanDeleteEntries = !this.disabled && lengthEditable;
    this.possiblyCanMoveEntries = !this.disabled && sortable;
    this.canDeleteEntries = this.possiblyCanDeleteEntries && this.entries.length > minEntries;
    this.canMoveEntries = this.possiblyCanMoveEntries && this.entries.length > 1;

  }


  addEntry(): void {
    this.serverModel.addRepeatableContextEntry(this.entries.length, this.componentRefPath(), TableContainerDefinition.ON_CHANGE, () => {
    });
  }

  deleteEntry(row: TableContainerRow): void {
    this.serverModel.deleteRepeatableContextEntry(this.componentRefPath(), row.entryContextId,
      __(this.entries).findIndexOf(e => e.contextId.id === row.entryContextId.id).getOrError("No entry"), TableContainerDefinition.ON_CHANGE, () => {
    });
  }

  moveEntry(fromIndex: number, toIndex: number): void {
    const entry = required(this.entries[fromIndex], "entry");
    arrayMove(this.entries, fromIndex, toIndex);
    arrayMove(this.rows, fromIndex, toIndex);
    this.serverModel.moveEntry(this.componentRefPath(), entry.contextId, fromIndex, toIndex, RepeatableContainerDefinition.ON_CHANGE, () => {

    });
  }




  static create(shared: ScreenSharedViewModel,
                parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                context: VariableId,
                definition: TableContainerDefinition,
                componentScreenId: string,
                ref: TableContainerRef,
                refScreenId: string,
                componentState: TableContainerState,
                refState: TableContainerRefState,
                serverModel: ScreenInstanceServerModel,
                screensComponents: {[screenId: string]: ScreenComponents},
                componentsState: ScreenComponentsState,
                createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {

    return new TableContainerViewModel(shared, parent, definition, componentScreenId, ref, refScreenId, componentState, refState, context, serverModel, screensComponents, componentsState,
      createViewModel);

  }

  childDeltaHeightChanged(): void {
  }


  getComponentById(id: number): Option<ScreenComponentViewModel> {
    for(let i = 0; i < this.entries.length; i++) {
      const fromEntry = this.entries[i].getComponentById(id);
      if (fromEntry.isDefined()) {
        return fromEntry;
      }

    }
    return None();
  }


}
