import {
  ContentWithAuthorization,
  DocumentTypeInfo,
  FileRequestResult,
  FileRequestResultFactory,
  NodeWithAuthorization,
  RepositoryNodeBasicInfoFactory,
  RepositoryNodeSearchInfo,
  WebdavFileLink
} from "@shared";
import {
  DocumentSearchQuery,
  FindDocumentsByQuery,
  FoundDocument,
  FoundDocumentResult,
  GetDocumentsSearchInfoById,
  GetWebdavLink,
  GetWebdavLinkByFlowOperator,
  SignatureVerificationResult,
  VerifyDocumentSignature
} from "./DocumentRepositoryQueryMessages";
import {AuthenticatedHttp, IsXmlInvoice, OneTimeFileId, PathSize} from "@shared-model";
import {AggregateId, FileUri, i18n, None, Option, Some, toastr, toUrlParams, Typed} from "@utils";
import {ReadDocument} from "../OcrModel";
import {Injectable} from "@angular/core";


class FindSharedDirectoryContent {}

class FindStarDirectoryContent {}

class FindDeletedDirectoryContent {}

@Injectable({
  providedIn: 'root'
})
export class DocumentRepositoryQueryService {

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {
  }

  private ocrCache: { [file: string]: ReadDocument } = {};
  private signatureCache: { [file: string]: SignatureVerificationResult } = {};



  private hasAccessToRootContentCache: Option<boolean> = None();



  findSharedDirectoryContent(): Promise<ContentWithAuthorization> {
    return this.authenticatedHttp.postPromise<ContentWithAuthorization>("documents/structure/shared-directory-content", new FindSharedDirectoryContent())
      .then((response: ContentWithAuthorization) => {
        return ContentWithAuthorization.copy(response);
      });
  }

  findStarDirectoryContent(): Promise<ContentWithAuthorization> {
    return this.authenticatedHttp.postPromise<ContentWithAuthorization>("documents/structure/star-directory-content", new FindStarDirectoryContent())
      .then((response: ContentWithAuthorization) => {
        return ContentWithAuthorization.copy(response);
      });
  }

  findDeletedDirectoryContent(): Promise<ContentWithAuthorization> {
    return this.authenticatedHttp.postPromise<ContentWithAuthorization>("documents/structure/deleted-directory-content", new FindDeletedDirectoryContent())
        .then((response: ContentWithAuthorization) => {
          return ContentWithAuthorization.copy(response);
        });
  }



  loadNodePromise(uri: FileUri): Promise<NodeWithAuthorization> {
    return this.authenticatedHttp.getPromise<Typed<FileRequestResult<NodeWithAuthorization>>>("documents/structure/get/" + uri.urlSerialized())
      .then((response: Typed<FileRequestResult<NodeWithAuthorization>>) => {
        const copied = FileRequestResultFactory.copyTypedAndUnwrap<NodeWithAuthorization>(response, NodeWithAuthorization.copy)
        if (copied.isSuccess()) {
          return copied.asSuccess().file;
        } else {
          toastr.error(copied.errorMessage());
          throw new Error(copied.errorMessage());
        }
      })
  }


  loadDeletedNode(nodeId: AggregateId): Promise<NodeWithAuthorization> {
    return this.authenticatedHttp.getPromise<Typed<FileRequestResult<NodeWithAuthorization>>>("documents/structure/getDeleted/" + nodeId.id)
      .then((response: Typed<FileRequestResult<NodeWithAuthorization>>) => {
        const copied = FileRequestResultFactory.copyTypedAndUnwrap<NodeWithAuthorization>(response, NodeWithAuthorization.copy)
        if (copied.isSuccess()) {
          return copied.asSuccess().file;
        } else {
          toastr.error(copied.errorMessage());
          throw new Error(copied.errorMessage());
        }
      })
  }


  getOneTimeIdByFileId(filePath: string): Promise<OneTimeFileId> {
    return this.authenticatedHttp.getPromise<OneTimeFileId>("documents/get-one-time-id/" + filePath)
      .then((response: OneTimeFileId) => {
        return response
      })
  }

  getDirectorySize(directoryId: string): Promise<PathSize> {
    return this.authenticatedHttp.getPromise<Typed<FileRequestResult<PathSize>>>("documents/directory/get-size/" + directoryId)
      .then((response: Typed<FileRequestResult<PathSize>>) => {
        const copied = FileRequestResultFactory.copyTypedAndUnwrap<PathSize>(response, PathSize.copy)
        if (copied.isSuccess()) {
          return copied.asSuccess().file;
        } else {
          toastr.error(copied.errorMessage());
          throw new Error(copied.errorMessage());
        }
      })
  }


  isFileXmlInvoice(uri: FileUri): Promise<IsXmlInvoice> {
    return this.authenticatedHttp.getPromise<IsXmlInvoice>("documents/is-file-xml-invoice/" + uri.urlSerialized())
      .then((response: IsXmlInvoice) => {
        return IsXmlInvoice.copy(response)
      })
  }

  // getXlsDocumentAsInvoicePdf(fileUri: FileUri): Promise


  getOcrDocumentFromFile(fileId: string, config: string): Promise<Option<ReadDocument>> {
    return this.authenticatedHttp.getPromise<Option<ReadDocument>>("documents/ocr/" + fileId)
      .then((response: Option<ReadDocument>) => {
        return Option.copy(response, ReadDocument.copy)
      })
  }

  getOcrDocumentFromFileUri(fileUri: FileUri, config: string): Promise<Option<ReadDocument>> {

    const fromCache = Option.of(this.ocrCache[fileUri.serialize()]);

    if (fromCache.isDefined()) {
      return new Promise<Option<ReadDocument>>((resolve, reject) =>
        resolve(fromCache.map(ReadDocument.copy))
      );
    } else {
      return this.authenticatedHttp.getPromise<Option<ReadDocument>>("documents/ocr-by-file-uri/" + fileUri.urlSerialized())
        .then((response: Option<ReadDocument>) => {
          const readDocument = Option.copy(response);
          if (readDocument.isDefined()) {
            this.ocrCache[fileUri.serialize()] = readDocument.map(ReadDocument.copy).get();
          }
          return readDocument.map(ReadDocument.copy);
        })

    }

  }

  VerifyDocumentSignature(fileUri: FileUri, detachedFileUri: Option<FileUri>): Promise<SignatureVerificationResult> {
    const fromCache = Option.of(this.signatureCache[fileUri.serialize()]);

    if (fromCache.isDefined()) {
      return new Promise<SignatureVerificationResult>((resolve, reject) => {
        resolve(SignatureVerificationResult.copy(fromCache.get()));
      });
    } else {
      return this.authenticatedHttp.postPromise<Typed<FileRequestResult<SignatureVerificationResult>>>("documents/verify-digital-signature", new VerifyDocumentSignature(fileUri, detachedFileUri))
        .then((response: Typed<FileRequestResult<SignatureVerificationResult>>) => {
          const copied = FileRequestResultFactory.copyTypedAndUnwrap<SignatureVerificationResult>(response, SignatureVerificationResult.copy);

          if (copied.isSuccess()) {
            this.signatureCache[fileUri.serialize()] = SignatureVerificationResult.copy(copied.asSuccess().file);
            return copied.asSuccess().file;
          } else {
            toastr.error(copied.errorMessage());
            throw new Error(copied.errorMessage());
          }
        })
    }
  }

  getDocumentsSearchInfoByIds(documentsFound: Array<FoundDocument>, path: string, onSuccess: (documents: RepositoryNodeSearchInfo[]) => void) {
    if (documentsFound.length === 0) {
      onSuccess([]);
    } else {
      this.authenticatedHttp.post("documents/structure/get-documents-search-info-by-ids", new GetDocumentsSearchInfoById(documentsFound.map(d => d.id), path),
        (data: Array<Typed<RepositoryNodeSearchInfo>>) => {
          onSuccess(data.map(RepositoryNodeBasicInfoFactory.copyTyped).map(node => <RepositoryNodeSearchInfo>Typed.value(node)));
        });
    }
  }

  findDocumentsByQuery(documentSearchQuery: DocumentSearchQuery, onSuccess: (documents: FoundDocumentResult) => void, onError: () => void, onTimeoutError: (message: string) => void) {
    this.authenticatedHttp.post("documents/structure/find-documents-by-search-query", new FindDocumentsByQuery(documentSearchQuery),
      (data: FoundDocumentResult) => {
        if (data.timeoutError.length == 0) {
          onSuccess(FoundDocumentResult.copy(data))
        } else {
          onTimeoutError(i18n("Document query timeout exceeded: " + data.timeoutError));
        }
      }, () => {
        onError();
      });
  }

  getDocumentWebdavLink(fileUri: FileUri, forEdition: boolean, onSuccess: (uri: WebdavFileLink) => void) {
    this.authenticatedHttp.post("documents/get-webdav-link", new GetWebdavLink(fileUri, forEdition), (data: WebdavFileLink) => {
      onSuccess(WebdavFileLink.copy(data));
    })
  }


  getDocumentWebdavLinkByFlowOperator(fileUri: FileUri, forEdition: boolean, onSuccess: (uri: WebdavFileLink) => void) {
    this.authenticatedHttp.post("documents/get-webdav-link-by-flow-operator", new GetWebdavLinkByFlowOperator(fileUri, forEdition), (data: WebdavFileLink) => {
      onSuccess(WebdavFileLink.copy(data));
    })
  }

}
