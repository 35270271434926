import {
  AggregateId,
  ApplicationId,
  mySetTimeoutNoAngular,
  Option,
  required,
  ScreenId,
  ScreenInstanceId,
  toastr
} from "@utils";
import {
  I18nService, NavigationService,
  ScreenInstanceSharedService,
  ServerEventsService,
  UserSettingsStateService, VariableTextPreviewService,
  ViewableFile
} from "@shared"
import {
  BusinessVariable,
  EmailsSharedService,
  FilesSharedService,
  ObjectVariable, ProcessEdgeId,
  ScreenExternalEventBus
} from "@shared-model";
import {
  ComponentInternalErrorViewModel,
  ComponentValidationErrorViewModel,
  ComponentViewModelFactory,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenWrapperViewModel
} from "./components/screen-component.view-model";
import {ScreenDebugInfo, ScreenInstanceState} from "./model/screen.runtime-model";
import {ScreenInstanceServerModel} from "./screen-instance.server-model";
import {ScreenRuntimeEventBus} from "./services/screen-runtime.event-bus";
import {ScreenComponentController} from "./ScreenComponentController";
import {ScreenInstanceService} from "./services/screen-instance.service";
import {ScreenSharedViewModel} from "./model/ViewModel";
import {ScreenComponentRefIdInScreen, SkinPrototype} from "@screen-common";
import {ScreenDebugViewModel} from "./internal/screen-debug.view-model";
import {BusinessEntitySharedService} from "@shared-model";


export class ScreenViewModel {
  notFound: boolean;
  loading: boolean;
  screenId: ScreenId = new ScreenId("");
  applicationId: ApplicationId = ApplicationId.EMPTY;
  mainContainerViewModel: Array<ScreenComponentViewModel> = [];

  controller: ScreenComponentController|null = null;
  serverModel: ScreenInstanceServerModel|null = null;
  eventBus = new ScreenRuntimeEventBus();

  validationErrors: Array<ComponentValidationErrorViewModel> = [];

  internalErrors: Array<ComponentInternalErrorViewModel> = [];

  shared: ScreenSharedViewModel;


  debugAvailable: boolean = false;
  debugInfoVisible: boolean = false;
  debugInfo: ScreenDebugViewModel|null = null;

  keepAlive: boolean = false;


  screenFileViewerVisible: boolean = false;
  screenViewableFiles: Array<ViewableFile> = [];
  screenFileViewerIndex: number = 0;

  terminated: boolean = false;

  constructor(readonly $container: HTMLElement,
              readonly screenInstanceId: ScreenInstanceId,
              readonly terminateOnDestroy: "always"|"never"|"ifNotKeepAlive",
              readonly externalEventBus: ScreenExternalEventBus,
              readonly screenInstanceService: ScreenInstanceService,
              readonly screenInstanceSharedService: ScreenInstanceSharedService,
              readonly serverEventsService: ServerEventsService,
              readonly filesSharedService: FilesSharedService,
              readonly emailsSharedService: EmailsSharedService,
              readonly businessEntitySharedService: BusinessEntitySharedService,
              readonly i18nService: I18nService,
              readonly userSettingsService: UserSettingsStateService,
              readonly navigationService: NavigationService,
              readonly variableTextPreviewService: VariableTextPreviewService,
              readonly allowDebug: boolean,
              private readOnly: boolean,
              readonly filesPreviewRequest: Option<(files: Array<any>, index: number) => void>,
              readonly onNotFound: () => void|null,
              skins: Array<SkinPrototype>) {

    this.shared = new ScreenSharedViewModel(this.eventBus, skins);

    this.notFound = false;
    this.loading = true;
    this.initScreen();

    this.eventBus.on(this.eventBus.debugInfoLoaded, (debugInfo: ScreenDebugInfo) => {
      this.onDebugInfoLoaded(debugInfo);
    })

    this.eventBus.on(this.eventBus.debugInfoAvailableLoaded, (debugInfo: boolean) => {
      this.onDebugInfoAvailableLoaded(debugInfo);
    })

    this.eventBus.on(this.eventBus.componentsStateUpdated, () => {
      this.updateAllErrors();
    });

    this.eventBus.on(this.eventBus.outputParameterChanged, (input: boolean, variableName: string, value: Option<BusinessVariable>) => {
      this.externalEventBus.outputParameterChanged(input, variableName, value);
    })

    this.externalEventBus.on(this.externalEventBus.inputParametersChanged, (params: Array<[string, string]>) => {
      if(this.serverModel) {
        this.serverModel.changeInputParameters(params)
      }
    })

    this.eventBus.on(this.eventBus.screenExternalContextIdChanged, (context: AggregateId) => {
      this.externalEventBus.screenExternalContextIdChanged(context);
    })


    // this.externalEventBus.on(this.externalEventBus.screenCloseRequested, (screenInstanceId: ScreenInstanceId) => {
    //   if(this.screenInstanceId.id == screenInstanceId.id) {
    //    if(this.serverModel) {
    //     this.serverModel.terminate(() => {
    //       this.externalEventBus.screenTerminated(screenInstanceId);
    //     });
    //   }
    //   }
    // });

    externalEventBus.on(externalEventBus.filesPreviewRequested, (files: Array<ViewableFile>, index: number) => {
      this.onFilesPreviewRequested(files, index);
    });

    externalEventBus.on(externalEventBus.screenTerminateRequested, (screenInstanceId: ScreenInstanceId) => {
      this.terminate();
    });

    externalEventBus.on(externalEventBus.debugRequested, () => {
      this.toggleDebugInfo();
    });
  }

  private initScreen() {

    // clear previous instance
    this.screenInstanceService.getInstance(this.screenInstanceId, (instanceOption: Option<ScreenInstanceState>) => {

      this.loading = false;
      this.notFound = instanceOption.isEmpty();

      if (instanceOption.isDefined()) {
        const instance: ScreenInstanceState = instanceOption.get();


        if (this.serverModel) {
          this.serverModel.destroy();
        }
        this.serverModel = new ScreenInstanceServerModel(instance.screenId, this.eventBus, this.screenInstanceService, this.screenInstanceSharedService, instance, this.serverEventsService, this.filesSharedService,
          this.businessEntitySharedService, () => {
          this.initScreen();
          });

        this.screenId = instance.screenId;
        this.applicationId = instance.applicationId;

        const wrapper: ScreenWrapperViewModel = {
          isEditable: () => true,
          isReadOnlyMode: () => this.readOnly
        }

        this.mainContainerViewModel = [ComponentViewModelFactory.root(this.shared, this.externalEventBus, wrapper,
          this.i18nService, this.userSettingsService, instance.contextId,
          ScreenComponentRefIdInScreen.of(instance.screenId.id, instance.root), instance.componentsAsMap(),
          instance.componentsState, this.serverModel, this.navigationService, this.variableTextPreviewService)];

        this.keepAlive = !instance.lifeMode.isVolatile();

        if(this.controller) {
          this.controller.destroy();
        }
        this.controller = new ScreenComponentController(this.$container, <ScreenContainerViewModel>this.mainContainerViewModel[0], this.eventBus);

        if (this.allowDebug) {
          this.serverModel.checkDebugInfoAvailable();
        }

        this.updateAllErrors();
      } else {
        if (this.onNotFound != null) {
          this.onNotFound();
        }
      }
    });
  }

  onFilesPreviewRequested(files: Array<ViewableFile>, index: number) {
    if(this.filesPreviewRequest.isDefined()) {
      this.filesPreviewRequest.get()(files, index);
    } else {
      this.screenFileViewerVisible = true;
      this.screenViewableFiles = files;
      this.screenFileViewerIndex = index;
    }
  }

  onScreenCloseFileViewer() {
    this.screenFileViewerVisible = false;
    this.screenViewableFiles = [];
    this.screenFileViewerIndex = 0;
  }

  private onDebugInfoAvailableLoaded(debugInfoAvailable: boolean) {
    this.debugAvailable = debugInfoAvailable;
  }

  toggleDebugInfo() {

    this.debugInfoVisible = !this.debugInfoVisible;
    if(this.debugInfoVisible) {

      this.debugInfo = new ScreenDebugViewModel(this, required(this.serverModel, "serverModel"), this.eventBus, "", "", new ObjectVariable({}), false,
        this.filesSharedService,
        this.emailsSharedService);

      required(this.serverModel, "serverModel").loadDebugInfo();
    }
  }

  closeDebugInfo() {
    this.debugInfoVisible = false;
    this.debugInfo = null;
  }


  private onDebugInfoLoaded(debugInfo: ScreenDebugInfo) {
    let logs = "";
    let errors = "";
    debugInfo.logs.forEach(l => {
      logs += l.level.level+" "+l.logType+" "+l.timestamp+" "+l.message+"\n";
    });

    debugInfo.errors.forEach(l => {
      errors += l.timestamp.formattedFromNow()+" "+l.variableName+" "+l.error+"\n";
    });

    if(this.debugInfo) {
      this.debugInfo.logs = logs;
      this.debugInfo.errors = errors;
      this.debugInfo.debugData = debugInfo.data;
      this.debugInfo.loaded = true;
    }
  }

  updateAllErrors() {
    if(this.mainContainerViewModel.length == 1) {
      this.validationErrors = this.mainContainerViewModel[0].getValidationErrorsRecursive();
      this.internalErrors = this.mainContainerViewModel[0].getInternalErrorsRecursive();
    }
  }

  destroy() {
    if(this.serverModel) {
      if(this.terminateOnDestroy === 'always' || this.terminateOnDestroy === 'ifNotKeepAlive' && !this.keepAlive) {
        this.terminate();
      }
      this.serverModel.destroy();
    }
    if(this.controller) {
      this.controller.destroy();
    }
  }

  errorsExpanded = true;

  toggleErrorsExpanded() {
    this.errorsExpanded = !this.errorsExpanded;
  }

  terminate() {
    if(!this.terminated) {
      this.terminated = true;
      this.externalEventBus.screenTerminated(this.screenInstanceId);
      mySetTimeoutNoAngular(() => { // timeout so we'll prioritize loading of new content, and we don't want to use connection
        if (this.serverModel) {
          this.serverModel.terminate(() => {
          });
        }
      }, 200);
    }
  }

  setReadOnly(readOnly: boolean) {
    this.readOnly = readOnly;
    if(this.mainContainerViewModel.length == 1) {
      this.mainContainerViewModel[0].updateEditableAndPreview();
    }
  }

  submit(edgeId: ProcessEdgeId, onSubmitted: () => void) {
    if(this.readOnly) {
      toastr.error("Cannot submit form in read only mode");
    } else {
      if(this.serverModel) {
        this.serverModel.submit(edgeId, onSubmitted);
      }
    }
  }

  setMobileDesktop(mobile: boolean, tablet: boolean, desktop: boolean) {
    this.shared.mobile = mobile;
    this.shared.desktop = desktop;
    this.shared.tablet = tablet;
    if(this.mainContainerViewModel.length == 1) {
      this.mainContainerViewModel[0].onMobileUpdated();
    }
  }
}
