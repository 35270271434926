import {__, AggregateId, AnyFlowId, FileUri, None, Option, Some, Typed} from "@utils";
import {SignatureResultInfo} from "../SignaturesModel";
import {FieldQueryExpression, LongConstraint, ProcessNodeId, TimeConstraint} from "@shared-model";

export class FoundDocumentResult {
  constructor(readonly found: Array<FoundDocument>,
              readonly moreAvailable: boolean,
              readonly timeoutError: string) {
  }

  static copy(other: FoundDocumentResult) {
    return new FoundDocumentResult(other.found.map(FoundDocument.copy), other.moreAvailable, other.timeoutError);
  }
}

export class FoundDocument {
  constructor(readonly id: AggregateId) {
  }

  static copy(other: FoundDocument) {
    return new FoundDocument(AggregateId.copy(other.id));
  }
}


export class QueryNodeType {
  constructor(readonly name: string) {
  }

  static file = new QueryNodeType("file");
  static dir = new QueryNodeType("dir");
  static both = new QueryNodeType("both");
}



export class DocumentSearchQuery {

  constructor(public textQuery: string,
              public path: string,
              public resultsAfter: Option<FoundDocument>,
              public results: Option<number>,
              public nodeType: QueryNodeType,
              public created: Option<TimeConstraint>,
              public modified: Option<TimeConstraint>,
              public size: Option<LongConstraint>,
              public name: Option<string>,
              public nameLike: boolean,
              public extension: Array<string>,
              public documentType: Array<string>,
              public expressionQuery: string,
              public fieldQuery: Option<Typed<FieldQueryExpression>>) {
  }

  static empty(): DocumentSearchQuery {
    return new DocumentSearchQuery("", "", None(), Some(1), QueryNodeType.both, None(), None(), None(), None(), false, [], [], "", None());
  }

  notEmpty() {
    return this.textQuery.trim().length > 0 ||
      this.created.exists(c => c.notEmpty()) ||
      this.modified.exists(c => c.notEmpty()) ||
      this.size.exists(c => c.notEmpty()) ||
      this.name.exists(c => c.trim().length > 0) ||
      __(this.extension).exists(e => e.length > 0) ||
      this.documentType.length > 0 ||
      this.expressionQuery.trim().length > 0 ||
      this.fieldQuery.isDefined();

  }

}

export class FindDocumentsByQuery {
  constructor(readonly documentSearchQuery: DocumentSearchQuery) {
  }
}

export class GetDocumentsSearchInfoById {
  constructor(readonly documentsFound: Array<AggregateId>, readonly path: string) {
  }
}

export class GetPdfPageDimensions {
  constructor(readonly uri: FileUri) {
  }
}

export class GetWebdavLink {
  constructor(readonly uri: FileUri, readonly forEdition: boolean) {
  }
}

export class GetWebdavLinkByFlowPreview {
  constructor(readonly uri: FileUri, readonly flowId: Typed<AnyFlowId>, readonly forEdition: boolean) {
  }
}

export class GetWebdavLinkByFlowOperator {
  constructor(readonly uri: FileUri, readonly forEdition: boolean) {
  }
}

export class GetWebdavLinkByFlowSupervisor {
  constructor(readonly uri: FileUri,
              readonly flowId: Typed<AnyFlowId>,
              readonly nodeId: ProcessNodeId,
              readonly forEdition: boolean) {}
}

export class DigitalSignatureResult {
  constructor(readonly name: string) {
  }

  static verified = new DigitalSignatureResult("correct");
  static incorrect = new DigitalSignatureResult("incorrect");
  static unsure = new DigitalSignatureResult("unsure");

  static copy(other: DigitalSignatureResult): DigitalSignatureResult {
    switch (other.name) {
      case DigitalSignatureResult.verified.name:
        return DigitalSignatureResult.verified;
      case DigitalSignatureResult.incorrect.name:
        return DigitalSignatureResult.incorrect;
      case DigitalSignatureResult.unsure.name:
        return DigitalSignatureResult.unsure;
      default:
        throw new Error("Unsupported DigitalSignatureResult [" + other.name + "]");
    }
  }
}

export class CertificateResultInfo {
  constructor(readonly name: string) {
  }

  static copy(other: CertificateResultInfo): CertificateResultInfo {
    return new CertificateResultInfo(other.name);
  }
}


export class SignatureStrength {
  constructor(readonly name: string) {
  }

  static copy(other: CertificateResultInfo): SignatureStrength {
    switch (other.name) {
      case SignatureStrength.qualified.name:
        return SignatureStrength.qualified;
      case SignatureStrength.advanced.name:
        return SignatureStrength.advanced;
      case SignatureStrength.advancedSupportedByQualified.name:
        return SignatureStrength.advancedSupportedByQualified;
      case SignatureStrength.notAdvanced.name:
        return SignatureStrength.notAdvanced;
      case SignatureStrength.notAdvancedSupportedByQualified.name:
        return SignatureStrength.notAdvancedSupportedByQualified;
      case SignatureStrength.notApplicable.name:
        return SignatureStrength.notApplicable;
      default:
        throw new Error("Incorrect SignatureStrength [" + other.name + "]");
    }
  }

  static qualified = new SignatureStrength("qualified");
  static advanced = new SignatureStrength("advanced");
  static advancedSupportedByQualified = new SignatureStrength("advancedSupportedByQualified");
  static notAdvanced = new SignatureStrength("notAdvanced");
  static notAdvancedSupportedByQualified = new SignatureStrength("notAdvancedSupportedByQualified");
  static notApplicable = new SignatureStrength("notApplicable");
}

export class SignOrSeal {
  constructor(readonly name: string) {
  }

  static copy(other: SignOrSeal): SignOrSeal {
    switch (other.name) {
      case SignOrSeal.sign.name:
        return SignOrSeal.sign;
      case SignOrSeal.seal.name:
        return SignOrSeal.seal;
      case SignOrSeal.signOrSeal.name:
        return SignOrSeal.signOrSeal;
      default:
        throw new Error("Incorrect SignOrSeal [" + other.name + "]");
    }
  }

  static sign = new SignOrSeal("sign");
  static seal = new SignOrSeal("seal");
  static signOrSeal = new SignOrSeal("signOrSeal");
}

export class SignatureVerificationResult {
  constructor(readonly signatures: Array<SignatureResultInfo>) {
  }

  static copy(other: SignatureVerificationResult): SignatureVerificationResult {
    return new SignatureVerificationResult(other.signatures.map(SignatureResultInfo.copy))
  }

  ok() {
    return this.signatures.length > 0 && __(this.signatures).all(s => s.result.name == DigitalSignatureResult.verified.name && s.warnings.length == 0 && s.errors.length == 0);
  }

  withWarnings() {
    return !this.ok() && (this.signatures.length == 0 || __(this.signatures).exists(s => s.result.name == DigitalSignatureResult.verified.name));
  }
}

export class VerifyDocumentSignature {
  constructor(readonly fileUri: FileUri,
              readonly detachedFileUri: Option<FileUri>) {
  }
}
