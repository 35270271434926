import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef
} from "@angular/core";
import {$$, $$Element, toastr} from "@utils";
import {AppSettingsController, NavigationService} from "@shared";

export type ModalShowAnimation = "fadeIn" | "slideInBottom" | "slideInEnd";

@Component({
  selector: 'my-modal',
  templateUrl: './modal.component.html'
})
// Directive annotation is here so compiler would not complain, but it should have no side effects
export class ModalComponent implements OnInit, OnDestroy, OnChanges {

  popupVisible: boolean = false;

  @Input({required:true}) cssClass: string|undefined;
  @Input() backgroundVisible: boolean = false;
  @Input() backgroundStyle: "light"|"dark" = "dark";
  @Input() blurBackground = false;

  @Input() mode: "custom"|"popup"|"endPanel"|"bottomPanel" = "popup";

  @Output() opened = new EventEmitter<HTMLElement>();
  @Output() closed = new EventEmitter<void>();
  @Output() closedByUser = new EventEmitter<void>(); // clopsed by user action, not by 'visible' change

  @Input() closeOnBackgroundClick: boolean = false;
  @Output() backgroundClicked = new EventEmitter<void>();

  private lastPosition: any|undefined = undefined;
  private shopPopupInProgress: boolean = false;
  private layer: HTMLElement|undefined;

  @Input() showAnimation: ModalShowAnimation = "fadeIn";

  private initialized: boolean = false;
  private _visible: boolean = false; // visible is too render content
  private _show: boolean = true; // show is optional to delay showing content, until it is ready
  private modalComponent: $$Element|undefined = undefined;

  /** If popup will handle back button */
  @Input() navigationEnabled: boolean = true;

  private navigationStateId?: string;
  private themeChanged = false;

  @Input() horizontalPosition: "start"|"center"|"end" = "center";
  @Input() verticalPosition: "top"|"middle"|"bottom" = "middle";
  private mouseDownOnBackground: boolean = false;

  constructor(private readonly viewContainerRef: ViewContainerRef,
              private readonly navigationService: NavigationService,
              private readonly appSettingsController: AppSettingsController) {
  }

  @Input({required:true}) set visible(visible: boolean) {
    this._visible = visible;
    if(this.initialized) {
      this.onVisibleChanged();
    }
  }

  @Input() set show(show: boolean) {
    this._show = show;
    if(this.initialized) {
      this.onVisibleChanged();
    }
  }

  private onVisibleChanged() {
    if (this._visible && this._show) {
      if (!this.shopPopupInProgress) {
        this.showPopup();
      }
    } else {
      this.hidePopupIfVisible(false);
    }
  }

  @Output() visibleChange = new EventEmitter<boolean>();

  ngOnInit() {
    this.initialized = true;
    this.onVisibleChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.layer) {
      this.layer.classList
        .toggle("popup", this.mode === "popup");
      this.layer.classList
        .toggle("blockingVisible", this.backgroundVisible || this.closeOnBackgroundClick);
      this.layer.classList
        .toggle("transparent", !this.backgroundVisible && this.closeOnBackgroundClick);
      this.layer.classList
        .toggle("light", this.backgroundVisible && this.backgroundStyle === "light");
      this.layer.classList
        .toggle("dark", this.backgroundVisible && this.backgroundStyle === "dark");
      this.layer.classList
        .toggle("blur", this.backgroundVisible && this.blurBackground);
      this.layer.classList
        .toggle("nonBlocking", !this.backgroundVisible);
    }
  }



  private showPopup(): void {
    // console.log("showPopup " + (new Date().getTime() % 1000000));

    const modalComponent = $$(this.viewContainerRef).children()[0];

    if(modalComponent === undefined) {
      console.error("modalComponent is null");
      toastr.error("modalComponent is null");
      // this.shopPopupInProgress = true;
      // myRequestAnimationFrame(() => {
      //   this.showPopup();
      // });
    } else {

      if(this.navigationEnabled) {
        requestAnimationFrame(() => {
          // new frame to allow previous popup to close
          this.navigationStateId = this.navigationService.pushTemporaryState(() => {
            this.hidePopupIfVisible(true);
          });
        });
      }


      this.popupVisible = true;
      this.lastPosition = undefined;

      this.layer = document.createElement("div");
      this.layer.classList.add("modal-overlay");
      this.layer.classList.add(this.showAnimation);
      document.body.appendChild(this.layer);

      this.layer.classList
        .toggle("popup", this.mode === "popup");
      this.layer.classList
        .toggle("blockingVisible", this.backgroundVisible);
      this.layer.classList
        .toggle("light",this.backgroundVisible && this.backgroundStyle === "light");
      this.layer.classList
        .toggle("dark",this.backgroundVisible && this.backgroundStyle === "dark");
      this.layer.classList
        .toggle("blur", this.backgroundVisible && this.blurBackground);
      this.layer.classList
        .toggle("nonBlocking", !this.backgroundVisible);
      this.layer.appendChild(modalComponent.getAsHtmlElement());


      $$(this.layer).on("mousedown", (event) => {
        this.mouseDownOnBackground = event.target === this.layer
      });

      $$(this.layer).on("mouseup", (event) => {
        if(event.target === this.layer && this.mouseDownOnBackground) {
          if(this.closeOnBackgroundClick) {
            this.hidePopupIfVisible(true);
          }
          this.backgroundClicked.emit();
        }
      });

      this.opened.emit(modalComponent.getAsHtmlElement());

      this.modalComponent = modalComponent;

      this.shopPopupInProgress = false;

      if(this.backgroundVisible && this.backgroundStyle === "dark") {
        this.appSettingsController.changeBrowserThemeToDarkBackground();
        this.themeChanged = true;
      } else if(this.backgroundVisible && this.backgroundStyle === "light") {
        this.appSettingsController.changeBrowserThemeToLightBackground();
        this.themeChanged = true;
      }
    }
  }

  private hidePopupIfVisible(changeVisible: boolean): void {
    if (this.popupVisible) {
      this.popupVisible = false;

      if(this.navigationStateId) {
        this.navigationService.removeTemporaryState(this.navigationStateId);
        this.navigationStateId = undefined;
      }

      if (this.modalComponent === undefined) {
        throw new Error("No modalComponent");
      } else {
        if (this.layer === undefined) {
          throw new Error("No container");
        } else {
          this.viewContainerRef.element.nativeElement.append(this.modalComponent.getAsHtmlElement());
          this.modalComponent = undefined;
          document.body.removeChild(this.layer);
          this.layer = undefined;
        }
      }

      if(changeVisible) {
        this._visible = false;
        this.visibleChange.emit(false);
      }
      this.closed.emit();
      if(changeVisible) {
        this.closedByUser.emit();
      }
      if(this.themeChanged) {
        this.appSettingsController.returnToPreviousTheme();
        this.themeChanged = false;
      }
    }
  }



  ngOnDestroy(): void {
    if (this.layer) {
      if(this.themeChanged) {
        this.appSettingsController.returnToPreviousTheme();
      }
      document.body.removeChild(this.layer);
      this.layer = undefined;
    }
  }
}
