import {
  __, ___, clearArray, Duration,
  FileUri,
  I18nText,
  LocalDate,
  LocalDateTime,
  LocalTime,
  None,
  Option, OrganizationNodeId, overwriteArray, PersonId,
  Some,
  Trilean,
  Typed,
  VariableId
} from "@utils";
import {
  ArrayVariable,
  BooleanVariable,
  BusinessVariable,
  BusinessVariableFactory,
  DateTimeVariable,
  DateVariable,
  DepartmentVariable,
  FileVariableV2,
  GroupVariable,
  I18nTextVariable,
  IntervalVariable,
  NullVariable,
  NumberVariable,
  ObjectVariable, OrganizationNodeVariable, OrganizationNodeVariableType,
  PasswordVariable,
  PersonVariable,
  StringVariable,
  TimeVariable
} from "@shared-model";
import {
  ModalContainerDefinition,
  PropertyTry,
  ScreenComponentIdInScreen,
  ScreenComponentRefIdInScreen, SwitchComponentDefinition, ViewSwitcherContainerDefinition
} from "@screen-common";
import {ScreenComponentRefStateFactory, ScreenComponentStateFactory} from "../components/component-state.factory";
import {ComponentValidationError, ComponentValidationErrorFactory} from "./screen.runtime-model";

export class RepeatableContext {
  constructor(readonly contextId: VariableId,
              readonly entries: Array<VariableId>) {
  }

  static copy(other: RepeatableContext) {
    return new RepeatableContext(
      VariableId.copy(other.contextId),
      other.entries.map(VariableId.copy));
  }
}


export interface UpdateableState {
  updated(): void;
}

export class ParentComponentId {
  constructor(readonly context: VariableId,
              readonly componentId: ScreenComponentIdInScreen) {}

  static copy(other: ParentComponentId) {
    return new ParentComponentId(VariableId.copy(other.context),
      ScreenComponentIdInScreen.copy(other.componentId));
  }
}

export abstract class ScreenComponentState implements UpdateableState {
  abstract className(): string;
  abstract readonly properties: PropertiesStateHolder;
  abstract readonly parent: Option<ParentComponentId>;
  readonly boxState: BoxPropertiesState;
  protected constructor(properties: PropertiesStateHolder) {
    this.boxState = new BoxPropertiesState(properties);
  }

  get uncovered() {
    return this.properties.booleanProperty("uncovered");
  }
  get visible() {
    return this.properties.booleanProperty("visible");
  }
  get editable() {
    return this.properties.booleanProperty("editable");
  }

  get status() {
    return this.properties.numberProperty("$status");
  }

  readonly updateListeners: Array<() => void> = [];
  onUpdate(callback: () => void) {
    this.updateListeners.push(callback);
  }
  updated() {
    this.updateListeners.forEach(callback => callback());
  }

  updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case ViewSwitcherContainerDefinition.ACTIVE_VIEW:
        if(value.isDefined() && value.get() instanceof StringVariable) {
          this.properties.putValue(ViewSwitcherContainerDefinition.ACTIVE_VIEW, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type String but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(ViewSwitcherContainerDefinition.ACTIVE_VIEW);
        }
        break;
      case ViewSwitcherContainerDefinition.VIEW_PARAM:
        if(value.isDefined()) {
          this.properties.putValue(ViewSwitcherContainerDefinition.VIEW_PARAM, value.get());
        } else {
          this.properties.clearValue(ViewSwitcherContainerDefinition.VIEW_PARAM);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }

  setModelInProgress(modelName: string) {
    this.properties.putInProgress(modelName);
  }

}

export abstract class RepeatableContextComponentState extends ScreenComponentState {
  abstract updateInnerContext(innerContext: RepeatableContext): void;

  abstract clearInnerContext(): void;

  abstract innerContext: Option<RepeatableContext>;
}


export abstract class LabeledScreenComponentState extends ScreenComponentState {
  readonly labelState: LabelPropertiesState;
  protected constructor(properties: PropertiesStateHolder) {
    super(properties);
    this.labelState = new LabelPropertiesState(properties);
  }

  get tooltip() {
    return this.properties.optionalI18nTextProperty("tooltip");
  }
}


export abstract class OptionalContextComponentState extends ScreenComponentState {
  abstract updateInnerContext(innerContext: Option<VariableId>): void;
  abstract innerContext: Option<VariableId>;

}




export abstract class ScreenComponentRefState implements UpdateableState {
  abstract className(): string;
  abstract readonly properties: PropertiesStateHolder;
  readonly positionState: RefPositionPropertiesState;
  protected constructor(properties: PropertiesStateHolder) {
    this.positionState = new RefPositionPropertiesState(properties);
  }


  get uncovered() {
    return this.properties.booleanProperty("uncovered");
  }

  get visible() {
    return this.properties.booleanProperty("visible");
  }

  get tabletVisible() {
    return this.properties.booleanProperty("tablet");
  }

  get desktopVisible() {
    return this.properties.booleanProperty("desktop");
  }

  get mobileVisible() {
    return this.properties.booleanProperty("mobile");
  }

  get editable() {
    return this.properties.booleanProperty("editable");
  }

  get preview() {
    return this.properties.optionalBooleanProperty("preview");
  }


  readonly updateListeners: Array<() => void> = [];
  onUpdate(callback: () => void) {
    this.updateListeners.push(callback);
  }
  updated() {
    this.updateListeners.forEach(callback => callback());
  }
}


export class ScreenComponentsState {

  private componentStates: Array<[string, Array<[ScreenComponentIdInScreen, Typed<ScreenComponentState>]>]> = [];
  private refStates: Array<[string, Array<[ScreenComponentRefIdInScreen, Typed<ScreenComponentRefState>]>]> = [];

  private componentStatesMap: { [contextId: string]: { [componentIdInScreen: string]: ScreenComponentState } } = {};
  private refStatesMap: { [contextId: string]: { [componentIdInScreen: string]: ScreenComponentRefState } } = {};

  updateMaps() {
    this.componentStatesMap = {};
    this.refStatesMap = {};
    this.componentStates.forEach((context: [string, Array<[ScreenComponentIdInScreen, Typed<ScreenComponentState>]>]) => {
      const state: { [name: string]: ScreenComponentState } = {};
      this.componentStatesMap[context[0]] = state;
      context[1].forEach((entry: [ScreenComponentIdInScreen, Typed<ScreenComponentState>]) => {
        state[entry[0].asMapKey()] = Typed.value(entry[1]);
      });
    });
    this.refStates.forEach((context: [string, Array<[ScreenComponentRefIdInScreen, Typed<ScreenComponentRefState>]>]) => {
      const state: { [name: string]: ScreenComponentRefState } = {};
      this.refStatesMap[context[0]] = state;
      context[1].forEach((entry: [ScreenComponentRefIdInScreen, Typed<ScreenComponentRefState>]) => {
        state[entry[0].asMapKey()] = Typed.value(entry[1]);
      });
    });
    return this;
  }

  getComponentState(context: VariableId, componentId: ScreenComponentIdInScreen): ScreenComponentState {
    const contextKey = context.id;
    let byContext = this.componentStatesMap[contextKey];
    if (!byContext) {
      throw new Error("State not available for context [" + contextKey + "] and component " + componentId.id);
    }
    let state = byContext[componentId.asMapKey()];
    if (!state) {
      throw new Error("State not available for context [" + contextKey + "] and component " + componentId.id);
    }
    return state;
  }

  isComponentRefState(context: VariableId, componentRefId: ScreenComponentRefIdInScreen): boolean {
    const contextKey = context.id;
    let byContext = this.refStatesMap[contextKey];
    if (!byContext) {
      return false;
    }
    return byContext[componentRefId.asMapKey()] != undefined;
  }

  getComponentRefState(context: VariableId, componentRefId: ScreenComponentRefIdInScreen): ScreenComponentRefState {
    const contextKey = context.id;
    let byContext = this.refStatesMap[contextKey];
    if (!byContext) {
      throw new Error("State not available for context [" + contextKey + "] and component ref " + componentRefId.id);
    }
    let state = byContext[componentRefId.asMapKey()];
    if (!state) {
      throw new Error("State not available for context [" + contextKey + "] and component ref " + componentRefId.id);
    }
    return state;
  }

  putComponentState(context: VariableId, componentId: ScreenComponentIdInScreen, componentState: ScreenComponentState) {
    const contextKey = context.id;
    let byContext = this.componentStatesMap[contextKey];
    if (!byContext) {
      byContext = {};
      this.componentStatesMap[contextKey] = byContext;
    }
    byContext[componentId.asMapKey()] = componentState;
  }

  deleteComponentState(context: VariableId, componentId: ScreenComponentIdInScreen) {
    const contextKey = context.id;
    let byContext = this.componentStatesMap[contextKey];
    if (byContext) {
      delete byContext[componentId.asMapKey()];
    }
  }

  putComponentRefState(context: VariableId, componentRefId: ScreenComponentRefIdInScreen, refState: ScreenComponentRefState) {
    const contextKey = context.id;
    let byContext = this.refStatesMap[contextKey];
    if (!byContext) {
      byContext = {};
      this.refStatesMap[contextKey] = byContext;
    }
    byContext[componentRefId.asMapKey()] = refState;
  }

  deleteComponentRefState(context: VariableId, componentRefId: ScreenComponentRefIdInScreen) {
    const contextKey = context.id;
    const byContext = this.refStatesMap[contextKey];
    if (byContext) {
      delete byContext[componentRefId.asMapKey()];
    }
  }

  static copy(other: ScreenComponentsState) {
    const states = new ScreenComponentsState();
    states.componentStates = other.componentStates.map((cs: [string, Array<[ScreenComponentIdInScreen, Typed<ScreenComponentState>]>]) => <[string, Array<[ScreenComponentIdInScreen, Typed<ScreenComponentState>]>]>[cs[0], cs[1].map((css: [ScreenComponentIdInScreen, Typed<ScreenComponentState>]) => [ScreenComponentIdInScreen.copy(css[0]), ScreenComponentStateFactory.copyTyped(css[1])])]);
    states.refStates = other.refStates.map((cs: [string, Array<[ScreenComponentRefIdInScreen, Typed<ScreenComponentRefState>]>]) => <[string, Array<[ScreenComponentRefIdInScreen, Typed<ScreenComponentRefState>]>]>[cs[0], cs[1].map((css: [ScreenComponentRefIdInScreen, Typed<ScreenComponentRefState>]) => [ScreenComponentRefIdInScreen.copy(css[0]), ScreenComponentRefStateFactory.copyTyped(css[1])])]);
    return states;
  }
}



export class RefPositionPropertiesState {
  constructor(readonly properties: PropertiesStateHolder) {
  }

  get desiredWidth() {
    return this.properties.optionalStringProperty("desiredWidth")
  }

  get desiredHeight() {
    return this.properties.optionalStringProperty("desiredHeight")
  }

  get left() {
    return this.properties.optionalStringProperty("left")
  }

  get top() {
    return this.properties.optionalStringProperty("top")
  }

  get right() {
    return this.properties.optionalStringProperty("right")
  }

  get bottom() {
    return this.properties.optionalStringProperty("bottom")
  }

  get column() {
    return this.properties.optionalNumberProperty("column")
  }

  get row() {
    return this.properties.optionalNumberProperty("row")
  }

  get columnSpan() {
    return this.properties.numberProperty("columnSpan")
  }

  get rowSpan() {
    return this.properties.numberProperty("rowSpan")
  }

  get marginTop() {
    return this.properties.optionalStringProperty("marginTop")
  }

  get marginLeft() {
    return this.properties.optionalStringProperty("marginLeft")
  }

  get marginBottom() {
    return this.properties.optionalStringProperty("marginBottom")
  }

  get marginRight() {
    return this.properties.optionalStringProperty("marginRight")
  }
}

export class BordersPropertiesState {

  constructor(readonly properties: PropertiesStateHolder) {
  }

  get borderTop() {
    return this.properties.optionalStringProperty("borderTop")
  }

  get borderLeft() {
    return this.properties.optionalStringProperty("borderLeft")
  }

  get borderBottom() {
    return this.properties.optionalStringProperty("borderBottom")
  }

  get borderRight() {
    return this.properties.optionalStringProperty("borderRight")
  }

  get borderRadius() {
    return this.properties.optionalStringProperty("borderRadius")
  }

  get borderColorTop() {
    return this.properties.optionalStringProperty("borderColorTop")
  }

  get borderColorLeft() {
    return this.properties.optionalStringProperty("borderColorLeft")
  }

  get borderColorBottom() {
    return this.properties.optionalStringProperty("borderColorBottom")
  }

  get borderColorRight() {
    return this.properties.optionalStringProperty("borderColorRight")
  }

  get outerShadow() {
    return this.properties.booleanProperty("outerShadow")
  }
}

export class BackgroundsPropertiesState {
  constructor(readonly prefix: string,
              readonly properties: PropertiesStateHolder) {
  }

  get backgroundColor() {
    return this.properties.optionalStringProperty(this.prefix + "backgroundColor")
  }
}

export class LayoutsPropertiesState {
  constructor(readonly prefix: string,
              readonly properties: PropertiesStateHolder) {
  }

  get gapColumn() {
    return this.properties.optionalStringProperty(this.prefix + "gapColumn")
  }

  get gapRow() {
    return this.properties.optionalStringProperty(this.prefix + "gapRow")
  }

  get layout() {
    return this.properties.stringProperty(this.prefix + "layout")
  }

  get layoutAlign() {
    return this.properties.stringProperty(this.prefix + "layoutAlign")
  }

  get layoutStretch() {
    return this.properties.stringProperty(this.prefix + "layoutStretch")
  }

  get layoutWrap() {
    return this.properties.stringProperty(this.prefix + "layoutWrap")
  }

  get rows() {
    return this.properties.numberProperty(this.prefix + "rows")
  }

  get columns() {
    return this.properties.numberProperty(this.prefix + "columns")
  }

}


export class PaddingsPropertiesState {
  constructor(readonly prefix: string,
              readonly properties: PropertiesStateHolder) {
  }


  get paddingTop() {
    return this.properties.optionalStringProperty(this.prefix + "paddingTop")
  }

  get paddingLeft() {
    return this.properties.optionalStringProperty(this.prefix + "paddingLeft")
  }

  get paddingBottom() {
    return this.properties.optionalStringProperty(this.prefix + "paddingBottom")
  }

  get paddingRight() {
    return this.properties.optionalStringProperty(this.prefix + "paddingRight")
  }
}


export class BoxPropertiesState {
  constructor(readonly properties: PropertiesStateHolder) {
  }

  get componentSkin() {
    return this.properties.optionalStringProperty("componentSkin")
  }

  get componentClass() {
    return this.properties.stringProperty("componentClass")
  }

  get cssClass() {
    return this.properties.optionalStringProperty("cssClass")
  }

  get contentMinWidth() {
    return this.properties.optionalStringProperty("contentMinWidth")
  }

  get contentMinHeight() {
    return this.properties.optionalStringProperty("contentMinHeight")
  }

  get maxWidth() {
    return this.properties.optionalStringProperty("maxWidth")
  }

  get maxHeight() {
    return this.properties.optionalStringProperty("maxHeight")
  }
}

export class TextPropertiesState {
  constructor(readonly properties: PropertiesStateHolder) {
  }

  get textSize() {
    return this.properties.optionalStringProperty("textSize")
  }

  get textColor() {
    return this.properties.optionalStringProperty("textColor")
  }

  get textAlign() {
    return this.properties.stringProperty("textAlign")
  }

  get textVerticalAlign() {
    return this.properties.stringProperty("textVerticalAlign")
  }

  get textFont() {
    return this.properties.optionalStringProperty("textFont")
  }

  get bold() {
    return this.properties.booleanProperty("bold")
  }

  get italic() {
    return this.properties.booleanProperty("italic")
  }

  get underline() {
    return this.properties.booleanProperty("underline")
  }

  get lineThrough() {
    return this.properties.booleanProperty("lineThrough")
  }
}


export class HeaderPropertiesState {
  constructor(readonly properties: PropertiesStateHolder) {
  }

  get headerTextSize() {
    return this.properties.optionalStringProperty("headerTextSize")
  }

  get headerTextColor() {
    return this.properties.optionalStringProperty("headerTextColor")
  }

  get headerTextAlign() {
    return this.properties.optionalStringProperty("headerTextAlign")
  }

  get headerTextVerticalAlign() {
    return this.properties.optionalStringProperty("headerTextVerticalAlign")
  }

  get headerTextFont() {
    return this.properties.optionalStringProperty("headerTextFont")
  }

  get headerTextBold() {
    return this.properties.booleanProperty("headerTextBold")
  }

  get headerTextItalic() {
    return this.properties.booleanProperty("headerTextItalic")
  }

  get headerTextUnderline() {
    return this.properties.booleanProperty("headerTextUnderline")
  }

  get headerTextLineThrough() {
    return this.properties.booleanProperty("headerTextLineThrough")
  }

  get headerBackgroundColor() {
    return this.properties.optionalStringProperty("headerBackgroundColor")
  }

  get headerMinHeight() {
    return this.properties.optionalStringProperty("headerMinHeight")
  }

  // Header Borders
  get borderTop() {
    return this.properties.optionalStringProperty("headerBorderTop")
  }

  get borderLeft() {
    return this.properties.optionalStringProperty("headerBorderLeft")
  }

  get borderBottom() {
    return this.properties.optionalStringProperty("headerBorderBottom")
  }

  get borderRight() {
    return this.properties.optionalStringProperty("headerBorderRight")
  }

  get borderRadius() {
    return this.properties.optionalStringProperty("headerBorderRadius")
  }

  get borderColorTop() {
    return this.properties.optionalStringProperty("headerBorderColorTop")
  }

  get borderColorLeft() {
    return this.properties.optionalStringProperty("headerBorderColorLeft")
  }

  get borderColorBottom() {
    return this.properties.optionalStringProperty("headerBorderColorBottom")
  }

  get borderColorRight() {
    return this.properties.optionalStringProperty("headerBorderColorRight")
  }

  get outerShadow() {
    return this.properties.booleanProperty("headerOuterShadow")
  }

  // Header paddings
  get paddingTop() {
    return this.properties.optionalStringProperty("headerPaddingTop")
  }

  get paddingLeft() {
    return this.properties.optionalStringProperty("headerPaddingLeft")
  }

  get paddingBottom() {
    return this.properties.optionalStringProperty("headerPaddingBottom")
  }

  get paddingRight() {
    return this.properties.optionalStringProperty("headerPaddingRight")
  }
}

export class LabelPropertiesState {
  constructor(readonly properties: PropertiesStateHolder) {
  }

  get label() {
    return this.properties.optionalI18nTextProperty("label")
  }

  get labelPosition() {
    return this.properties.stringProperty("labelPosition")
  }

  get labelWidth() {
    return this.properties.optionalStringProperty("labelWidth")
  }

  get labelHeight() {
    return this.properties.optionalStringProperty("labelHeight")
  }

  get labelSize() {
    return this.properties.optionalStringProperty("labelSize")
  }

  get labelColor() {
    return this.properties.optionalStringProperty("labelColor")
  }

  get labelFont() {
    return this.properties.optionalStringProperty("labelFont")
  }

  get labelAlign() {
    return this.properties.optionalStringProperty("labelAlign")
  }

  get labelVerticalAlign() {
    return this.properties.optionalStringProperty("labelVerticalAlign")
  }

  get labelBold() {
    return this.properties.booleanProperty("labelBold")
  }

  get labelItalic() {
    return this.properties.booleanProperty("labelItalic")
  }

  get labelUnderline() {
    return this.properties.booleanProperty("labelUnderline")
  }

  get labelLineThrough() {
    return this.properties.booleanProperty("labelLineThrough")
  }
}



export class ActionStateHolder {
  constructor(
    readonly syncInProgress: boolean, // state
    readonly errors: Array<ActionStateError>,
    readonly validationErrors: Array<I18nText>,
    readonly asyncStarted: Array<number>,
    readonly asyncCompleted: Array<number>
  ) {
  }

  static copy(other: ActionStateHolder) {
    return new ActionStateHolder(
      other.syncInProgress,
      other.errors.map(ActionStateError.copy),
      other.validationErrors.map(I18nText.copy),
      other.asyncStarted.slice(),
      other.asyncCompleted.slice());
  }

  isError() {
    return this.errors.length > 0;
  }

  isValidationError() {
    return this.validationErrors.length > 0;
  }

  isInProgress() {
    return this.syncInProgress || this.asyncStarted.length != this.asyncCompleted.length;
  }
}

export class PropertiesStateHolder {
  constructor(
    readonly validationErrors: Array<Typed<ComponentValidationError>>,
    public validationException: Option<ComponentValidationExceptionState>,
    readonly values: Array<[string, PropertyValueHolder]>,
    readonly actions: Array<[string, ActionStateHolder]>
  ) {
  }


  clearValue(name: string) {
    __(this.values).findIndexOf(v => v[0] === name).forEach(index => this.values.splice(index, 1));
  }

  putValue(name: string, value: BusinessVariable) {
    this.clearValue(name);
    this.values.push([name, PropertyValueHolder.value(value)]);
  }

  putError(name: string, timestamp: LocalDateTime, message: string) {
    this.clearValue(name);
    this.values.push([name, PropertyValueHolder.error(timestamp, message)]);
  }

  putInProgress(name: string) {

    const current = this.propertyByName(name);
    this.clearValue(name);
    if (current.isDefined()) {
      this.values.push([name, new PropertyValueHolder(true, current.get().v, current.get().e)]);
    } else {
      this.values.push([name, PropertyValueHolder.inProgress()]);
    }


  }

  private propertyByName(name: string): Option<PropertyValueHolder> {
    return __(this.values).find(v => v[0] === name).map(v => v[1]);
  }

  booleanProperty(name: string): PropertyTry<boolean> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<boolean>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof BooleanVariable) {
      return PropertyTry.success(property.get().p, (<BooleanVariable>property.get().value).value);
    } else {
      return PropertyTry.errorCompleted<boolean>(`Property [${name}] was not of type 'Boolean' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  trileanProperty(name: string): PropertyTry<Trilean> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<Trilean>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof BooleanVariable) {
      return PropertyTry.success(property.get().p, Trilean.ofBoolean((<BooleanVariable>property.get().value).value));
    } else if (property.get().value instanceof NullVariable) {
      return PropertyTry.success(property.get().p, Trilean.UNKNOWN);
    } else {
      return PropertyTry.errorCompleted<Trilean>(`Property [${name}] was not of type 'Boolean' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  labeledValuesProperty(name: string): PropertyTry<Array<BusinessVariable>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<Array<BusinessVariable>>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof ArrayVariable) {
      return PropertyTry.success(property.get().p, (<ArrayVariable<BusinessVariable>>property.get().value).unwrappedValue());
    } else {
      return PropertyTry.errorCompleted<Array<BusinessVariable>>(`Property [${name}] was not of type 'ValuesArray' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  modelProperty(name: string): PropertyTry<BusinessVariable> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<BusinessVariable>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else {
      return PropertyTry.success(property.get().p, (<BusinessVariable>property.get().value));
    }
  }

  optionalModelProperty(name: string): PropertyTry<Option<BusinessVariable>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else {
      return PropertyTry.success(property.get().p, Some((<BusinessVariable>property.get().value)));
    }
  }

  stringProperty(name: string): PropertyTry<string> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<string>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof StringVariable) {
      return PropertyTry.success(property.get().p, (<StringVariable>property.get().value).value);
    } else {
      return PropertyTry.errorCompleted<string>(`Property [${name}] was not of type 'String' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  i18nTextProperty(name: string): PropertyTry<I18nText> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<I18nText>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof I18nTextVariable) {
      return PropertyTry.success(property.get().p, (<I18nTextVariable>property.get().value).value);
    } else if (property.get().value instanceof StringVariable) {
      return PropertyTry.success(property.get().p, I18nText.ofCurrent((<StringVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<I18nText>(`Property [${name}] was not of type 'I18nText' nor 'String' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  stringifiedI18nProperty(name: string): PropertyTry<I18nText> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<I18nText>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof I18nTextVariable) {
      return PropertyTry.success(property.get().p, (<I18nTextVariable>property.get().value).value);
    } else if (property.get().value instanceof StringVariable) {
      return PropertyTry.success(property.get().p, I18nText.ofCurrent((<StringVariable>property.get().value).value));
    } else {
      return PropertyTry.success(property.get().p, I18nText.ofCurrent(property.get().value.toStringVariable().value));
    }
  }


  numberProperty(name: string): PropertyTry<number> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<number>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof NumberVariable) {
      return PropertyTry.success(property.get().p, (<NumberVariable>property.get().value).value);
    } else {
      return PropertyTry.errorCompleted<number>(`Property [${name}] was not of type 'Number' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  fileProperty(name: string): PropertyTry<FileUri> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<FileUri>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof FileVariableV2) {
      return PropertyTry.success(property.get().p, (<FileVariableV2>property.get().value).value);
    } else {
      return PropertyTry.errorCompleted<FileUri>(`Property [${name}] was not of type 'File' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  optionalNumberProperty(name: string): PropertyTry<Option<number>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof NumberVariable) {
      return PropertyTry.success(property.get().p, Some((<NumberVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<Option<number>>(`Property [${name}] was not of type 'Number' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  optionalBusinessVariableProperty(name: string): PropertyTry<Option<BusinessVariable>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else {
      return PropertyTry.success(property.get().p, Some(property.get().value));
    }
  }

  optionalStringProperty(name: string): PropertyTry<Option<string>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof StringVariable) {
      return PropertyTry.success(property.get().p, Some((<StringVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<Option<string>>(`Property [${name}] was not of type 'String' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  optionalPasswordProperty(name: string): PropertyTry<Option<string>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof PasswordVariable) {
      return PropertyTry.success(property.get().p, Some((<PasswordVariable>property.get().value).value.password));
    } else {
      return PropertyTry.errorCompleted<Option<string>>(`Property [${name}] was not of type 'Password' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  optionalI18nTextProperty(name: string): PropertyTry<Option<I18nText>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof I18nTextVariable) {
      return PropertyTry.success(property.get().p, Some((<I18nTextVariable>property.get().value).value));
    } else if (property.get().value instanceof StringVariable) {
      return PropertyTry.success(property.get().p, Some(I18nText.ofCurrent((<StringVariable>property.get().value).value)));
    } else {
      return PropertyTry.errorCompleted<Option<I18nText>>(`Property [${name}] was not of type 'I18nText' or 'String' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  optionalDateProperty(name: string): PropertyTry<Option<LocalDate>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof DateVariable) {
      return PropertyTry.success(property.get().p, Some((<DateVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<Option<LocalDate>>(`Property [${name}] was not of type 'Date' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  optionalDateTimeProperty(name: string): PropertyTry<Option<LocalDateTime>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof DateTimeVariable) {
      return PropertyTry.success(property.get().p, Some((<DateTimeVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<Option<LocalDateTime>>(`Property [${name}] was not of type 'DateTime' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  optionalTimeProperty(name: string): PropertyTry<Option<LocalTime>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof TimeVariable) {
      return PropertyTry.success(property.get().p, Some((<TimeVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<Option<LocalTime>>(`Property [${name}] was not of type 'Time' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  optionalDurationProperty(name: string): PropertyTry<Option<Duration>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof IntervalVariable) {
      return PropertyTry.success(property.get().p, Some((<IntervalVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<Option<Duration>>(`Property [${name}] was not of type 'Duration' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  optionalBooleanProperty(name: string): PropertyTry<Option<boolean>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof BooleanVariable) {
      return PropertyTry.success(property.get().p, Some((<BooleanVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<Option<boolean>>(`Property [${name}] was not of type 'Boolean' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  optionalObjectsArrayProperty(name: string): PropertyTry<Option<ArrayVariable<ObjectVariable>>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof ArrayVariable && __((<ArrayVariable<BusinessVariable>>property.get().value).unwrappedValue()).all(o => o instanceof ObjectVariable)) {
      return PropertyTry.success(property.get().p, Some(<ArrayVariable<ObjectVariable>>property.get().value));
    } else {
      return PropertyTry.errorCompleted<Option<ArrayVariable<ObjectVariable>>>(`Property [${name}] was not of type 'Array[Object]' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  stringArrayProperty(name: string): PropertyTry<Array<string>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.errorCompleted<Array<string>>(`Property [${name}] was not available`);
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof ArrayVariable && __((<ArrayVariable<BusinessVariable>>property.get().value).unwrappedValue()).all(o => o instanceof StringVariable)) {
      return PropertyTry.success(property.get().p, (<ArrayVariable<StringVariable>>property.get().value).unwrappedValue().map(v => v.value));
    } else {
      return PropertyTry.errorCompleted<Array<string>>(`Property [${name}] was not of type 'Array[String]' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  optionalStringArrayProperty(name: string): PropertyTry<Option<Array<string>>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof ArrayVariable && __((<ArrayVariable<BusinessVariable>>property.get().value).unwrappedValue()).all(o => o instanceof StringVariable)) {
      const value: ArrayVariable<BusinessVariable> = <ArrayVariable<BusinessVariable>>property.get().value;
      return PropertyTry.success(property.get().p, Some((<Array<StringVariable>>value.unwrappedValue()).map(vv => vv.value)));
    } else {
      return PropertyTry.errorCompleted<Option<Array<string>>>(`Property [${name}] was not of type 'Array[String]' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  optionalArrayProperty(name: string): PropertyTry<Option<ArrayVariable<BusinessVariable>>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof ArrayVariable) {
      return PropertyTry.success(property.get().p, Some(<ArrayVariable<ObjectVariable>>property.get().value));
    } else {
      return PropertyTry.errorCompleted<Option<ArrayVariable<BusinessVariable>>>(`Property [${name}] was not of type 'Array' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  optionalPersonProperty(name: string): PropertyTry<Option<PersonId>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof PersonVariable) {
      return PropertyTry.success(property.get().p, Some((<PersonVariable>property.get().value).value));
    } else {
      return PropertyTry.errorCompleted<Option<PersonId>>(`Property [${name}] was not of type 'Person' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  optionalOrganizationNodeProperty(name: string): PropertyTry<Option<OrganizationNodeId>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof PersonVariable) {
      return PropertyTry.success(property.get().p, Some(OrganizationNodeId.fromPersonId((<PersonVariable>property.get().value).value.id)));
    } else if (property.get().value instanceof DepartmentVariable) {
      return PropertyTry.success(property.get().p, Some(OrganizationNodeId.fromDepartmentId((<DepartmentVariable>property.get().value).value.id)));
    } else if (property.get().value instanceof GroupVariable) {
      return PropertyTry.success(property.get().p, Some(OrganizationNodeId.fromGroupId((<GroupVariable>property.get().value).value.id)));
    } else {
      return PropertyTry.errorCompleted<Option<OrganizationNodeId>>(`Property [${name}] was not of type 'Person' but of type [${property.get().value.simpleValueType()}]`);
    }
  }


  optionalOrganizationNodesArrayProperty(name: string): PropertyTry<Option<Array<OrganizationNodeVariable>>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof PersonVariable || property.get().value instanceof DepartmentVariable || property.get().value instanceof GroupVariable) {
      return PropertyTry.success(property.get().p, Some((<Array<OrganizationNodeVariable>>[property.get().value]))); // allow single value to be handled here
    } else if (property.get().value instanceof ArrayVariable && __((<ArrayVariable<BusinessVariable>>property.get().value).unwrappedValue()).all(o => o instanceof PersonVariable || o instanceof DepartmentVariable || o instanceof GroupVariable)) {
      return PropertyTry.success(property.get().p, Some((<ArrayVariable<OrganizationNodeVariable>>property.get().value).unwrappedValue()));
    } else {
      return PropertyTry.errorCompleted<Option<Array<OrganizationNodeVariable>>>(`Property [${name}] was not of type OrganizationNode nor of type 'Array[OrganizationNode]' but contain other types`);
    }
  }

  optionalFileProperty(name: string): PropertyTry<Option<FileUri>> {
    const property: Option<PropertyValueHolder> = this.propertyByName(name);
    if (property.isEmpty()) {
      return PropertyTry.successCompleted(None());
    } else if (property.get().isInProgress() && !property.get().isError() && !property.get().isOK()) {
      return PropertyTry.inProgressEmpty<any>();
    } else if (property.get().isError()) {
      return PropertyTry.error<any>(property.get().p, `Property [${name}] is in error state: ${property.get().error.toString()}`);
    } else if (property.get().value instanceof FileVariableV2) {
      return PropertyTry.success(property.get().p, Some((<FileVariableV2>property.get().value).value));
    } else if (property.get().value instanceof StringVariable) {
      return PropertyTry.success(property.get().p, Some(FileUri.parse((<StringVariable>property.get().value).value)));
    } else {
      return PropertyTry.errorCompleted<Option<FileUri>>(`Property [${name}] was not of type 'File' but of type [${property.get().value.simpleValueType()}]`);
    }
  }

  static copy(other: PropertiesStateHolder) {
    return new PropertiesStateHolder(
      other.validationErrors.map(ComponentValidationErrorFactory.copyTyped),
      Option.copy(other.validationException).map(ComponentValidationExceptionState.copy),
      other.values.map((v: [string, PropertyValueHolder]) => <[string, PropertyValueHolder]>[v[0], PropertyValueHolder.copy(v[1])]),
      other.actions.map((v: [string, ActionStateHolder]) => <[string, ActionStateHolder]>[v[0], ActionStateHolder.copy(v[1])])
    )
  }

  static empty() {
    return new PropertiesStateHolder([], None(), [], []);
  }

  errors(): Array<ComponentErrorInfo> {
    const validation = this.validationException.toArray().map(e => new ComponentErrorInfo("validation " + e.ruleIndex, Some(e.timestamp), e.message))
    const values = this.values.filter((v: [string, PropertyValueHolder]) => v[1].isError()).map((v: [string, PropertyValueHolder]) => new ComponentErrorInfo(v[0], Some(v[1].error.timestamp), v[1].error.message));
    const actions = ___(this.actions).filter((a: [string, ActionStateHolder]) => a[1].isError()).flatMap((v: [string, ActionStateHolder]) => v[1].errors.map(e => new ComponentErrorInfo(v[0] + ":" + (e.index + 1), Some(e.timestamp), e.message))).value();
    return values.concat(actions).concat(validation);
  }

  allValidationErrors(): Array<string> {
    const values: Array<string> = this.validationErrors.map(e => Typed.value(e).toMessage());
    const actions = ___(this.actions).filter((a: [string, ActionStateHolder]) => a[1].isValidationError()).flatMap((v: [string, ActionStateHolder]) => v[1].validationErrors.map(e => e.getCurrentWithFallback())).value();
    return values.concat(actions);
  }

  anyInProgress(): boolean {
    const valuesInProgress = __(this.values).exists((v: [string, PropertyValueHolder]) => v[1].isInProgress());
    const actionsInProgress = __(this.actions).exists((a: [string, ActionStateHolder]) => a[1].isInProgress());
    return valuesInProgress || actionsInProgress;
  }

  setActionStateInProgress(actionName: string, timestamp: LocalDateTime) {
    this.updateActionState(actionName, new ActionStateHolder(true, [], [], [], []));
  }


  setActionStateValidationError(actionName: string, messages: Array<I18nText>) {
    this.updateActionState(actionName, new ActionStateHolder(false, [], messages, [], []));
  }

  setActionSyncStateError(actionName: string, timestamp: LocalDateTime, index: number, message: string) {
    const actionState = this.getActionState(actionName);
    if (actionState.isDefined()) {
      const state = actionState.get();
      this.updateActionState(actionName, new ActionStateHolder(false, state.errors.filter(e => e.index != index).concat([new ActionStateError(index, message, timestamp)]),
        [], state.asyncStarted, state.asyncCompleted));
    } else {
      this.updateActionState(actionName, new ActionStateHolder(false, [new ActionStateError(index, message, timestamp)], [], [], []));
    }
  }

  setActionAsyncStateError(actionName: string, timestamp: LocalDateTime, index: number, message: string) {
    const actionState = this.getActionState(actionName);
    if (actionState.isDefined()) {
      const state = actionState.get();
      const asyncCompleted = state.asyncCompleted.concat([index]);
      this.updateActionState(actionName, new ActionStateHolder(state.syncInProgress, state.errors.filter(e => e.index != index).concat([new ActionStateError(index, message, timestamp)]),
        [], state.asyncStarted, asyncCompleted));
    } else {
      this.updateActionState(actionName, new ActionStateHolder(false, [new ActionStateError(index, message, timestamp)], [], [], []));
    }
  }

  private updateActionState(actionName: string, state: ActionStateHolder) {
    const ind = __(this.actions).findIndexOf(a => a[0] == actionName);
    ind.forEach(i => this.actions.splice(i, 1));
    this.actions.push([actionName, state]);
  }

  getActionState(actionName: string): Option<ActionStateHolder> {
    return __(this.actions).find(a => a[0] == actionName).map(a => a[1]);
  }

  setActionStateInAsyncStarted(name: string, startedAsyncActions: Array<number>) {
    const currentActionState = __(this.actions).find(a => a[0] == name).map(s => s[1]);

    const currentSyncInProgress = currentActionState.map(s => s.syncInProgress).getOrElse(false);
    const currentErrors = currentActionState.map(s => s.errors).getOrElse([]);
    const currentActionCompleted = currentActionState.map(s => s.asyncCompleted).getOrElse([]);
    this.updateActionState(name, new ActionStateHolder(currentSyncInProgress, currentErrors, [], startedAsyncActions, currentActionCompleted));
  }

  setActionStateInAsyncCompleted(name: string, completedAsyncAction: number) {
    const currentActionState = __(this.actions).find(a => a[0] == name).map(a => a[1]);

    const currentSyncInProgress = currentActionState.map(s => s.syncInProgress).getOrElse(false);
    const currentErrors = currentActionState.map(s => s.errors).getOrElse([]);
    const currentActionCompleted = currentActionState.map(s => s.asyncCompleted).getOrElse([]).concat(completedAsyncAction);
    const actionStared = currentActionState.map(s => s.asyncStarted).getOrElse([]);
    this.updateActionState(name, new ActionStateHolder(currentSyncInProgress, currentErrors, [], actionStared, currentActionCompleted));
  }

  setActionStateSyncCompleted(actionName: string) {
    const index = __(this.actions).findIndexOf(a => a[0] == actionName);
    if (index.isDefined()) {
      const actionState = this.actions[index.get()][1];
      if (actionState.errors.length == 0 && actionState.asyncCompleted.length === actionState.asyncStarted.length) {
        index.forEach(i => this.actions.splice(i, 1));
      } else {
        this.updateActionState(actionName, new ActionStateHolder(false, actionState.errors, [], actionState.asyncStarted, actionState.asyncCompleted));
      }
    } else {
      throw new Error("No state for action '" + actionName + "' when completing sync")
    }


  }

  isActionInProgress(actionName: string) {
    return __(this.actions).find(a => a[0] == actionName).exists(a => a[1].isInProgress());
  }

  setComponentValidationErrors(validationErrors: Array<Typed<ComponentValidationError>>) {
    this.validationException = None();
    overwriteArray(this.validationErrors, validationErrors);
  }

  setComponentValidationException(message: string, timestamp: LocalDateTime, ruleIndex: number) {
    this.validationException = Some(new ComponentValidationExceptionState(ruleIndex, timestamp, message));
    clearArray(this.validationErrors);
  }
}







export class ComponentValidationExceptionState {
  constructor(readonly ruleIndex: number,
              readonly timestamp: LocalDateTime,
              readonly message: string) {
  }

  static copy(other: ComponentValidationExceptionState) {
    return new ComponentValidationExceptionState(other.ruleIndex, LocalDateTime.copy(other.timestamp), other.message);
  }

  toString() {
    return this.ruleIndex + ": " + this.timestamp + ": " + this.message;
  }
}


export class PropertyValueHolder {
  constructor(
    readonly p: boolean,
    readonly v: Option<Typed<BusinessVariable>>,
    readonly e: Option<PropertyValueError>
  ) {
    if (v.isDefined() && e.isDefined()) {
      throw new Error("Both value and error cannot be defined");
    } else if (v.isEmpty() && e.isEmpty() && !p) {
      throw new Error("Both value and error cannot be empty if not in progress");
    }
  }

  static copy(other: PropertyValueHolder) {
    return new PropertyValueHolder(other.p, Option.copy(other.v).map(BusinessVariableFactory.copyTyped), Option.copy(other.e).map(PropertyValueError.copy));
  }

  static value(v: BusinessVariable) {
    return new PropertyValueHolder(false, Some(Typed.of(v)), None());
  }

  static error(timestamp: LocalDateTime, message: string) {
    return new PropertyValueHolder(false, None(), Some(new PropertyValueError(timestamp, message)));
  }

  static inProgress() {
    return new PropertyValueHolder(true, None(), None());
  }

  get value(): BusinessVariable {
    if (this.e.isEmpty()) {
      if (this.v.isDefined()) {
        return Typed.value(this.v.get());
      } else {
        throw new Error("Property is not defined");
      }
    } else {
      throw new Error("Property is in error state " + this.v.get().toString())
    }
  }

  get error() {
    if (this.e.isEmpty()) {
      throw new Error("Property is correct, non error state");
    } else {
      return this.e.get();
    }
  }

  isOK() {
    return this.v.isDefined();
  }

  isError() {
    return this.e.isDefined();
  }

  isInProgress() {
    return this.p;
  }

}


export class ActionStateError {
  constructor(
    readonly index: number,
    readonly message: string,
    readonly timestamp: LocalDateTime,
  ) {
  }

  static copy(other: ActionStateError) {
    return new ActionStateError(other.index, other.message, LocalDateTime.copy(other.timestamp));
  }
}

export class ComponentErrorInfo {
  constructor(readonly name: string,
              readonly timestamp: Option<LocalDateTime>,
              readonly message: string) {}
}


export class PropertyValueError {
  constructor(readonly timestamp: LocalDateTime,
              readonly message: string) {}

  static copy(other: PropertyValueError) {
    return new PropertyValueError(LocalDateTime.copy(other.timestamp), other.message);
  }

  toString() {
    return this.timestamp.isoSimpleFormattedToMinutes()+": "+this.message;
  }
}
