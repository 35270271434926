import {Injectable} from "@angular/core";
import {clearArray, global, restUrl, toastr} from "@utils";
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {NavigationService} from "@shared";

@Injectable({
  providedIn: 'root',
})
export class OfflineService {

  private _offline = false;
  private offlineSubject = new Subject<boolean>();

  private waitForConnectionPending = false;
  readonly pendingCheckConnectionCallbacks: Array<() => void> = [];

  constructor(readonly http: HttpClient,
              readonly navigationService: NavigationService) {}

  get online() {
    return !this._offline;
  }

  get offline() {
    return this._offline;
  }

  getOfflineObservable() {
    return this.offlineSubject.asObservable();
  }

  init() {
    this._offline = global.offline;
    if(this._offline) {
      this.offlineSubject.next(this._offline);
    }

    if(this._offline) {
      setTimeout(() => {
        this.waitForConnection(Date.now());
      }, 100);
    }

    window.addEventListener("focus", (event) =>{
      this.checkConnection();
    });
  }


  waitForConnection(startTimestamp: number, retry: boolean = false) {

    if(!this.waitForConnectionPending || retry) {
      this.waitForConnectionPending = true;

      const promise = <Observable<HttpResponse<any>>>this.http.get(restUrl("technical/ping"), {
        observe: "response",
        responseType: "text"
      });
      promise.subscribe({
        next: (result: HttpResponse<any>) => {
          this.waitForConnectionPending = false;
          this.navigationService.reloadPage();
        },
        error: (reason) => {

          setTimeout(() => {
            this.waitForConnection(startTimestamp, true);
          }, (Date.now() - startTimestamp) < 5000 ? 200 : 1000); // in the first 5 seconds try every 200ms, then every second
        }
      });
    }
  }



  checkConnection(onConnectionOk: () => void = () => {}) {

    this.pendingCheckConnectionCallbacks.push(onConnectionOk);

    if(this.pendingCheckConnectionCallbacks.length === 1) { // if first
      const promise = <Observable<HttpResponse<any>>>this.http.get(restUrl("technical/ping"), {
        observe: "response",
        responseType: "text"
      });
      promise.subscribe({
        next: (result: HttpResponse<any>) => {
          this.pendingCheckConnectionCallbacks.forEach(callback => callback());
          clearArray(this.pendingCheckConnectionCallbacks);
        },
        error: (reason) => {
          clearArray(this.pendingCheckConnectionCallbacks);
          this.setOffline();
        }
      });
    }
  }

  setOffline() {
    if(!this._offline) {
      this._offline = true;
      this.offlineSubject.next(this._offline);
      setTimeout(() => {
        this.waitForConnection(Date.now());
      }, 200);
    }
  }
}
