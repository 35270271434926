import {
  __,
  ___,
  AnyFlowId,
  AnyFlowIdHelper,
  AnyPersonId,
  AnyPersonIdHelper,
  ApplicationId, colorId,
  FileUri,
  flowCommentAttachmentDownloadUrl,
  formatDurationClock,
  i18n,
  InstanceId,
  LocalDateTime,
  None,
  NoneSingleton,
  now,
  Option, OrganizationNodeId,
  PersonId, ProcessId,
  RemoteFlowId, required,
  Some,
  toastr,
  Typed
} from "@utils";
import {
  AdHocTaskHelper,
  ApplicationIcon,
  BusinessVariable,
  CommentViewModel,
  computeTimeByCalendar,
  FilesSharedService,
  FlowCursorId,
  FlowsSharedService,
  NodeType,
  PersonSummary,
  ProcessEdgeId,
  ProcessFlowComment,
  ProcessNodeId,
  TaskIdentifier,
  TaskListManagerService,
  TaskModelSummary,
  TasksEventBus,
  TasksStatusManager,
  TaskStatus,
  TaskTimeStatus
} from "@shared-model";
import {TaskServerModel} from "./TaskServerModel";
import {TaskEventBus} from "./TaskEventBus";
import {FormElementSummary, ProcessProgressStep, ProcessProgressStepStatus, TaskModel} from "./model/ProcessFlow";
import {DepartmentCalendar, I18nService, NavigationService, ViewableFile, ViewableFileUrl} from "@shared";
import {TasksServerModel} from "./service/TasksServerModel";
import {Subject} from "rxjs";
import {DesignAttachment, NodeActivity, TasksVisibility} from "../process-common.module";


export class TaskActivityViewModel {
  constructor(
    readonly parentTask: TaskModel,
    readonly serverModel: TaskServerModel,
    readonly activityId: number,
    readonly name: string,
    public checked: boolean,
    readonly required: boolean) {}
}

export class ProcessProgressStepViewModel {
  constructor(
    readonly name: string,
    readonly completed: boolean,
    readonly ongoing: boolean,
    readonly upcoming: boolean,
    readonly time: LocalDateTime|null) {}

  static of(other: ProcessProgressStep) {
    return new ProcessProgressStepViewModel(
      other.name,
      other.status === ProcessProgressStepStatus.completed,
      other.status === ProcessProgressStepStatus.ongoing,
      other.status === ProcessProgressStepStatus.upcoming,
      other.time.getOrNull()
    );
  }

}

export class TaskPullActionViewModel {
  constructor(readonly edgeId: ProcessEdgeId,
              readonly name: string,
              readonly cursorId: FlowCursorId) {}
}

export class AlternativeEdgeViewModel {
  constructor(readonly edgeId: ProcessEdgeId,
              readonly name: string) {}
}

export class FormElementSummaryViewModel {
  constructor(
    readonly label: string,
    readonly value: BusinessVariable
  ) {}
}

export class ChangeTaskStatusOption {
  readonly name: string;

  constructor(readonly status: TaskStatus,
              i18nName: string,
              readonly enabled: boolean) {
    this.name = i18n(i18nName);
  }

  // translations need to be loaded before usage, so delay initialization of static code
  static init() {
    if (!ChangeTaskStatusOption.initiated) {
      ChangeTaskStatusOption.todo = new ChangeTaskStatusOption(TaskStatus.todo, "tasks_mark_todo", true);
      ChangeTaskStatusOption.waiting = new ChangeTaskStatusOption(TaskStatus.waiting, "tasks_hold", true);
      ChangeTaskStatusOption.inProgress = new ChangeTaskStatusOption(TaskStatus.inProgress, "tasks_start", true);
      ChangeTaskStatusOption.completed = new ChangeTaskStatusOption(TaskStatus.completed, "tasks_complete", true);

      ChangeTaskStatusOption.todoDisabled = new ChangeTaskStatusOption(TaskStatus.todo, "", false);
      ChangeTaskStatusOption.waitingDisabled = new ChangeTaskStatusOption(TaskStatus.waiting, "tasks_hold", false);
      ChangeTaskStatusOption.inProgressDisabled = new ChangeTaskStatusOption(TaskStatus.inProgress, "tasks_start", false);
      ChangeTaskStatusOption.completedDisabled = new ChangeTaskStatusOption(TaskStatus.completed, "tasks_complete", false);
      ChangeTaskStatusOption.initiated = true;
    }
  }

  static initiated = false;

  static todo: ChangeTaskStatusOption = null!;
  static waiting: ChangeTaskStatusOption = null!;
  static inProgress: ChangeTaskStatusOption = null!;
  static completed: ChangeTaskStatusOption = null!;

  static todoDisabled: ChangeTaskStatusOption = null!;
  static waitingDisabled: ChangeTaskStatusOption = null!;
  static inProgressDisabled: ChangeTaskStatusOption = null!;
  static completedDisabled: ChangeTaskStatusOption = null!;

}

interface TaskLabel {
  name: string;
  color: number;
}

export class SubmitTaskButtonViewModel {
  constructor(readonly name: string,
              readonly edgeId: ProcessEdgeId) {}
}

export class TaskViewModel {

  timeInQueue: Option<number> = NoneSingleton;
  timeInQueueLabel: string = "";
  timeToDeadline: Option<number> = NoneSingleton;

  redirectActionEdge: Option<AlternativeEdgeViewModel> = None();
  redirectComment: string = "";

  readonly taskDescriptionVisible: boolean;
  readonly attachmentsListVisible: boolean;
  readonly attachmentsListNotYetVisible: boolean;

  private commentsSubscriptions: number[] = [];
  private tasksSubscriptions: number[] = [];
  private taskSubscriptions: number[] = [];

  public sharedFilesNames = {};
  public sharedFiles: Array<ViewableFile> = [];
  public showSharedFiles: boolean = false;
  public fileSharedIndex: number = 0;

  public changeStatusOptions: Array<ChangeTaskStatusOption> = [];
  public statusSelectorVisible = false;

  private dueDateStringSource: Option<LocalDateTime> = None();
  private dueDateString: Option<string> = None();

  private summaryElementsViewModel: Array<FormElementSummaryViewModel> = [];

  statusName = "?";

  readOnly = false;


  personsAssignedSubject = new Subject<Array<AnyPersonId>>();
  taskLabels: Array<TaskLabel> = [];
  taskSystemLabels: Array<BusinessVariable> = [];
  selectedPropertiesTab: string|undefined = "details";
  redirectConfirmationVisible: boolean = false;
  redirectConfirmationText: string = "";
  rediectConfirmationElement: HTMLElement|undefined = undefined;

  actionsMenuVisible: boolean = false;

  submitRequest: Subject<ProcessEdgeId> = new Subject<ProcessEdgeId>();

  propertiesExpanded: boolean = false;
  isInProgressOnTime: boolean = true;
  isDelayed: boolean = false;
  isAtRisk: boolean = false;
  isAtHighRisk: boolean = false;
  isFinishedOnTime: boolean = false;
  isFinishedLate: boolean = false;

  public mobile: boolean = false;

  activitiesValidationPassed: boolean = true;
  commentsCount: number = 0;

  instructionVisible: boolean;

  constructor(
    readonly applicationId: Option<ApplicationId>,
    readonly i18nService: I18nService,
    readonly serverModel: TaskServerModel,
    readonly tasksServerModel: TasksServerModel,
    readonly eventBus: TaskEventBus,
    readonly tasksEventBus: TasksEventBus,
    readonly filesSharedService: FilesSharedService,
    readonly tasksStatusManager: TasksStatusManager,
    public flowId: Option<AnyFlowId>,
    public remoteOrganizationName: string,
    readonly instanceId: InstanceId,
    readonly nodeId: ProcessNodeId,
    readonly nodeType: NodeType,
    public status: TaskStatus,
    public created: LocalDateTime,
    public started: Option<LocalDateTime>,
    public completed: Option<LocalDateTime>,
    public deadline: Option<LocalDateTime>,
    public flowCode: Option<string>,
    public taskDescription: string,
    public nodeName: string,
    public processName: string,
    public description: string,
    readonly roleId: number,
    readonly roleName: string,
    public comments: Array<ProcessFlowComment>,
    public personsAssigned: Array<AnyPersonId>,
    public assigneeLimit: number,
    public activities: Array<TaskActivityViewModel>,
    readonly processProgress: Array<ProcessProgressStepViewModel>,
    public labels: Array<string>,
    public systemLabels: Array<BusinessVariable>,
    readonly attachments: Array<DesignAttachment>,
    readonly canPreviewFlow: boolean,
    readonly departmentCalendar: DepartmentCalendar,
    readonly timezone: string,
    public alternativeActions: Array<AlternativeEdgeViewModel>,
    public pullActions: Array<TaskPullActionViewModel>,
    readonly submitButtons:  Array<SubmitTaskButtonViewModel>,
    public submitTaskButtonDisabled: boolean,
    public canChangeAssignee: boolean,
    public canAssignOther: boolean,
    public canAssignMySelf: boolean,
    public assignToMeButtonVisible: boolean,
    public startButtonVisible: boolean,
    public cancelButtonVisible: boolean,
    public redirectButtonsEnabled: boolean,
    public currentPersonId: PersonId,
    public allRequiredFieldsFilled: boolean,
    public summaryElements: Array<FormElementSummary>,
    public color: Option<string>,
    public importance: number,
    public urgency: number,
    public canChangeImportance: boolean,
    public canChangeUrgency: boolean,
    public canChangeLabels: boolean,
    public commentsAccess: boolean,
    public applicationName: string,
    public applicationIcon: ApplicationIcon,
    readonly taskListManagerService: TaskListManagerService,
    readonly flowsSharedService: FlowsSharedService,
    readonly navigationService: NavigationService,
    readonly screenBased: boolean,
    readonly estimatedDurationSeconds: Option<number>,
    public seen: boolean,
    readonly adHocTask: boolean,
    readonly instruction: string
  ) {

    this.instructionVisible = instruction.length > 0;
    this.commentsCount = comments.length;

    this.updateSummaryVariables(summaryElements);
    this.updateReadOnly();
    this.updateTimes();
    this.taskDescriptionVisible = this.taskDescription.trim().length > 0;
    this.attachmentsListVisible = attachments.length > 0 && flowId.isDefined();
    this.attachmentsListNotYetVisible = attachments.length > 0 && flowId.isEmpty();

    this.statusName = (this.nodeType.isStartForm() && this.status.name === TaskStatus.completed.name)
      ? i18n("task_status_sent")
      : i18n("task_status_" + this.status.name);

    this.updateChangeStatusOptions();

    this.taskLabels = labels.map(l => {
      return {name: l, color: colorId(l)};
    });

    this.selectDefaultTab();

    this.taskSubscriptions.push(eventBus.on(this.eventBus.activityStateChanged, (flowId: AnyFlowId, nodeId: ProcessNodeId, activityId: number, checked: boolean) => {
      this.onActivityStateChanged(flowId, nodeId, activityId, checked);
    }));

    this.taskSubscriptions.push(eventBus.on(this.eventBus.commentAdded, (flowId: AnyFlowId, personId: AnyPersonId, commentId: number, extraRecipients: Array<AnyPersonId>, attachments: Array<FileUri>, commentText: string) => {
      this.onCommentAdded(flowId, personId, commentId, extraRecipients, attachments, commentText);
    }));

    this.tasksSubscriptions.push(tasksEventBus.on(this.tasksEventBus.flowIdChanged, (oldId: AnyFlowId, newId: AnyFlowId) => {
      this.onFlowIdChanged(oldId, newId);
    }));

    this.tasksSubscriptions.push(tasksEventBus.on(this.tasksEventBus.tasksUpdated, (added: Array<TaskModelSummary>, updated: Array<TaskModelSummary>, removed: Array<TaskIdentifier>, personsInfo: Array<PersonSummary>) => {
      __(updated).find(t => this.flowId.exists(f => AnyFlowIdHelper.equals(f, t.flowIdUnwrapped())) && t.nodeId === this.nodeId).forEach(task => {
        this.onTaskSummaryUpdated(task);
      });
    }));

    this.tasksSubscriptions.push(tasksEventBus.on(this.tasksEventBus.taskSubmitted, (flowId: AnyFlowId, nodeId: ProcessNodeId) => {
      this.completed = Some(now());
      this.submitTaskButtonDisabled = true;
      this.redirectButtonsEnabled = false;
    }));

    this.tasksSubscriptions.push(tasksEventBus.on(this.tasksEventBus.taskRedirected, (flowId: AnyFlowId, nodeId: ProcessNodeId) => {
      this.completed = Some(now());
      this.submitTaskButtonDisabled = true;
      this.redirectButtonsEnabled = false;
    }));

    this.taskSubscriptions.push(eventBus.on(this.eventBus.taskUpdateFailed, (labels: Option<Array<string>>, personsAssigned: Option<Array<AnyPersonId>>, started: Option<Option<LocalDateTime>>) => {
      this.onTaskUpdateFailed(labels, personsAssigned, started);
    }));

    this.tasksSubscriptions.push(tasksEventBus.on(this.tasksEventBus.taskAssigned, (taskId: TaskIdentifier, personId: AnyPersonId) => {
      if (this.flowId.exists(f => AnyFlowIdHelper.equals(f, taskId.flowIdUnwrapped())) && this.nodeId === taskId.nodeId) {
        this.onTaskAssigned(personId);
      }
    }));

    this.tasksSubscriptions.push(tasksEventBus.on(this.tasksEventBus.taskUnassigned, (taskId: TaskIdentifier, personId: AnyPersonId) => {
      if (this.flowId.exists(f => AnyFlowIdHelper.equals(f, taskId.flowIdUnwrapped())) && this.nodeId === taskId.nodeId) {
        this.onTaskUnassigned(personId);
      }
    }));

    this.taskSubscriptions.push(eventBus.on(this.eventBus.taskStarted, (taskId: TaskIdentifier) => {
      if (this.flowId.exists(f => AnyFlowIdHelper.equals(f, taskId.flowIdUnwrapped())) && this.nodeId === taskId.nodeId) {
        this.onTaskStarted();
      }
    }));

    this.taskSubscriptions.push(eventBus.on(this.eventBus.activityValidationFailed, () => {
      this.activitiesValidationPassed = false;
    }));

    this.eventBus.on(eventBus.taskRequiredFieldsFilled, (check: boolean) => this.allRequiredFieldsFilled = check);

    this.updateTimeStatuses();
  }

  setMobile(mobile: boolean) {
    if(this.mobile !== mobile) {
      this.mobile = mobile;
      this.collapseProperties();
      this.selectDefaultTab();
    }
  }




  withSelectedComments(comments: boolean): this {
    if(comments) {
      this.selectedPropertiesTab = "comments";
    }
    return this;
  }

  updateChangeStatusOptions() {
    ChangeTaskStatusOption.init();

    const assignedToMe = __(this.personsAssigned).exists(p => AnyPersonIdHelper.equals(p, this.currentPersonId));

    this.changeStatusOptions = [
      (this.status.name != ChangeTaskStatusOption.todo.status.name) ? ChangeTaskStatusOption.todo : ChangeTaskStatusOption.todoDisabled,
      (this.status.name != ChangeTaskStatusOption.waiting.status.name) ? ChangeTaskStatusOption.waiting : ChangeTaskStatusOption.waitingDisabled,
      (this.status.name != ChangeTaskStatusOption.inProgress.status.name) ? ChangeTaskStatusOption.inProgress : ChangeTaskStatusOption.inProgressDisabled,
      (this.status.name != ChangeTaskStatusOption.completed.status.name && assignedToMe) ? ChangeTaskStatusOption.completed : ChangeTaskStatusOption.completedDisabled];

  }

  updateReadOnly() {
    this.readOnly = !((this.currentPersonId && PersonId.isWebClient(this.currentPersonId) || __(this.personsAssigned).exists(p => AnyPersonIdHelper.equals(p, this.currentPersonId))) &&
      ((this.flowCode.isEmpty() || this.flowCode.get().length === 0) || this.completed.isEmpty()));
  }

  assigneeLimitNotReached() {
    return this.assigneeLimit > this.personsAssigned.length;
  }


  updateTimeStatuses() {

    const taskStatus = this.tasksStatusManager.calculateTaskStatus(this.started, this.deadline, this.estimatedDurationSeconds);

    this.isInProgressOnTime = this.completed.isEmpty() && taskStatus === TaskTimeStatus.onTime;

    this.isDelayed = this.completed.isEmpty() && taskStatus === TaskTimeStatus.delayed;

    this.isAtRisk = this.completed.isEmpty() && taskStatus === TaskTimeStatus.atRisk;

    this.isAtHighRisk = this.completed.isEmpty() && taskStatus === TaskTimeStatus.atHighRisk;

    this.isFinishedOnTime = this.completed.isDefined() && (!this.deadline.isDefined() || this.deadline.get().isAfter(this.completed.get()));

    this.isFinishedLate = this.completed.isDefined() && this.deadline.isDefined() && this.deadline.get().isBefore(this.completed.get());

  }

  updateSummaryVariables(summaryElements: Array<FormElementSummary>) {
    const uris = [];

    // this.summaryElements.forEach(sV => {
    //   if(Typed.className(sV.value) === FileVariableV2.className){
    //     uris.push((<FileVariableV2>sV.unwrappedValue()).value)
    //   } else if(Typed.className(sV.value) === ArrayVariable.className) {
    //     (<ArrayVariable<BusinessVariable>>sV.unwrappedValue()).unwrappedValue().forEach(value => {
    //       if(value.className() === FileVariableV2.className) {
    //         uris.push(value.value)
    //       } else if(value.className() === ArrayVariable.className) {
    //         (<ArrayVariable<BusinessVariable>>value).unwrappedValue().forEach(value => {
    //           if(value.className() === FileVariableV2.className) {
    //             uris.push(value.value)
    //           }
    //         })
    //       }
    //     })
    //   }
    // });

    this.summaryElementsViewModel = summaryElements.filter(e => e.variable.variable.isDefined() && !e.variable.unwrappedVariableOption().get().isEmpty()).map(element => {
      return new FormElementSummaryViewModel(element.elementRefUnwrapped().label.getCurrentWithFallback(), element.variable.unwrappedVariableOption().get());
    });

    // this.filesService.filesInfo(uris, (files) => {
    //   this.sharedFiles = __(files).map(basicFile => {
    //     const file = <FileInfo>basicFile;
    //     this.sharedFilesNames[file.uri.path] = file.name;
    //     return new ViewableFile(file.name,
    //       file.size,
    //       Some(file.version),
    //       new ViewableFileUrl(
    //         attachments.taskFlowAttachmentDownloadUrl(this.flowId.get(), this.nodeId, this.file.uri)
    //       ),
    //       file.modified,
    //       file.uri,
    //       this.flowId,
    //       Some(this.nodeId)
    //     )
    //   });
    // });
  }

  destroy() {
    this.taskSubscriptions.forEach(s => this.eventBus.unsubscribe(s));
    this.tasksSubscriptions.forEach(s => this.tasksEventBus.unsubscribe(s));
  }


  static of(mobile: boolean, task: TaskModel, applicationName: string, applicationIcon: ApplicationIcon, taskServerModel: TaskServerModel, tasksServerModel: TasksServerModel,
            taskEventBus: TaskEventBus, tasksEventBus: TasksEventBus,
            filesSharedService: FilesSharedService, currentPersonId: PersonId,
            departmentCalendar: DepartmentCalendar, timezone: string,
            tasksStatusManager: TasksStatusManager, i18nService: I18nService, taskListManagerService: TaskListManagerService,
            flowsSharedService: FlowsSharedService, navigationService: NavigationService,
            adHocTaskHelper: AdHocTaskHelper): TaskViewModel {


    const flowCode = task.flowCode && task.flowCode.length > 0 ? Some(task.flowCode) : None();

    const defaultButtonName = task.nodeType.isStartForm()
      ? i18n("dashboard_Form_StartButton")
      : i18n("dashboard_Form_SubmitButton");



    const submitButtons = ___(task.mainEdges)
      .map(e => new SubmitTaskButtonViewModel(e.edgeName.map(e => e.getCurrentWithFallback()).getOrElse(defaultButtonName), e.edgeId))
      .sortBy(b => b.edgeId)
      .value();

    const flowId = Typed.value(task.flowId);

    const remoteOrganizationName = (flowId instanceof RemoteFlowId) ? flowId.remoteOrganization : "";

    const canAssignOther = task.tasksVisibility.name === TasksVisibility.allInCase.name || task.tasksVisibility.name === TasksVisibility.allInRole.name;

    const processId = ProcessId.of(task.processId);

    const model = new TaskViewModel(
      task.applicationId,
      i18nService,
      taskServerModel,
      tasksServerModel,
      taskEventBus,
      tasksEventBus,
      filesSharedService,
      tasksStatusManager,
      Some(flowId),
      remoteOrganizationName,
      InstanceId.of(task.instanceId),
      task.nodeId,
      task.nodeType,
      task.taskStatus,
      task.created,
      task.started,
      task.completed,
      task.deadline,
      flowCode,
      task.taskDescription,
      task.nodeName,
      task.processName,
      task.flowDescription.trim(),
      task.roleId,
      task.roleName,
      task.comments,
      task.personsAssigned.map(Typed.value),
      task.assigneeLimit,
      TaskViewModel.createActivities(task, task.activities, task.completedActivities, taskServerModel),
      task.processProgress.map(ProcessProgressStepViewModel.of),
      task.labels,
      task.systemLabelsUnwrapped(),
      task.attachments,
      true,
      departmentCalendar,
      timezone,
      task.availableAlternativeEdges.map(edge => new AlternativeEdgeViewModel(edge.edgeId, edge.edgeName.getOrElse(edge.toNodeName).getCurrentWithFallback())),
      task.pullActions.map(edge => new TaskPullActionViewModel(edge.edgeId, edge.edgeName.getOrElse(edge.toNodeName).getCurrentWithFallback(), edge.cursorId)),
      submitButtons,
      task.completed.isDefined() || __(task.personsAssignedUnwrapped()).notExists(p => AnyPersonIdHelper.equals(p, currentPersonId)),
      task.assignableToMe,
      canAssignOther,
      task.assignableToMe,
      task.assignableToMe && __(task.personsAssignedUnwrapped()).notExists(p => AnyPersonIdHelper.equals(p, currentPersonId)) && task.completed.isEmpty(),
      task.started.isEmpty() && flowCode.isDefined() && task.completed.isEmpty() &&
      (task.assignableToMe && task.personsAssignedUnwrapped().length == 0 || __(task.personsAssignedUnwrapped()).exists(p => AnyPersonIdHelper.equals(p, currentPersonId))),
      flowCode.isEmpty() && task.isMaterialized() && task.completed.isEmpty() || !task.isMaterialized() && task.completed.isEmpty(),
      task.completed.isEmpty() && task.completed.isEmpty(),
      currentPersonId,
      false,
      task.summaryElements,
      task.colorOverride.getOrElse(task.flowColor.orElse(task.instanceColor)),
      task.importanceOverride.getOrElse(task.importance),
      task.urgencyOverride.getOrElse(task.urgency),
      task.canChangeImportance,
      task.canChangeUrgency,
      task.canChangeLabels,
      task.commentsAccess,
      applicationName,
      applicationIcon,
      taskListManagerService,
      flowsSharedService,
      navigationService,
      task.screenBased,
      task.estimatedDurationSeconds,
      task.seen,
      processId.isAdHocTask(),
      task.taskInstruction
    );

    model.setMobile(mobile);

    return model;
  }


  importanceSelected() {
    this.tasksServerModel.overrideImportance(this.taskId(), Some(this.importance));
  }

  showStatusSelector() {
    if (!this.statusSelectorVisible) {
      this.statusSelectorVisible = true;
      this.tasksEventBus.statusSelectorShown();
    }
  }

  hideStatusSelector() {
    this.statusSelectorVisible = false;
  }

  selectStatus(enabled: boolean, status: TaskStatus) {

    if (enabled && status.name != this.status.name) {

      switch (this.status.name) {
        case TaskStatus.todo.name:
          switch (status.name) {
            case TaskStatus.inProgress.name:
              this.tasksServerModel.startTask(this.taskId());
              break;
            case TaskStatus.waiting.name:
              this.tasksServerModel.markTaskWaiting(this.taskId());
              break;
            case TaskStatus.completed.name:
              this.getSingleMainEdgeId(edgeId => this.tasksServerModel.finishTask(this.taskId(), edgeId));
              break;
            default:
              toastr.info("Changing from " + this.status.name + " to " + status.name + " is not supported");
          }
          break;
        case TaskStatus.waiting.name:
          switch (status.name) {
            case TaskStatus.todo.name:
              this.tasksServerModel.stopTask(this.taskId());
              break;
            case TaskStatus.inProgress.name:
              this.tasksServerModel.startTask(this.taskId());
              break;
            case TaskStatus.completed.name:
              this.getSingleMainEdgeId(edgeId => this.tasksServerModel.finishTask(this.taskId(), edgeId));
              break;
            default:
              toastr.info("Changing from " + this.status.name + " to " + status.name + " is not supported");
          }
          break;
        case TaskStatus.inProgress.name:
          switch (status.name) {
            case TaskStatus.todo.name:
              this.tasksServerModel.stopTask(this.taskId());
              break;
            case TaskStatus.waiting.name:
              this.tasksServerModel.markTaskWaiting(this.taskId());
              break;
            case TaskStatus.completed.name:
              this.getSingleMainEdgeId(edgeId => this.tasksServerModel.finishTask(this.taskId(), edgeId));
              break;
            default:
              toastr.info("Changing from " + this.status.name + " to " + status.name + " is not supported");
          }
          break;
        default:
          toastr.info("Changing from " + this.status.name + " to " + status.name + " is not supported");
      }
      this.status = status;
      this.statusName = (this.nodeType.isStartForm() && this.status.name === TaskStatus.completed.name)
        ? i18n("task_status_sent")
        : i18n("task_status_" + this.status.name);
    }
    this.statusSelectorVisible = false;
  }

  isNew() {
    return this.flowCode.isEmpty();
  }

  is(taskIdentifier: TaskIdentifier) {
    return this.flowId.isEmpty() || AnyFlowIdHelper.equals(this.flowId.get(), taskIdentifier.flowIdUnwrapped()) && this.nodeId === taskIdentifier.nodeId;
  }


  openFileViewer(fileUri: FileUri) {
    this.fileSharedIndex = __(this.sharedFiles).findIndexOf(sF => sF.fileUri.isEqual(fileUri)).getOrElse(0);
    this.showSharedFiles = true;
  }

  closeFileViewer() {
    this.showSharedFiles = false;
  }

  private onTaskSummaryUpdated(task: TaskModelSummary) {
    this.status = task.taskStatus;
    this.created = task.created;
    this.started = task.started;
    this.completed = task.completed;
    this.deadline = task.deadline;
    this.flowCode = task.flowCode;
    this.taskDescription = task.taskDescription;
    this.processName = task.processName;
    this.nodeName = task.nodeName;
    this.description = task.flowDescription.trim();
    this.personsAssigned = task.personsAssignedUnwrapped();
    this.personsAssignedSubject.next(this.personsAssigned);
    this.assigneeLimit = task.assigneeLimit;
    this.canAssignMySelf = task.assignableToMe;
    this.canChangeAssignee = task.assignableToMe;
    this.canAssignOther = task.canAssignOther;
    this.labels = task.labels;
    this.systemLabels = task.systemLabelsUnwrapped();
    this.cancelButtonVisible = task.flowCode.isEmpty() && task.isMaterialized() && task.completed.isEmpty() || !task.isMaterialized() && task.completed.isEmpty();
    this.assignToMeButtonVisible = task.assignableToMe && __(task.personsAssignedUnwrapped()).notExists(p => AnyPersonIdHelper.equals(p, this.currentPersonId)) && task.completed.isEmpty();
    this.startButtonVisible = task.started.isEmpty() && task.flowCode.isDefined() && task.completed.isEmpty();
    this.submitTaskButtonDisabled = task.completed.isDefined() || __(task.personsAssignedUnwrapped()).notExists(p => AnyPersonIdHelper.equals(p, this.currentPersonId));
    this.redirectButtonsEnabled = task.completed.isEmpty();
    this.color = task.colorOverride.getOrElse(task.flowColor).orElse(task.instanceColor);
    this.importance = task.currentImportance();
    this.urgency = task.currentUrgency();
    this.canChangeImportance = task.canChangeImportance;
    this.canChangeUrgency = task.canChangeUrgency;
    this.canChangeLabels = task.canChangeLabels;
    this.commentsAccess = task.commentsAccess;
    this.alternativeActions = task.availableAlternativeEdges.map(edge => new AlternativeEdgeViewModel(edge.edgeId, edge.edgeName.getOrElse(edge.toNodeName).getCurrentWithFallback()));
    this.pullActions = task.pullActions.map(edge => new TaskPullActionViewModel(edge.edgeId, edge.edgeName.getOrElse(edge.toNodeName).getCurrentWithFallback(), edge.cursorId));

    this.statusName = (this.nodeType.isStartForm() && this.status.name === TaskStatus.completed.name)
      ? i18n("task_status_sent")
      : i18n("task_status_" + this.status.name);

    this.updateChangeStatusOptions();
    this.updateReadOnly();
    this.updateTimeStatuses();

  }


  private onTaskUpdateFailed(labels: Option<Array<string>>, personsAssigned: Option<Array<AnyPersonId>>, started: Option<Option<LocalDateTime>>) {
    labels.forEach(l => this.labels = l);
    personsAssigned.forEach(p => this.personsAssigned = p);
    this.personsAssignedSubject.next(this.personsAssigned);
    started.forEach(s => this.started = s);
  }


  private static createActivities(task: TaskModel, activities: NodeActivity[], completed: number[], taskServerModel: TaskServerModel): TaskActivityViewModel[] {
    return activities.map((a: NodeActivity) => new TaskActivityViewModel(task, taskServerModel, a.id, a.name + (a.required ? " *" : ""), __(completed).contains(a.id), a.required));
  }

  labelAdded(label: string) {
    this.serverModel.addLabel(this.taskId(), label.trim());
  }

  labelRemoved(label: string) {
    this.serverModel.removeLabel(this.taskId(), label);
  }

  taskId() {
    return TaskIdentifier.of(this.flowId.get(), this.nodeId);
  }


  // private onLocalCommentAdded(comment: CommentViewModel) {
  //
  //   const newCommentId = ___(this.comments.comments).map(c => c.commentId).maxOrDefault(0) + 1;
  //   comment.commentId = newCommentId;
  //   this.serverModel.sendComment(this.taskId(), comment);
  //
  //   if(__(this.comments.comments).notExists(c => c.commentId === comment.commentId)) {
  //     this.comments.pushNewComment(CommentViewModel.copyNewComment(comment));
  //   }
  //
  // }

  // private onLocalCommentChanged(comment: CommentViewModel) {
  //   this.serverModel.changeComment(this.taskId(), comment);
  // }
  //
  // private onLocalCommentDeleted(comment: CommentViewModel) {
  //   this.serverModel.deleteComment(this.taskId(), comment.commentId);
  //   comment.markAsDeleted();
  // }

  updateTimes(): boolean {
    const timeInQueue = computeTimeByCalendar(this.created, this.started.getOrElse(now()),
      this.departmentCalendar, this.timezone);
    const timeToDeadline = this.deadline.map(end =>
      computeTimeByCalendar(now(), end, this.departmentCalendar, this.timezone));


    if (!this.timeInQueue || !this.timeInQueue.exists(t => t === timeInQueue) || timeToDeadline.notEquals(this.timeToDeadline)) {
      this.timeInQueue = Some(timeInQueue);
      this.timeInQueueLabel = formatDurationClock(timeInQueue);
      this.timeToDeadline = timeToDeadline;
      return true;
    } else {
      return false;
    }
  }

  commentAttachmentDownloadUrl = (fileInfo: FileUri): ViewableFileUrl => {
    return new ViewableFileUrl(flowCommentAttachmentDownloadUrl(this.flowId.get(), fileInfo));
  };

  isReadonly() {
    return ((this.started.isEmpty() && !this.nodeType.isActionForm()) || this.personsAssigned.length == 0);
  }

  submitForm(edgeId: ProcessEdgeId) {
    if (this.screenBased) {
      this.submitRequest.next(edgeId);
    } else {
      this.serverModel.submitForm(this.taskId(), edgeId);
    }
  }

  onSubmitted() {
    this.activitiesValidationPassed = true;
    this.tasksEventBus.taskSubmitted(this.flowId.get(), this.nodeId);
  }

  redirectByEdge(edge: AlternativeEdgeViewModel, $event: MouseEvent): void {
    if ($event.target === null) {
      throw new Error("Event target is null");
    } else {

      this.rediectConfirmationElement = $event.target as HTMLElement;
      this.redirectConfirmationVisible = true;
      this.redirectConfirmationText = edge.name;
      this.redirectButtonsEnabled = false;
      this.redirectActionEdge = Some(edge);
    }
  }

  pullByAction(action: TaskPullActionViewModel, $event: MouseEvent) {
    this.serverModel.pullTask(this.taskId(), action.edgeId, action.cursorId, None());
  }

  acceptRedirectAction() {

    if (this.redirectComment.trim().length === 0) {
      toastr.error(i18n("Comment required"));
    } else {

      const newCommentId = ___(this.comments).map(c => c.commentId).maxOrDefault(0) + 1;
      const comment = new CommentViewModel(newCommentId, OrganizationNodeId.fromPersonId(this.currentPersonId), this.redirectComment.trim(), [], [], now(), null, false);

      this.serverModel.redirectTask(this.taskId(), this.redirectActionEdge.get().edgeId, Some(comment));
      this.completed = Some(LocalDateTime.now());
      this.closeRedirectAction();
      this.updateTimeStatuses();
    }
  }

  cancelRedirectAction() {
    this.closeRedirectAction();
    this.redirectButtonsEnabled = this.completed.isEmpty();
  };

  closeRedirectAction() {
    this.redirectConfirmationVisible = false;
    this.redirectConfirmationText = "";
    this.rediectConfirmationElement = undefined;
    this.redirectActionEdge = None();
    this.redirectComment = "";
  }

  private onActivityStateChanged(flowId: AnyFlowId, nodeId: ProcessNodeId, activityId: number, checked: boolean) {

    if (this.flowId.exists(f => AnyFlowIdHelper.equals(f, flowId)) && this.nodeId === nodeId) {
      this.activities
        .filter(a => a.activityId === activityId)
        .forEach(a => a.checked = checked);
    }

  }

  private onCommentAdded(flowId: AnyFlowId, personId: AnyPersonId, commentId: number, extraRecipients: Array<AnyPersonId>, attachments: Array<FileUri>, commentText: string) {

    if (this.flowId.exists(f => AnyFlowIdHelper.equals(f, flowId))) {
      if (__(this.comments).notExists(c => c.commentId === commentId)) {
        console.log("Adding comment Not implemented");
      }
    }

  }


  private onFlowIdChanged(oldId: AnyFlowId, newId: AnyFlowId) {
    if (this.flowId.exists(f => f.id === oldId.id)) {
      this.flowId = Some(newId);
    }
  }

  unassignCurrentPerson() {
    toastr.error("Not implemented");
    // this.serverModel.unassignPersonFromTask(this.taskId()this.roleId,
  }


  unassignPerson(person: AnyPersonId) {
    this.serverModel.unassignPersonFromTask(this.taskId(), this.roleId, person);
  }

  assignSelf() {
    if (this.assigneeLimit > 1) {
      this.personsAssigned = this.personsAssigned.concat([this.currentPersonId]);
      this.serverModel.assignCurrentPersonToTask(this.taskId(), this.roleId);
    } else {
      this.personsAssigned = [this.currentPersonId];
      this.serverModel.assignCurrentPersonToTask(this.taskId(), this.roleId);
    }
    this.personsAssignedSubject.next(this.personsAssigned);
  }

  private onTaskAssigned(personId: AnyPersonId) {
    if (!__(this.personsAssigned).exists(p => p.equals(personId))) {
      this.personsAssigned.push(personId);
    }
    this.personsAssignedSubject.next(this.personsAssigned);
    if (AnyPersonIdHelper.equals(personId, this.currentPersonId)) {
      this.assignToMeButtonVisible = false;
      this.startButtonVisible = this.started.isEmpty() && this.flowCode.isDefined();
      this.submitTaskButtonDisabled = false;
    } else {
      this.assignToMeButtonVisible = this.canAssignMySelf;
      this.startButtonVisible = false;
      this.submitTaskButtonDisabled = true;
    }
    this.updateReadOnly();
  }

  private onTaskUnassigned(personId: AnyPersonId) {
    this.personsAssigned = this.personsAssigned.filter(p => !p.equals(personId));
    this.personsAssignedSubject.next(this.personsAssigned);
    this.assignToMeButtonVisible = this.canAssignMySelf;
    this.startButtonVisible = this.canAssignMySelf && this.started.isEmpty() && this.flowCode.isDefined();
    this.submitTaskButtonDisabled = true;
    this.updateReadOnly();
  }

  private onTaskStarted() {
    this.assignToMeButtonVisible = false;
    this.startButtonVisible = false;
    this.submitTaskButtonDisabled = false;
  }

  startTask() {
    this.startButtonVisible = false;
    this.submitTaskButtonDisabled = false;

    this.serverModel.startTask(this.taskId());
  }


  cancelFlow() {
    if (this.isMaterialized()) {
      this.cancelButtonVisible = false;
      this.submitTaskButtonDisabled = true;
      this.redirectButtonsEnabled = false;

      this.tasksEventBus.newFlowCancelled(this.flowId.get());
      this.serverModel.cancelFlow(this.taskId());
    } else {
      this.tasksEventBus.newFlowClosed();
    }
  }

  assignPerson(personId: AnyPersonId) {
    if (personId.equals(this.currentPersonId)) {
      this.assignSelf();
    } else {
      if (this.assigneeLimit > 1) {
        this.personsAssigned = this.personsAssigned.concat([personId]);
        this.serverModel.addPersonToTask(personId, this.taskId(), this.roleId);
      } else {
        this.personsAssigned = [personId];
        this.serverModel.assignPersonToTask(personId, this.taskId(), this.roleId);
      }

      this.personsAssignedSubject.next(this.personsAssigned);
    }
    this.updateReadOnly();
  }

  isAvailable() {
    return this.personsAssigned.length == 0 && this.started.isEmpty();
  }

  isTodo() {
    return this.personsAssigned.length > 0 && this.started.isEmpty();
  }

  isInProgress() {
    return this.started.isDefined() && this.completed.isEmpty();
  }

  isDone() {
    return this.completed.isDefined(); // && !this.canceled; TODO canceled is used in TaskModelSummary, probably should be taken into consideration here
  }

  isMaterialized() {
    return this.flowId.isDefined() && AnyFlowIdHelper.isMaterialized(this.flowId.get());
  }

  printTaskForm = () => {
    this.eventBus.printTaskForm();
  };

  removeLabel(name: string) {
    this.taskLabels.splice(__(this.taskLabels).findIndexOf(l => l.name === name).getOrElse(-1), 1);
    this.serverModel.removeLabel(this.taskId(), name);
  }

  addLabel(label: string) {
    this.taskLabels.push({name: label, color: colorId(label)});
    this.serverModel.addLabel(this.taskId(), label);
  }

  getPlainLabels(): Array<string> {
    return this.taskLabels.map(l => l.name);
  }

  toggleActionsMenu() {
    this.actionsMenuVisible = !this.actionsMenuVisible;
  }

  saveWorkingCopy() {
    this.serverModel.saveAsWorkingCopy(this.taskId(), () => {
      this.tasksEventBus.newFlowClosed();
    });
  }

  notSendStartForm() {
    return this.flowCode.isEmpty();
  }

  assignTaskToCurrentUser() {
    this.tasksServerModel.assignTaskToCurrentUser(this.taskId(), this.roleId, this.currentPersonId);
  }

  unassignPersonFromTask() {
    this.tasksServerModel.unassignPersonFromTask(this.taskId(), this.roleId, this.currentPersonId);
  }


  collapseProperties() {
    if(this.propertiesExpanded) {
      this.navigationService.navigateBack();
    }
  }

  expandProperties() {
    if(this.mobile && !this.propertiesExpanded) {
      this.navigationService.pushTemporaryState(() => {
        this.propertiesExpanded = false;
        this.selectedPropertiesTab = undefined;
      });
      this.propertiesExpanded = this.selectedPropertiesTab !== undefined;
    } else if(this.mobile && this.propertiesExpanded && this.selectedPropertiesTab == undefined) {
      this.collapseProperties();
    }
  }

  unselectTab() {
   this.selectedPropertiesTab = undefined;
  }

  selectDefaultTab() {
    if(this.mobile) {
      this.selectedPropertiesTab = undefined;
    } else {
      this.selectedPropertiesTab = "details";
    }
  }

  changeDeadline(deadline: LocalDateTime | null) {
    this.serverModel.changeDeadline(this.taskId(), deadline);
    this.deadline = Option.of(deadline);
    this.updateTimeStatuses();
  }

  flowIdChanged(newFlowId: AnyFlowId) {
    this.tasksEventBus.flowIdChanged(this.flowId.getOrError("No flow id"), newFlowId);
  }

  activityToggled(activity: TaskActivityViewModel) {
    this.serverModel.toggleActivity(this.taskId(), activity.activityId, activity.checked);
  }

  selectTab(tab: "details"|"comments") {
    if(this.selectedPropertiesTab === tab && this.mobile) {
      this.selectedPropertiesTab = undefined;
    } else {
      this.selectedPropertiesTab = tab;
    }
    this.expandProperties();
  }

  commentsChanged(commentsCount: number) {
    this.commentsCount = commentsCount;
  }

  private getSingleMainEdgeId(onSuccess: (edgeId: number) => void): void {
    if(this.submitButtons.length === 1) {
      onSuccess(required(this.submitButtons[0], "button").edgeId);
    } else if(this.submitButtons.length === 0) {
      toastr.info("Task has no main buttons, cannot submit");
    } else {
      toastr.info("Task has alternative main buttons, cannot submit");
    }
  }
}
