import {Typed} from "../data-types/Typed";

export class AggregateId {
  private readonly _AggregateId: undefined; // force TypeScript to check types (workaround for duck typing)
  static EMPTY: AggregateId = new AggregateId("");
  constructor(readonly id: string) {}

  static copy(other: AggregateId): AggregateId {
    return new AggregateId(other.id);
  }

  static unique(ids: Array<AggregateId>): Array<AggregateId> {
    const uIds: Array<AggregateId> = [];

    ids.forEach(id => {
      if(uIds.filter(uId => uId.id === id.id).length === 0) {
        uIds.push(id);
      }
    });

    return uIds;
  }

}


export class RemoteOrganizationIdentifier {
  private readonly _RemoteOrganizationIdentifier: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {}
  static copy(other: RemoteOrganizationIdentifier) {
    return new RemoteOrganizationIdentifier(other.id);
  }
}

class RemoteOrganizationId {
  private readonly _RemoteOrganizationId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: string) {}

  static copy(other: RemoteOrganizationId): RemoteOrganizationId {
    return new RemoteOrganizationId(other.id);
  }

  toAggregateId() {
    return new AggregateId(this.id);
  }
}

export class AnyFlowIdHelper {
  static equals(a: AnyFlowId, b: AnyFlowId): boolean {
    if(a instanceof FlowId && b instanceof FlowId) {
      return a.id === b.id;
    } else if(a instanceof RemoteFlowId && b instanceof RemoteFlowId) {
      return a.id === b.id && a.remoteOrganization === b.remoteOrganization;
    } else {
      return false;
    }
  }

  static isMaterialized(id: AnyFlowId) {
    return id.id.indexOf("_") === -1;
  }

  static parseUrl(serialized: string): AnyFlowId {
    if(serialized.indexOf("r|") == 0) {
      const splitted = serialized.split("|");
      return new RemoteFlowId(splitted[2], splitted[1]);
    } else {
      return new FlowId(serialized);
    }
  }

}

class AnyInstanceHelper {

  static equals(a: AnyInstanceId, b: AnyInstanceId): boolean {
    if(a instanceof InstanceId && b instanceof InstanceId) {
      return a.id === b.id;
    } else if(a instanceof RemoteInstanceId && b instanceof RemoteInstanceId) {
      return a.id === b.id && a.remoteOrganization === b.remoteOrganization;
    } else {
      return false;
    }
  }
}

export interface AnyInstanceId {
  className(): string;
  id: string;
}


export class AnyInstanceIdFactory {
  static copyTyped(id: Typed<AnyInstanceId>): Typed<AnyInstanceId> {
    switch(Typed.className(id)) {
      case InstanceId.className: return Typed.of(InstanceId.of(<InstanceId>Typed.value(id)));
      case RemoteInstanceId.className: return Typed.of(RemoteInstanceId.copy(<RemoteInstanceId>Typed.value(id)));
      default: throw new Error("Unsupported type ["+Typed.className(id)+"]");
    }
  }

  static copy(id: AnyInstanceId): AnyInstanceId {
    switch(id.className()) {
      case InstanceId.className: return InstanceId.of(<InstanceId>id);
      case RemoteInstanceId.className: return RemoteInstanceId.copy(<RemoteInstanceId>id);
      default: throw new Error("Unsupported type ["+id.className()+"]");
    }

  }
}

export class InstanceId implements AnyInstanceId {
  static className = "InstanceId";
  private readonly _InstanceId: undefined; // force TypeScript to check types (workaround for duck typing)

  private static cache: Map<string, InstanceId> = new Map<string, InstanceId>();

  className(): string {
    return InstanceId.className;
  }

  constructor(readonly id: string) {}

  static of(id: string|AggregateId|InstanceId) {
    const idString = typeof id === "string" ? id : (id instanceof AggregateId ? id.id : id.id);

    const fromCache = InstanceId.cache.get(idString);
    if (fromCache) {
      return fromCache;
    } else {
      const id = new InstanceId(idString);
      InstanceId.cache.set(idString, id);
      return id;
    }
  }

  static copy(other: InstanceId): InstanceId {
    return InstanceId.of(other.id);
  }
}

export class RemoteInstanceId implements AnyInstanceId {
  static className = "RemoteInstanceId";

  className(): string {
    return RemoteInstanceId.className;
  }

  constructor(readonly id: string, readonly remoteOrganization: string) {}

  static copy(other: RemoteInstanceId): RemoteInstanceId {
    return new RemoteInstanceId(other.id, other.remoteOrganization);
  }

  equals(other: RemoteInstanceId) {
    return this.remoteOrganization === other.remoteOrganization && this.id === other.id;
  }
}

export interface AnyFlowId {
  className(): string;
  id: string;
  toFlowId(): FlowId;
  urlSerialized(): string;
  cssClassSerialized(): string;

  isRemote(): boolean;
}

export class AnyFlowIdFactory {
  static copyTyped(id: Typed<AnyFlowId>): Typed<AnyFlowId> {
    switch(Typed.className(id)) {
      case FlowId.className: return Typed.of(FlowId.copy(<FlowId>Typed.value(id)));
      case RemoteFlowId.className: return Typed.of(RemoteFlowId.copy(<RemoteFlowId>Typed.value(id)));
      default: throw new Error("Unsupported type ["+Typed.className(id)+"]");
    }
  }

  static copy(id: AnyFlowId): AnyFlowId {
    switch(id.className()) {
      case FlowId.className: return FlowId.copy(<FlowId>id);
      case RemoteFlowId.className: return RemoteFlowId.copy(<RemoteFlowId>id);
      default: throw new Error("Unsupported type ["+id.className()+"]");
    }

  }
}

export class RemoteFlowId implements AnyFlowId {
  static className = "RemoteFlowId";
  className(): string {
    return RemoteFlowId.className;
  }

  constructor(readonly id: string,
              readonly remoteOrganization: string) {}

  static copy(other: RemoteFlowId): RemoteFlowId {
    return new RemoteFlowId(other.id, other.remoteOrganization);
  }

  toFlowId(): FlowId {
    throw new Error("Not a local flow id");
  }

  urlSerialized(): string {
    return this.id+"@"+this.remoteOrganization;
  }

  cssClassSerialized(): string {
    return "r_"+this.remoteOrganization+"_"+this.id;
  }

  isRemote(): boolean {
    return true;
  }
}

export class FlowId implements AnyFlowId {
  static className = "FlowId";
  private readonly FlowId: undefined; // force TypeScript to check types (workaround for duck typing)

  className(): string {
    return FlowId.className;
  }
  constructor(readonly id: string) {}

  static copy(other: FlowId): FlowId {
    return new FlowId(other.id);
  }

  static isMaterialized(id: FlowId) {
    return id.id.indexOf("_") === -1;
  }

  static of(flowId: AggregateId) {
    return new FlowId(flowId.id);
  }

  toFlowId(): FlowId {
    return this;
  }

  toAggregateId(): AggregateId {
    return new AggregateId(this.id);
  }

  urlSerialized(): string {
    return this.id;
  }

  cssClassSerialized(): string {
    return this.id;
  }

  isRemote(): boolean {
    return false;
  }

  static parseFromUri(text: string): AnyFlowId {
    if(text.indexOf("_") >= 0) {
      const splitted = text.split("_")
      return new RemoteFlowId(splitted[0], splitted[1]);
    } else {
      return new FlowId(text);
    }
  }
}



export class AggregateVersion {
  constructor(readonly asInt: number) {}
  static ZERO = new AggregateVersion(0);
  static ONE = new AggregateVersion(1);

  static copy(other: AggregateVersion): AggregateVersion {
    return new AggregateVersion(other.asInt);
  }
}

export class AggregateIdWithVersion {
  constructor(readonly id: AggregateId, readonly version: AggregateVersion) {}

  static copy(other: AggregateIdWithVersion) {
    return new AggregateIdWithVersion(other.id, other.version);
  }
}

export class CommandId {
  constructor(readonly asLong: number) {}

  static copy(other: CommandId): CommandId {
    return new CommandId(other.asLong);
  }
}


export class AggregateType {
  constructor(readonly typeName: string) {}
  static copy(other: AggregateType): AggregateType {
    return new AggregateType(other.typeName);
  }
}

