import {Component, OnInit} from "@angular/core";
import {MainMenuPage, MainMenuService} from "./main-menu.service";
import {
  LocalStorageService,
  NavigationService,
  SessionServiceProvider
} from "@shared";
import {MenuEventBus} from "./menu.event-bus";
import {
  Actions,
  ApplicationIcon,
  ApplicationIcons,
  ApplicationsSharedService,
  AuthorizationSharedService,
  PersonAvatarInfo
} from "@shared-model";
import {__, ApplicationId, FileUri, gravatarHash, Option} from "@utils";
import {Subject} from "rxjs";
import {DocumentRepositoryQueryService} from "../../modules/documents.module/services/DocumentRepositoryQueryService";

export class PinnedApplicationViewModel {
  applicationRouterLink: string;
  active: boolean = false;
  readonly applicationIcon: ApplicationIcon;
  constructor(readonly id: ApplicationId,
              readonly identifier: string,
              readonly name: string,
              readonly iconCode: string,
              readonly colorId: number
  ) {
    this.applicationIcon = ApplicationIcons.getApplicationIcon(id, iconCode, colorId);
    if(identifier.length > 0) {
      this.applicationRouterLink = `/application/${identifier}`;
    } else {
      this.applicationRouterLink = `/application/@${id.id}`;
    }
  }
}

export class UserAvatar implements PersonAvatarInfo{
  constructor(readonly firstName: string, readonly lastName: string, readonly email: string, readonly avatar: Option<FileUri>) {}

  initials() {
    return this.firstName.charAt(0) + this.lastName.charAt(0);
  }

  gravatarHash(): string {
    return gravatarHash(this.email);
  }
}

@Component({
  selector: 'my-main-menu',
  templateUrl: './main-menu.component.html'
})
export class MainMenuComponent implements OnInit {

  mainDashboardActive: boolean = false;
  mainTasksAndProcessesActive: boolean = false;
  applicationsActive: boolean = false;
  documentsActive: boolean = false;
  designerActive: boolean = false;
  userSettingsActive: boolean = false;
  adminActive: boolean = false;
  helpActive: boolean = false;

  expanded = false;
  visible = false;

  userAvatar: UserAvatar = null!;

  applicationsMenuVisible = false;
  applicationsMenuContentAvailableNotifier = new Subject<boolean>();

  applications: Array<PinnedApplicationViewModel> = [];

  applicationContextMenuApp: PinnedApplicationViewModel | undefined = undefined;
  applicationContextMenuVisible: boolean = false;
  applicationContextMenuPointAnchor: {x: number, y: number}|undefined = undefined;
  applicationContextMenuAnchor: HTMLElement | undefined = undefined;

  private navigationStateId?: string;

  impersonated: boolean = false;

  canAccessDesigner = false;
  canAccessOrganization = false;

  constructor(readonly sessionServiceProvider: SessionServiceProvider,
              readonly mainMenuService: MainMenuService,
              readonly navigationService: NavigationService,
              readonly menuEventBus: MenuEventBus,
              readonly applicationsSharedService: ApplicationsSharedService,
              readonly localStorageService: LocalStorageService,
              readonly authorizationSharedService: AuthorizationSharedService,
              readonly documentRepositoryQueryService: DocumentRepositoryQueryService,
              readonly localStorage: LocalStorageService) {
    mainMenuService.getPageObservable().forEach(page => {
      this.onPageChanged(page, this.mainMenuService.application);
    });

    mainMenuService.getApplicationObservable().forEach(app => {
      this.onPageChanged(this.mainMenuService.page, app)
    });

    applicationsSharedService.getPinnedObservable().forEach(apps => {
      const existing = __(this.applications);
      this.applications = apps.map(a => {
        return existing.find(app => a.id.id === app.id.id).getOrElseLazy(() => {
          return new PinnedApplicationViewModel(ApplicationId.of(a.id), a.identifier, a.name, a.iconCode, a.colorId);
        });
      });
    });

    this.onPageChanged(mainMenuService.page);

    this.menuEventBus.on(this.menuEventBus.mainMenuOpenRequested, () => this.show());
    this.menuEventBus.on(this.menuEventBus.mainMenuCloseRequested, () => this.hide());

    navigationService.anyNavigationObservable.subscribe(() => {
      this.hideNotificationsPanel();
      this.hide(true);
    });

    this.authorizationSharedService.hasRightsByOrganization([
      Actions.FeaturesAccess.ProcessesAccess,
      Actions.FeaturesAccess.OrganizationStructureAccess,
      Actions.FeaturesAccess.OrganizationSettingsAccess,
    ], (result: {[rightName: string]: boolean}) => {
      this.canAccessDesigner = result[Actions.FeaturesAccess.ProcessesAccess.name];
      this.canAccessOrganization = result[Actions.FeaturesAccess.OrganizationStructureAccess.name] || result[Actions.FeaturesAccess.OrganizationSettingsAccess.name];
    });
  }

  ngOnInit() {
    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      this.userAvatar = new UserAvatar(sessionInfo.userFirstName, sessionInfo.userLastName, sessionInfo.userEmail, sessionInfo.avatar);
      this.impersonated = sessionInfo.imposter.isDefined();
    });

    this.applicationsSharedService.loadPinnedApplications((apps) => {
      this.applications = apps.map(a => new PinnedApplicationViewModel(ApplicationId.of(a.id), a.identifier, a.name, a.iconCode, a.colorId));
    });

  }

  private onPageChanged(page: MainMenuPage, applicationIdentifier: string | undefined = undefined) {
    this.mainDashboardActive = page === MainMenuPage.dashboard;
    this.mainTasksAndProcessesActive = page === MainMenuPage.tasksAndFlows;
    this.documentsActive = page === MainMenuPage.documents;
    this.designerActive = page === MainMenuPage.designer;
    this.applicationsActive = page === MainMenuPage.applications;
    this.userSettingsActive = page === MainMenuPage.settings;
    this.adminActive = page === MainMenuPage.admin;
    this.helpActive = page === MainMenuPage.help;
    this.applications.forEach(a => {
      if(applicationIdentifier?.indexOf("@") === 0) {
        a.active = ("@"+a.id.id) == applicationIdentifier;
      } else {
        a.active = a.identifier == applicationIdentifier;
      }
    })

    if(__(this.applications).exists(a => a.active)) {
      this.applicationsActive = false;
    } else if(applicationIdentifier) {
      this.applicationsActive = true;
    }

  }

  goToDesigner() {

    this.localStorageService.getString("designerLastViewedUrl").then(designerLastViewedUrl => {
      if(designerLastViewedUrl) {
        const appPath = `/designer/${designerLastViewedUrl}`;
        if(this.navigationService.currentUrlNoHash() != appPath) {
          this.navigationService.navigateByUrl(`/designer/${designerLastViewedUrl}`);
        } else {
          this.localStorageService.removeItem("designerLastViewedUrl");
          this.navigationService.navigateByUrl("/designer");
        }
      } else {
        this.navigationService.navigateByUrl("/designer");
      }
    })


  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  show() {
    this.visible = true;

    this.navigationStateId = this.navigationService.pushTemporaryState(() => {
      this.hide();
    });
  }

  hide(doNotNavigate: boolean = false) {
    this.visible = false;
    this.hideNotificationsPanel();

    if(this.navigationStateId) {
      this.navigationService.removeTemporaryState(this.navigationStateId, doNotNavigate);
      this.navigationStateId = undefined;
    }
  }

  toggleApplicationsMenu() {
    this.applicationsMenuVisible = !this.applicationsMenuVisible;
  }

  onApplicationSelected() {
    this.applicationsMenuVisible = false;
  }

  toggleApplicationContextMenuPoint($event: MouseEvent, model: PinnedApplicationViewModel) {
    this.applicationContextMenuApp = model;
    this.applicationContextMenuAnchor = undefined;
    this.applicationContextMenuPointAnchor = {x: $event.clientX, y: $event.clientY};
    this.applicationContextMenuVisible = true;
  }

  onUnpinPinToMenu(application: PinnedApplicationViewModel) {
    this.applicationsSharedService.removePinnedApplication(application.id);
    this.applicationContextMenuVisible = false;
  }

  toggleNotificationsPanel() {
    this.mainMenuService.toggleNotificationsPanel();
  }

  hideNotificationsPanel() {
    this.mainMenuService.hideNotificationsPanel();
  }

  logout() {
    this.sessionServiceProvider.getSessionService(sessionService => sessionService.logout());
    this.navigationService.navigateToLoginPage();
  }
}
