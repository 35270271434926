import {Either, I18nText, LocalDateTime, Option, ScreenId} from "@utils";
import {ApplicationComponentRef, ScreenComponentRefId} from "@shared";


export class AddedSpace {
    constructor(public fromRem: number,
                public sizeRem: number) {}
  }


  export class DimensionUnit {
    constructor(readonly name: string) {}
    static percentage = new DimensionUnit("percentage");
    static grid = new DimensionUnit("grid");

    static byName(name: string) {
      switch(name) {
        case DimensionUnit.percentage.name: return DimensionUnit.percentage;
        case DimensionUnit.grid.name: return DimensionUnit.grid;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }

    static copy(other: DimensionUnit) {
      return DimensionUnit.byName(other.name);
    }
  }



  export class LabelPosition {
    constructor(readonly name: string) {}

    static top = new LabelPosition("top");
    static start = new LabelPosition("start");
    static bottom = new LabelPosition("bottom");
    static end = new LabelPosition("end");

    static byName(name: string) {
      switch (name) {
        case LabelPosition.top.name: return LabelPosition.top;
        case "left": return LabelPosition.start;
        case LabelPosition.start.name: return LabelPosition.start;
        case LabelPosition.bottom.name: return LabelPosition.bottom;
        case "right": return LabelPosition.end;
        case LabelPosition.end.name: return LabelPosition.end;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }

    isTop() {
      return this.name == LabelPosition.top.name;
    }

    isStart() {
      return this.name == LabelPosition.start.name;
    }

    isBottom() {
      return this.name == LabelPosition.bottom.name;
    }

    isEnd() {
      return this.name == LabelPosition.end.name;
    }

  }

  export class TextAlign {
    constructor(readonly name: string) {}

    static start = new TextAlign("start");
    static center = new TextAlign("center");
    static end = new TextAlign("end");
    static justify = new TextAlign("justify");
    static DEFAULT = TextAlign.start;

    static byName(name: string) {
      switch (name) {
        case "left": return TextAlign.start;
        case TextAlign.start.name: return TextAlign.start;
        case TextAlign.center.name: return TextAlign.center;
        case "right": return TextAlign.end;
        case TextAlign.end.name: return TextAlign.end;
        case TextAlign.justify.name: return TextAlign.justify;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }
    static copy(other: TextAlign) {
      return TextAlign.byName(other.name);
    }

    isStart() {
      return this.name === TextAlign.start.name || this.name == "left";
    }
    isCenter() {
      return this.name === TextAlign.center.name;
    }
    isEnd() {
      return this.name === TextAlign.end.name || this.name == "right";
    }
    isJustify() {
      return this.name === TextAlign.justify.name;
    }
  }

  export class TextVerticalAlign {
    constructor(readonly name: string) {}

    static top = new TextVerticalAlign("top");
    static center = new TextVerticalAlign("center");
    static bottom = new TextVerticalAlign("bottom");
    static DEFAULT = TextVerticalAlign.top;

    static byName(name: string) {
      switch (name) {
        case TextVerticalAlign.top.name: return TextVerticalAlign.top;
        case TextVerticalAlign.center.name: return TextVerticalAlign.center;
        case TextVerticalAlign.bottom.name: return TextVerticalAlign.bottom;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }
    static copy(other: TextVerticalAlign) {
      return TextVerticalAlign.byName(other.name);
    }

    isTop() {
      return this.name === TextVerticalAlign.top.name;
    }
    isCenter() {
      return this.name === TextVerticalAlign.center.name;
    }
    isBottom() {
      return this.name === TextVerticalAlign.bottom.name;
    }
  }


  export class TextFont {
    constructor(readonly name: string) {}

    static defaultFont = new TextFont("defaultFont");
    /**
     * @deprecated Use `defaultFont` instead.
     */
    static lato = new TextFont("lato");
    static times = new TextFont("times");
    static georgia = new TextFont("georgia");
    static courier = new TextFont("courier");
    static cursive = new TextFont("cursive");

    static DEFAULT = TextFont.defaultFont;

    static byName(name: string) {
      switch (name) {
        case TextFont.defaultFont.name: return TextFont.defaultFont;
        case TextFont.lato.name: return TextFont.lato;
        case TextFont.times.name: return TextFont.times;
        case TextFont.georgia.name: return TextFont.georgia;
        case TextFont.courier.name: return TextFont.courier;
        case TextFont.cursive.name: return TextFont.cursive;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }
    static copy(other: TextFont) {
      return TextFont.byName(other.name);
    }


    static getFontCss(fontText: string): string {
      switch (fontText) {
        case TextFont.defaultFont.name: return "sofia-pro, sans-serif"
        case TextFont.lato.name: return "sofia-pro, sans-serif"
        case TextFont.times.name: return "'Times New Roman', serif";
        case TextFont.georgia.name: return "Georgia, serif";
        case TextFont.courier.name: return "'Courier New', monospace";
        case TextFont.cursive.name: return "'Brush Script MT', cursive";
        default: throw new Error("Unsupported font [" + fontText+"]");
      }
    }

  }


  export class LayoutType {
    constructor(readonly name: string) {}

    static static = new LayoutType("static");
    static vertical = new LayoutType("vertical");
    static horizontal = new LayoutType("horizontal");
    static grid = new LayoutType("grid");

    static byName(name: string) {
      switch (name) {
        case LayoutType.static.name: return LayoutType.static;
        case LayoutType.vertical.name: return LayoutType.vertical;
        case LayoutType.horizontal.name: return LayoutType.horizontal;
        case LayoutType.grid.name: return LayoutType.grid;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }
    static copy(other: LayoutType) {
      return LayoutType.byName(other.name);
    }

    isStatic() {
      return this.name === LayoutType.static.name;
    }
    isVertical() {
      return this.name === LayoutType.vertical.name;
    }
    isHorizontal() {
      return this.name === LayoutType.horizontal.name;
    }
    isGrid() {
      return this.name === LayoutType.grid.name;
    }
  }



  export class LayoutAlign {
    constructor(readonly name: string) {}

    static start = new LayoutAlign("start");
    static center = new LayoutAlign("center");
    static stretch = new LayoutAlign("stretch");
    static end = new LayoutAlign("end");

    static DEFAULT = LayoutAlign.start;

    static byName(name: string) {
      switch (name) {
        case LayoutAlign.start.name: return LayoutAlign.start;
        case LayoutAlign.center.name: return LayoutAlign.center;
        case LayoutAlign.stretch.name: return LayoutAlign.stretch;
        case LayoutAlign.end.name: return LayoutAlign.end;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }
    static copy(other: LayoutAlign) {
      return LayoutAlign.byName(other.name);
    }

    isStart() {
      return this.name === LayoutAlign.start.name;
    }
    isStretch() {
      return this.name === LayoutAlign.stretch.name;
    }
    isCenter() {
      return this.name === LayoutAlign.center.name;
    }
    isEnd() {
      return this.name === LayoutAlign.end.name;
    }
  }


  export class LayoutWrap {
    constructor(readonly name: string) {}

    static wrap = new LayoutWrap("wrap");
    static noWrap = new LayoutWrap("noWrap");

    static DEFAULT = LayoutWrap.wrap;

    static byName(name: string) {
      switch (name) {
        case LayoutWrap.wrap.name: return LayoutWrap.wrap;
        case LayoutWrap.noWrap.name: return LayoutWrap.noWrap;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }
    static copy(other: LayoutWrap) {
      return LayoutWrap.byName(other.name);
    }

    isWrap() {
      return this.name === LayoutWrap.wrap.name;
    }
    isNoWrap() {
      return this.name === LayoutWrap.noWrap.name;
    }
  }


  export class LayoutStretch {
    constructor(readonly name: string) {}

    static start = new LayoutStretch("start");
    static stretch = new LayoutStretch("stretch");
    static center = new LayoutStretch("center");
    static end = new LayoutStretch("end");

    static DEFAULT = LayoutStretch.stretch;

    static byName(name: string) {
      switch (name) {
        case LayoutStretch.start.name: return LayoutStretch.start;
        case LayoutStretch.stretch.name: return LayoutStretch.stretch;
        case LayoutStretch.center.name: return LayoutStretch.center;
        case LayoutStretch.end.name: return LayoutStretch.end;
        default: throw new Error("Unsupported value ["+name+"]")
      }
    }
    static copy(other: LayoutStretch) {
      return LayoutStretch.byName(other.name);
    }

    isStart() {
      return this.name === LayoutStretch.start.name;
    }
    isStretch() {
      return this.name === LayoutStretch.stretch.name;
    }
    isCenter() {
      return this.name === LayoutStretch.center.name;
    }
    isEnd() {
      return this.name === LayoutStretch.end.name;
    }
  }


export class ValidationRuleRef {
  constructor(
    public id: ValidationRuleRefId,
    readonly applicationComponent: Option<ApplicationComponentRef>,
    public ruleId: Either<ValidationRuleId, string>,
    readonly stopOnError: boolean
  ) {}

  static copy(other: ValidationRuleRef) {
    return new ValidationRuleRef(ValidationRuleRefId.copy(other.id),
      Option.copy(other.applicationComponent, ApplicationComponentRef.copy),
      Either.copy(other.ruleId, ValidationRuleId.copy, s => s),
      other.stopOnError);
  }
}

export class ValidationMessageType {
  constructor(readonly name: string) {}

  static text = new ValidationMessageType("text");
  static expression = new ValidationMessageType("expression");

  static copy(other: ValidationMessageType) {
    switch (other.name) {
      case ValidationMessageType.text.name: return ValidationMessageType.text;
      case ValidationMessageType.expression.name: return ValidationMessageType.expression;
      default: throw new Error("Unsupported message type '"+other.name+"'");
    }
  }

  isExpression() {
    return this.name == "expression";
  }

  isText() {
    return this.name == "text";
  }
}

export class ValidationRuleId {
  private readonly _ValidationRuleId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {}
  static copy(other: ValidationRuleId) {
    return new ValidationRuleId(other.id);
  }
}

export class ValidationRuleRefId {
  private readonly _ValidationRuleRefId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {}
  static copy(other: ValidationRuleRefId) {
    return new ValidationRuleRefId(other.id);
  }
}

export class ValidationRule {
  constructor(public id: ValidationRuleId,
              readonly name: Option<string>,
              readonly expression: string,
              readonly messageType: ValidationMessageType,
              readonly message: I18nText,
              readonly messageExpression: string) {}

  static copy(other: ValidationRule) {
    return new ValidationRule(ValidationRuleId.copy(other.id), Option.copy(other.name), other.expression, ValidationMessageType.copy(other.messageType), I18nText.copy(other.message), other.messageExpression);
  }
}

export class ComponentPosition {
  constructor(readonly x: number,
              readonly y: number,
              readonly width: number,
              readonly height: number) {}

  static of(x: number, y: number, width: number, height: number) {
    return new ComponentPosition(x, y, width, height);
  }

  static zero() {
    return new ComponentPosition(0, 0, 10, 2);
  }

  static copy(other: ComponentPosition) {
    return new ComponentPosition(other.x, other.y, other.width, other.height);
  }
}

export class EntriesLayoutType {
  constructor(readonly name: string) {}

  static vertical = new EntriesLayoutType("vertical");
  static horizontal = new EntriesLayoutType("horizontal");

  static byName(name: string) {
    switch (name) {
      case EntriesLayoutType.vertical.name: return EntriesLayoutType.vertical;
      case EntriesLayoutType.horizontal.name: return EntriesLayoutType.horizontal;
      default: throw new Error("Unsupported value ["+name+"]")
    }
  }

  isVertical() {
    return this.name == EntriesLayoutType.vertical.name;
  }

  isHorizontal() {
    return this.name == EntriesLayoutType.horizontal.name;
  }

  static copy(other: EntriesLayoutType) {
    return EntriesLayoutType.byName(other.name);
  }
}



export class ScreenComponentId {
  private readonly _ScreenComponentId: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly id: number) {}

  static of(id: number) {
    return new ScreenComponentId(id);
  }

  static copy(other: ScreenComponentId) {
    return new ScreenComponentId(other.id);
  }
}




export class ScreenComponentIdInScreen {
  private readonly _ScreenComponentIdInScreen: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly screenId: string,
              readonly id: number) {}

  static copy(other: ScreenComponentIdInScreen) {
    return new ScreenComponentIdInScreen(other.screenId, other.id);
  }

  asMapKey() {
    return this.screenId+"|"+this.id;
  }

  toScreenId() {
    return new ScreenId(this.screenId);
  }

  toComponentId() {
    return new ScreenComponentId(this.id);
  }

  equals(other: ScreenComponentIdInScreen) {
    return this.id === other.id && this.screenId === other.screenId;
  }

  static ofIds(parentScreenId: ScreenId, id: ScreenComponentId) {
    return new ScreenComponentIdInScreen(parentScreenId.id, id.id);
  }
}


export class ScreenComponentRefIdInScreen {
  private readonly _ScreenComponentRefIdInScreen: undefined; // force TypeScript to check types (workaround for duck typing)
  constructor(readonly screenId: string,
              readonly id: number) {}

  static copy(other: ScreenComponentRefIdInScreen) {
    return new ScreenComponentRefIdInScreen(other.screenId, other.id);
  }

  asMapKey() {
    return this.screenId+"|"+this.id;
  }

  toScreenId() {
    return new ScreenId(this.screenId);
  }

  toComponentRefId() {
    return new ScreenComponentRefId(this.id);
  }

  static ofIds(parentScreenId: ScreenId, refId: ScreenComponentRefId) {
    return new ScreenComponentRefIdInScreen(parentScreenId.id, refId.id);
  }

  static of(parentScreenId: string, refId: ScreenComponentRefId) {
    return new ScreenComponentRefIdInScreen(parentScreenId, refId.id);
  }

  equals(other: ScreenComponentRefIdInScreen) {
    return other.id === this.id && other.screenId === this.screenId;
  }
}




