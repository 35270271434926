<my-modal [(visible)]="viewModel.popped" cssClass="modalContainerModal screenInstance screen"
          [backgroundVisible]="true" [closeOnBackgroundClick]="false" (closedByUser)="viewModel.onClosed()">

  <div class="sectionHeader {{viewModel.headerCssClasses}}" *ngIf="viewModel.headerVisible" [style]="viewModel.headerCss">{{viewModel.header}}
  </div>
  <button *ngIf="viewModel.closeVisible" class="closeButton theme-button-close" (click)="viewModel.close()"><i class="mi-close"></i></button>
  <my-scroll [adjustHeight]="true" [contentSizeChanged]="viewModel.contentChanged" [adjustWidth]="true" [horizontal]="true">
    <div
      [class]="'childrenPlain ' + viewModel.childrenPlainCssClasses + ' layout_' + viewModel.layoutClass + ' layoutAlign_' + viewModel.layoutAlignClass + ' layoutStretch_' + viewModel.layoutStretchClass + ' layoutWrap_' + viewModel.layoutWrapClass"
      [style]="viewModel.childrenPlainCss">
      <ng-container *ngFor="let component of viewModel.children">
        <div *ngIf="component.uncoveredAndVisible" class="ScreenComponent ScreenComponent{{component.id}} layout_{{component.layoutClass}} {{component.errorClass}}"
             [style]="component.positionCss"
             [class.nonVisibleComponent]="component.nonVisibleComponent">
          <div class="componentBodyContainer labelPosition_{{component.label?.positionClass}}">
            <!--              SHARED -->
            <component-label *ngIf="component.label && component.label.visible" [viewModel]="component.label" />

            <ng-container *ngComponentOutlet="children.componentOf(component);inputs:children.inputsOf(component)" />

            <div class="errorsInfo" *ngIf="component.error">
              <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: component.errorText, position: 'top'}"></i>
            </div>

            <div class="inProgressGlass" *ngIf="component.inProgress"></div>
            <!--              SHARED -->
          </div>
        </div>
      </ng-container>
    </div>
  </my-scroll>
  <div *ngIf="viewModel.actionButtonsVisible" class="sectionActions">
    <button *ngIf="viewModel.declineVisible" class="theme-button-cancel" (click)="viewModel.close()">{{viewModel.declineLabel}}</button>
    <button *ngIf="viewModel.acceptVisible" class="theme-button-confirm-with-icon" [class.theme-disabled]="viewModel.disabled" (click)="viewModel.confirm()"><i class="mi-check"></i>{{viewModel.acceptLabel}}</button>
  </div>
</my-modal>
