import {
  DirectoryWithContent,
  DocumentTypeInfo,
  FileVersionClientInfo,
  NodeWithAuthorization,
  ViewableFile
} from "@shared"
import {
  AggregateId,
  DirectoryId,
  EventBus,
  FileId,
  FileUri,
  Option,
  OrganizationNodeId,
  RepositoryNodeId
} from "@utils";
import {ProcessedDocument} from "./OcrModel";
import {BasicOrganizationNodeInfo, FileVersionSummary, PathSize} from "@shared-model";
import {ListEntryViewModel} from "./components/directory-content.view-model";

export class DocumentsRepositoryEventBus extends EventBus {

    directoryCreationFailed(tempId: DirectoryId) {}

    directoryRenamed(directoryId: DirectoryId, name: string) {}

    directoryNavigationRequested(uri: FileUri) {}

    deletedDirectoryNavigationRequested(uri: FileUri) {}

    deletedFileNavigationRequested(uri: FileUri) {}

    fileNavigationRequested(locationPath: Option<string>, uri: FileUri) {}

    fileClosed(parentPath: string) {}

    directoryLoadedCompleted() {}

    fileFound(founded: boolean) {}

      selectedNodesChanged(ids: Array<RepositoryNodeId>, deletedIds: Array<RepositoryNodeId>) {}

    onNodeUnselected() {}

    directoryWithContentLoaded(directoryWithContent: DirectoryWithContent, nodes: Array<BasicOrganizationNodeInfo>, showDeleted: boolean) {}

    documentTypesLoaded(documentTypes: Array<DocumentTypeInfo>) {}

    fileVersionUploaded(uploadId: number, aggregateId: AggregateId, fileVersion: FileVersionClientInfo) {}

    physicalFileDeleted(versions: Array<FileVersionSummary>, fileId: FileId) {}

    physicalFileDeleteFailed(fileId: FileId, versions: Array<FileVersionSummary>) {}

    documentDeletePermanentlyFailed(fileId: FileId) {}

    fileVersionSummaryLoadedForPreview(file: FileVersionSummary, fileName: string, fileLink: string, fileUri: FileUri, currentVersion: boolean) {}

    directorySizeLoaded(pathSize: PathSize) {}

    nodePropertiesLoaded(uri: FileUri, file: NodeWithAuthorization, nodes: Array<BasicOrganizationNodeInfo>) {}

    fileUploadStarted(uploadId: number, fileName: string, directoryId: DirectoryId) {}

    fileVersionUploadStarted(uploadId: number, fileId: FileId) {}
    fileVersionUploadFailed(fileId: FileId) {}

    fileUploadProgress(uploadId: number, uploadedBytes: number, totalBytes: number) {}

    fileUploaded(uploadId: number, node: NodeWithAuthorization) {}

    fileRenamed(fileId: FileId, name: string) {}

    fileLockedByCurrentUser(fileId: FileId) {}

    fileUnlocked(fileId: FileId) {}

    fileWatchingChanged(fileId: FileId, watching: boolean) {}

    fileStarChanged(fileId: FileId, starred: boolean) {}

    directoryStarChanged(id: DirectoryId, starred: boolean) {}

    fileDeletionFailed(fileId: FileId) {}

    fileRecoveryFailed(fileId: FileId) {}

    directoryDeletionFailed(directoryId: DirectoryId) {}

    directoryRecoveryFailed(directoryId: DirectoryId) {}

    ocrStarted(fileUri: FileUri) {}

    ocrPerformed(fileUri: FileUri, ocrDocument: Option<ProcessedDocument>) {}

    ocrFailed(fileUri: FileUri, error: string) {}

    fileSaveRequested(fileUri: FileUri, fileId: FileId, content: string) {}

    rootAuthorizationsLoaded(read: Array<OrganizationNodeId>, write: Array<OrganizationNodeId>, administration: Array<OrganizationNodeId>) {}

    fileLoadedForPreview(viewableFile: ViewableFile) {}

    entriesInClipboardChanged(ids: Array<RepositoryNodeId>) {}

    nodeDeleted(id: RepositoryNodeId, permanently: boolean) {}

    nodesMovedToDirectory(entriesClipboard: Array<RepositoryNodeId>, destinationDirectoryId: DirectoryId) {}

    nodeRestored(id: RepositoryNodeId) {}

    entryAdded(parentId: DirectoryId, entry: ListEntryViewModel) {}

    entryDeleted(id: RepositoryNodeId) {}

    entryIdChanged(oldId: AggregateId, newId: AggregateId) {}

    fileOpened(file: FileId) {}

    newFileCreated(fileUri: FileUri) {}
  }
