import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  ArrayVariableType,
  BooleanVariableType,
  BusinessVariable,
  BusinessVariableType, CaseVariableType,
  DateTimeVariableType,
  DateVariableType,
  DepartmentVariableType,
  EmailVariableType,
  FileVariableType,
  GroupVariableType,
  I18nTextVariableType,
  NumberVariableType,
  OrganizationNodeVariableType,
  PersonVariableType,
  StringVariableType,
  TimeVariableType
} from "@shared-model";

export class ParameterViewModel {
  constructor(public id: number,
              public name: string,
              public valueType: BusinessVariableType,
              public requiredParam: boolean,
              public defaultValue: BusinessVariable | undefined) {}
}


@Component({
  templateUrl: './parameter.component.html',
  selector: 'my-parameter'
})
export class ParameterComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input({required: true}) viewModel!: ParameterViewModel;
  @Output() viewModelChange = new EventEmitter<ParameterViewModel>();
  @Input() focusOnShow: boolean = false;
  @Input() parameterType: "input" | "output" = "input";
  @Input() defaultable: boolean = false;

  input: boolean = false;
  output: boolean = false;

  valueTypes: Array<BusinessVariableType> = [
    StringVariableType.empty(),
    I18nTextVariableType.empty(),
    NumberVariableType.empty(),
    BooleanVariableType.empty(),
    DateVariableType.empty(),
    DateTimeVariableType.empty(),
    TimeVariableType.empty(),
    PersonVariableType.empty(),
    GroupVariableType.empty(),
    DepartmentVariableType.empty(),
    OrganizationNodeVariableType.empty(),
    FileVariableType.empty(),
    EmailVariableType.empty(),
    CaseVariableType.empty(),
    ArrayVariableType.of(StringVariableType.empty()),
    ArrayVariableType.of(I18nTextVariableType.empty()),
    ArrayVariableType.of(NumberVariableType.empty()),
    ArrayVariableType.of(PersonVariableType.empty()),
    ArrayVariableType.of(GroupVariableType.empty()),
    ArrayVariableType.of(DepartmentVariableType.empty()),
    ArrayVariableType.of(OrganizationNodeVariableType.empty()),
    ArrayVariableType.of(FileVariableType.empty()),
    ArrayVariableType.of(EmailVariableType.empty()),
    ArrayVariableType.of(CaseVariableType.empty()),
  ];

  //
  // new AvailableFieldType(i18n("business_model_variable_Person"), PersonVariableType.empty()),
  // new AvailableFieldType(i18n("business_model_variable_Department"), DepartmentVariableType.empty()),
  // new AvailableFieldType(i18n("business_model_variable_Group"), GroupVariableType.empty()),
  // new AvailableFieldType(i18n("business_model_variable_OrganizationNode"), OrganizationNodeVariableType.empty()),
  //
  constructor() {
  }

  ngOnInit(): void {
    this.input = this.parameterType === "input";
    this.output = this.parameterType === "output";
  }

  onChange() {
    this.viewModelChange.emit(this.viewModel);
  }

}

