import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from "@angular/core";
import {convertLinksToTags} from "@utils";

@Component({
  selector: "adjusting-text-input[ngDefaultControl]",
  template: "",
  host: {
    "[attr.placeholder]": "placeholder",
    "[class.large]": "large",
    "(keydown.enter)": "onEnterPressed()",
    "(keydown.escape)": "onEscape.emit()",
    "(keydown)": "onKeyDown.emit()",
    "[class.disabled]": "_disabled"
  }
})
export class AdjustingTextInput implements OnInit {

  _disabled: boolean = false;

  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.initContentEditable();
  }


  @Input() large: boolean = false;

  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Output() focus: EventEmitter<void> = new EventEmitter<void>();

  @Input() set model(m: string|null) {
    this.innerValue = m;
    const nativeElement = this.getNativeElement();
    if(this._disabled) {
      // for non editable text we can activate links
      nativeElement.innerText = m ? m : "";
      nativeElement.innerHTML = convertLinksToTags(nativeElement.innerHTML);
    } else {
      nativeElement.innerText = m ? m : "";
    }

  }

  @Output() modelChange = new EventEmitter<string>();
  @Output() immediateChange = new EventEmitter<void>();

  @Output() accepted = new EventEmitter<void>();

  @Input() newLineOnEnter: boolean = true;

  private innerValue: string|null = "";
  @Input() placeholder?: string;

  constructor(private readonly viewContainerRef: ViewContainerRef) {
    const element = this.getNativeElement();
    element.addEventListener("blur", this.onBlur);
    element.addEventListener("paste", this.onPaste);
    element.addEventListener("focus", this.onFocus);
    element.addEventListener("input", this.onInput);
  }

  ngOnInit(): void {
    this.initContentEditable();
  }

  private getNativeElement() {
    return <HTMLElement>this.viewContainerRef.element.nativeElement;
  }

  private onFocus = () => {
    this.focus.emit();
  };

  private onPaste = (event: ClipboardEvent) => {

    if(!this._disabled) {
      event.preventDefault();
      let paste = (event.clipboardData || (<any>window).clipboardData).getData("text/plain");

      const selection = window.getSelection();
      if (selection !== null) {
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        let node = document.createTextNode(paste);
        selection.getRangeAt(0).insertNode(node);
        selection.setPosition(node, paste.length);
      }
      this.immediateChange.emit();
    }
  };

  private onBlur = () => {
    this.innerValue = this.getNativeElement().innerText;
    if (this.innerValue !== null) {
      this.modelChange.emit(this.innerValue);
    } else {
      this.modelChange.emit("");
    }
    this.blur.emit();
    this.getNativeElement().blur();
  };


  private onInput = () => {
    this.immediateChange.emit();
  };

  @Output() onEnter = new EventEmitter<void>();
  @Output() onEscape = new EventEmitter<void>();
  @Output() onKeyDown = new EventEmitter<void>();

  private initContentEditable() {
    if (this._disabled) {
      this.viewContainerRef.element.nativeElement.contentEditable = false;
    } else {
      try {
        this.viewContainerRef.element.nativeElement.contentEditable = "plaintext-only"; // we want text to be plain text, so it's behave better in case on line breaks in Chromium
      } catch (e) {
        this.viewContainerRef.element.nativeElement.contentEditable = true;
      }
    }
  }

  onEnterPressed() {
    if (!this.newLineOnEnter) {
      this.onBlur();
      this.onEnter.emit()
    }
  }
}
