const lastLogs: Array<{msg: string, params: Array<any>}> = [];

function debugEnabled() {
  return localStorage.getItem("debugEnabled") === "true";
}

export function debug(message: string, ...params: any[]) {
  if(debugEnabled()) {
    console.log(message, ...params);
  } else {
    lastLogs.push({msg: message, params: params});
    if(lastLogs.length > 100) {
      lastLogs.shift();
    }
  }
}

(<any>window).enableDebug = () => {
  localStorage.setItem("debugEnabled", "true");
  if(lastLogs.length > 0) {
    lastLogs.forEach(l => console.log(l.msg, ...l.params));
    lastLogs.length = 0;
  }
  console.log("Debug enabled");
}

(<any>window).disableDebug = () => {
  localStorage.removeItem("debugEnabled");
  console.log("Debug disabled");
}
