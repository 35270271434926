<button #SelectNode class="selectedNode" [class.smallIcon]="smallIcon" (click)="viewModel.toggleSearchBox()"
        [disabled]="viewModel.preview && viewModel.readOnly">

  <div class="noNode" *ngIf="!viewModel.node">{{placeholder}}</div>

  <div class="personNode organizationNode" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.person.isDefined()"
       [my-tooltip]="{text: viewModel.nodeInfo.person.get().email, position: 'top'}">
    <div class="nodeIcon">
      <my-avatar [person]="viewModel.nodeInfo.person.get()" [mediumIcon]="mediumIcon" [smallIcon]="smallIcon"/>
    </div>
    <div class="nodeDescription" [class.myGravatar]="viewModel.nodeInfo.person.get().avatar.isDefined()">
      {{viewModel.nodeInfo.person!.get().simpleName()}}
    </div>
  </div>

  <div class="departmentNode organizationNode" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.department.isDefined()">
    <div [class.theme-department-icon-medium]="!smallIcon" [class.theme-department-icon-small]="smallIcon"><i class="mi-home"></i></div>
    <div class="nodeDescription">
      {{viewModel.nodeInfo.department!.get().name}}
    </div>
  </div>

  <div class="groupNode organizationNode" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.group.isDefined()">
    <div [class.theme-group-icon-medium]="!smallIcon" [class.theme-group-icon-small]="smallIcon">
      <i class="mi-user-group"></i>
    </div>
    <div class="nodeDescription">
      {{viewModel.nodeInfo.group!.get().group.name}}
      <span class="applicationInfo" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.group.get().applicationId.isDefined()">{{viewModel.nodeInfo.group.get().applicationName}}</span>
    </div>
  </div>

  <div class="processNode organizationNode" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.process.isDefined()">
    <div [class.theme-process-icon]="!smallIcon" [class.theme-process-icon-small]="smallIcon">
      <i class="mi-right"></i>
    </div>
    <div class="nodeDescription">
      {{viewModel.nodeInfo.process!.get().process.name}}
      <span class="applicationInfo" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.process.get().applicationId.isDefined()">{{viewModel.nodeInfo.process.get().applicationName}}</span>
    </div>
  </div>

  <div class="reportNode organizationNode" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.report.isDefined()">
    <div [class.theme-report-icon]="!smallIcon" [class.theme-report-icon-small]="smallIcon">
      <i class="mi-barchart"></i>
    </div>
    <div class="nodeDescription">
      {{viewModel.nodeInfo.report!.get().report.name}}
      <span class="applicationInfo" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.report.get().applicationId.isDefined()">{{viewModel.nodeInfo.report.get().applicationName}}</span>
    </div>
  </div>

  <div class="applicationNode organizationNode" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.application.isDefined()">
    <div [class.theme-application-icon]="!smallIcon" [class.theme-application-icon-small]="smallIcon">
      <i class="mi-tablet"></i>
    </div>
    <div class="nodeDescription">
      {{viewModel.nodeInfo.application!.get().name}}
    </div>
  </div>

  <div class="screenResult organizationNode" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.screen.isDefined()">
    <div [class.theme-screen-icon]="!smallIcon" [class.theme-screen-icon-small]="smallIcon">
      <i class="mi-thumbnails"></i>
    </div>
    <div class="nodeDescription">
      {{viewModel.nodeInfo.screen!.get().screen.name}}
    </div>
  </div>

  <div class="functionsResult organizationNode" *ngIf="viewModel.nodeInfo !== null && viewModel.nodeInfo.functions.isDefined()">
    <div [class.theme-functions-icon]="!smallIcon" [class.theme-functions-icon-small]="smallIcon">
      <i class="mi-calculator"></i>
    </div>
    <div class="nodeDescription">
      {{viewModel.nodeInfo.functions!.get().functions.name}}
    </div>
  </div>

  <i class="expandArrow mi-navigate-down theme-expand-arrow-small" [class.theme-expanded]="viewModel.searchBoxVisible"></i>
</button>

<my-drop-menu *ngIf="!viewModel.readOnly" cssClass="organizationNodeSelectSearchBox" [(visible)]="viewModel.searchBoxVisible" [anchor]="SelectNode"
              [contentChangeNotifier]="contentChangeNotifier" [asModalOnMobile]="'top'">
  <my-organization-nodes-search-box *ngIf="viewModel.searchBoxVisible" [noneLabel]="placeholder" (resultsChanged)="contentChangeNotifier.emit($event)"
                                    (nodeSelected)="viewModel.onNodeSelected($event)" (nodeCleared)="viewModel.removeNode()"
                                    [applicationId]="viewModel.applicationId" [inAllApplications]="viewModel.inAllApplications" [fromGlobal]="viewModel.fromGlobal"
                                    [persons]="viewModel.persons" [includeDeletedNodes]="viewModel.includeDeletedNodes" [departments]="viewModel.departments" [groups]="viewModel.groups"
                                    [processes]="viewModel.processes" [reports]="viewModel.reports" [anonymous]="viewModel.anonymous"
                                    [applications]="viewModel.applications" [screens]="viewModel.screens" [functions]="viewModel.functions"
                                    [excludeNodes]="viewModel.excludeNodes" [unselectEnabled]="true" [withinNodes]="withinNodes"
                                    [alwaysShowResults]="true" />
</my-drop-menu>
