import {
  __, ___,
  AggregateVersion,
  FileProtocol,
  FileUri,
  i18n,
  I18nText,
  LocalDateTime, LocalTime,
  None,
  Option,
  OrganizationId,
  PersonId,
  ScreenId,
  ScreenReleaseId,
  Some,
  Typed
} from "@utils";
import {ApplicationComponentRef, ScreenComponentRefId, ScreenLifeMode} from "@shared";
import {
  AutomaticAction,
  AutomaticActionFactory,
  AutomaticActionRef,
  AutomaticActionRefWithSchedule
} from "./screen-actions-model";
import {
  AnyVariablePath,
  BusinessVariable,
  BusinessVariableFactory,
  BusinessVariableType,
  BusinessVariableTypeFactory,
  ScreenInfo,
  VariablePath
} from "@shared-model";
import {
  BooleanProperty,
  ComponentProperty,
  ComponentPropertyFactory,
  DateProperty,
  DateTimeProperty,
  DurationProperty,
  FileProperty,
  I18nTextProperty,
  LabeledValuesProperty,
  ModelProperty,
  NumberProperty,
  OptionalBooleanProperty,
  OptionalComponentProperty,
  OptionalDateProperty,
  OptionalDateTimeProperty,
  OptionalDurationProperty,
  OptionalFileProperty,
  OptionalI18nTextProperty,
  OptionalModelProperty,
  OptionalNumberProperty,
  OptionalOrganizationNodesProperty,
  OptionalStringArrayProperty,
  OptionalStringProperty,
  OptionalTimeProperty,
  PropertyType,
  StringProperty,
  TimeProperty,
  TrileanProperty
} from "./screen-properties.model";
import {
  LabelPosition,
  LayoutAlign,
  LayoutStretch,
  LayoutType,
  LayoutWrap,
  ScreenComponentId,
  TextAlign,
  TextFont,
  TextVerticalAlign,
  ValidationRule,
  ValidationRuleId,
  ValidationRuleRef
} from "./screen-simple.model";
import {
  ButtonComponentDefinition,
  ButtonComponentRef,
  CalendarComponentDefinition,
  CalendarComponentRef,
  DateInputComponentDefinition,
  DateInputComponentRef,
  DateTimeInputComponentDefinition,
  DateTimeInputComponentRef,
  DropListComponentDefinition,
  DropListComponentRef,
  DurationInputComponentDefinition,
  DurationInputComponentRef,
  HtmlComponentDefinition,
  HtmlComponentRef,
  ImageComponentDefinition,
  ImageComponentRef,
  LabelComponentDefinition,
  LabelComponentRef,
  LinkComponentDefinition,
  LinkComponentRef,
  MapComponentDefinition,
  MapComponentRef,
  ModalContainerDefinition,
  ModalContainerRef,
  MultiAttachmentInputComponentDefinition,
  MultiAttachmentInputComponentRef,
  MultiCheckboxComponentDefinition,
  MultiCheckboxComponentRef,
  NumberInputComponentDefinition,
  NumberInputComponentRef,
  PasswordInputComponentDefinition,
  PasswordInputComponentRef,
  PersonSelectComponentDefinition,
  PersonSelectComponentRef,
  RadioButtonComponentDefinition,
  RadioButtonComponentRef,
  RepeatableContainerDefinition,
  RepeatableContainerRef,
  SectionContainerDefinition,
  SectionContainerRef,
  SingleAttachmentInputComponentDefinition,
  SingleAttachmentInputComponentRef,
  SingleCheckboxComponentDefinition,
  SingleCheckboxComponentRef,
  SwitchComponentDefinition,
  SwitchComponentRef,
  TableContainerDefinition,
  TableContainerRef,
  TabsContainerDefinition,
  TabsContainerRef,
  TextInputComponentDefinition,
  TextInputComponentRef,
  TimeInputComponentDefinition,
  TimeInputComponentRef,
  ViewSwitcherContainerDefinition,
  ViewSwitcherContainerRef,
  WidgetComponentDefinition,
  WidgetComponentRef
} from "@screen-common";
import {SkinsPropertiesProvider} from "./screen-skins.model";

export class ScreenParam {
  constructor(readonly id: number,
              readonly variableName: string,
              readonly tpe: Typed<BusinessVariableType>,
              readonly defaultValue: Option<Typed<BusinessVariable>>,
              readonly documentation: I18nText,
              readonly input: boolean,
              readonly output: boolean,
              readonly required: boolean) {}

  static copy(other: ScreenParam) {
    return new ScreenParam(other.id,
      other.variableName,
      BusinessVariableTypeFactory.copyTyped(other.tpe),
      Option.copy(other.defaultValue, BusinessVariableFactory.copyTyped),
      I18nText.copy(other.documentation),
      other.input,
      other.output,
      other.required);
  }

  tpeUnwrapped() {
    return Typed.value(this.tpe);
  }

  defaultValueUnwrapped() {
    return this.defaultValue.map(Typed.value);
  }
}

export class ScreenReleaseWithName {
  constructor(readonly screenName: string,
              readonly release: ScreenRelease) {
  }
}

export class IdentifiableScreenComponentRefId {
  constructor(readonly identifier: string,
              readonly refId: ScreenComponentRefId) {}

  static copy(other: IdentifiableScreenComponentRefId) {
    return new IdentifiableScreenComponentRefId(other.identifier, ScreenComponentRefId.copy(other.refId));
  }
}

export class ScreenRelease {
  constructor(
    readonly organizationId: OrganizationId,
    readonly screenId: ScreenId,
    readonly releaseNumber: number,
    readonly releaseComment: string,
    readonly releaseCode: string,
    readonly closed: boolean,
    readonly description: I18nText,
    readonly components: ScreenComponents,
    readonly lastModified: LocalDateTime,
    readonly lastModifiedBy: PersonId,
    readonly authorizationType: ScreenAuthorizationType,
    readonly rootComponentsRefIds: Array<IdentifiableScreenComponentRefId>,
    readonly params: Array<ScreenParam>,
    readonly automaticVariables: Array<AutomaticVariable>,
    readonly onInit: Array<AutomaticActionRef>,
    readonly onInterval: Array<AutomaticActionRefWithSchedule>,
    readonly titleExpression: Option<string>,
    readonly lifeMode: ScreenLifeMode
  ) {}

  static copy(other: ScreenRelease) {
    return new ScreenRelease(
      OrganizationId.of(other.organizationId),
      ScreenId.copy(other.screenId),
      other.releaseNumber,
      other.releaseComment,
      other.releaseCode,
      other.closed,
      I18nText.copy(other.description),
      ScreenComponents.copy(other.components),
      LocalDateTime.copy(other.lastModified),
      PersonId.of(other.lastModifiedBy),
      ScreenAuthorizationType.copy(other.authorizationType),
      other.rootComponentsRefIds.map(IdentifiableScreenComponentRefId.copy),
      other.params.map(ScreenParam.copy),
      other.automaticVariables.map(AutomaticVariable.copy),
      other.onInit.map(AutomaticActionRef.copy),
      other.onInterval.map(AutomaticActionRefWithSchedule.copy),
      Option.copy(other.titleExpression),
      ScreenLifeMode.copy(other.lifeMode)
    )
  }

  findRootComponentRef(componentRefIdentifier: string) {
    return __(this.rootComponentsRefIds).find(c => c.identifier === componentRefIdentifier).map(c => c.refId).getOrUndefined();
  }
}


export class ScreenWithRelease {
  constructor(
    readonly screen: ScreenInfo,
    readonly releaseId: ScreenReleaseId,
    public releaseVersion: AggregateVersion,
    readonly release: ScreenRelease,
    readonly workingRelease: boolean) {}

  static copy(other: ScreenWithRelease) {
    return new ScreenWithRelease(
      ScreenInfo.copy(other.screen),
      ScreenReleaseId.copy(other.releaseId),
      AggregateVersion.copy(other.releaseVersion),
      ScreenRelease.copy(other.release),
      other.workingRelease);
  }
}




export class AutomaticVariable {
  constructor(readonly id: number,
              readonly path: AnyVariablePath,
              readonly mode: AutomaticVariableMode,
              readonly settings: Typed<AutomaticVariableSettings>) {}
  static copy(other: AutomaticVariable) {
    return new AutomaticVariable(other.id,
      AnyVariablePath.copy(other.path),
      AutomaticVariableMode.byName(other.mode.name),
      AutomaticVariableSettingsFactory.copyTyped(other.settings));
  }
  settingsUnwrapped() {
    return Typed.value(this.settings);
  }
}


export class AutomaticVariableSettingsFactory {
  static copy(settings: AutomaticVariableSettings): AutomaticVariableSettings {
    return AutomaticVariableSettingsFactory.copyByType(settings, settings.className());
  }

  static copyTyped(settings: Typed<AutomaticVariableSettings>): Typed<AutomaticVariableSettings> {
    return Typed.of(AutomaticVariableSettingsFactory.copyByType(Typed.value(settings), Typed.className(settings)));
  }

  static copyByType(settings: AutomaticVariableSettings, className: string): AutomaticVariableSettings {
    switch (className) {
      case StaticStringValueVariableSettings.className: return StaticStringValueVariableSettings.copy(<StaticStringValueVariableSettings> settings);
      case StaticNumberValueVariableSettings.className: return StaticNumberValueVariableSettings.copy(<StaticNumberValueVariableSettings> settings);
      case StaticBooleanValueVariableSettings.className: return StaticBooleanValueVariableSettings.copy(<StaticBooleanValueVariableSettings> settings);
      case BoundExpressionVariableSettings.className: return BoundExpressionVariableSettings.copy(<BoundExpressionVariableSettings> settings);
      case BoundVariablePathVariableSettings.className: return BoundVariablePathVariableSettings.copy(<BoundVariablePathVariableSettings> settings);
      default: throw new Error("Not supported '"+className+"'");
    }
  }
}

export interface AutomaticVariableSettings {
  className(): string;

  // This is temporary, before UI is implemented
  toExpression(): string;
  toAsync(): boolean;
}

export class StaticStringValueVariableSettings implements AutomaticVariableSettings {
  static className = "StaticStringValueVariableSettings";
  className(): string {
    return StaticStringValueVariableSettings.className;
  }
  constructor(readonly value: Option<string>) {}
  static copy(other: StaticStringValueVariableSettings) {
    return new StaticStringValueVariableSettings(Option.copy(other.value));
  }

  toExpression(): string {
    throw new Error("Not implemented");
  }
  toAsync(): boolean {
    throw new Error("Not implemented");
  }
}
export class StaticNumberValueVariableSettings implements AutomaticVariableSettings {
  static className = "StaticNumberValueVariableSettings";
  className(): string {
    return StaticNumberValueVariableSettings.className;
  }
  constructor(readonly value: Option<number>) {}
  static copy(other: StaticNumberValueVariableSettings) {
    return new StaticNumberValueVariableSettings(Option.copy(other.value));
  }
  toExpression(): string {
    throw new Error("Not implemented");
  }
  toAsync(): boolean {
    throw new Error("Not implemented");
  }
}
export class StaticBooleanValueVariableSettings implements AutomaticVariableSettings {
  static className = "StaticBooleanValueVariableSettings";
  className(): string {
    return StaticBooleanValueVariableSettings.className;
  }
  constructor(readonly value: Option<Boolean>) {}
  static copy(other: StaticBooleanValueVariableSettings) {
    return new StaticBooleanValueVariableSettings(Option.copy(other.value));
  }
  toExpression(): string {
    throw new Error("Not implemented");
  }
  toAsync(): boolean {
    throw new Error("Not implemented");
  }
}
export class BoundExpressionVariableSettings implements AutomaticVariableSettings {
  static className = "BoundExpressionVariableSettings";
  className(): string {
    return BoundExpressionVariableSettings.className;
  }
  constructor(readonly expression: string, readonly async: boolean) {}
  static copy(other: BoundExpressionVariableSettings) {
    return new BoundExpressionVariableSettings(other.expression, other.async);
  }
  toExpression(): string {
    return this.expression;
  }
  toAsync(): boolean {
    return this.async;
  }
}


export class BoundVariablePathVariableSettings implements AutomaticVariableSettings {
  static className = "BoundVariablePathVariableSettings";
  className(): string {
    return BoundVariablePathVariableSettings.className;
  }
  constructor(readonly variablePath: VariablePath) {}
  static copy(other: BoundVariablePathVariableSettings) {
    return new BoundVariablePathVariableSettings(VariablePath.copy(other.variablePath));
  }
  toExpression(): string {
    return this.variablePath.path;
  }
  toAsync(): boolean {
    return false;
  }
}





export class ScreenAuthorizationType {

  private constructor(readonly name: string) {}

  static copy(other: ScreenAuthorizationType) {
    switch (other.name) {
      case ScreenAuthorizationType.user.name: return ScreenAuthorizationType.user;
      case ScreenAuthorizationType.screen.name: return ScreenAuthorizationType.screen;
      default: throw new Error("Incorrect mode ["+other.name+"]");
    }
  }

  static readonly user = new ScreenAuthorizationType("user");
  static readonly screen = new ScreenAuthorizationType("screen");
}


export class AutomaticVariableMode {
  constructor(readonly name: string) {}
  static bind = new AutomaticVariableMode("bind");
  static init = new AutomaticVariableMode("init");

  static byName(name: string) {
    switch (name) {
      case AutomaticVariableMode.bind.name: return AutomaticVariableMode.bind;
      case AutomaticVariableMode.init.name: return AutomaticVariableMode.init;
      default: throw new Error("Incorrect mode ["+name+"]");
    }
  }

  isInit() {
    return this.name == AutomaticVariableMode.init.name;
  }

  isBind() {
    return this.name == AutomaticVariableMode.bind.name;
  }
}

export class ScreenComponents {

  private readonly definitionsMap = new Map<number, ScreenComponentDefinition>();
  private readonly refsMap = new Map<number, ScreenComponentRef>();

  constructor(private readonly definitions: ReadonlyArray<[number, Typed<ScreenComponentDefinition>]>,
              private readonly refs: ReadonlyArray<[number, Typed<ScreenComponentRef>]>,
              readonly actions: ReadonlyArray<[number, Typed<AutomaticAction>]>,
              readonly validationRules: ReadonlyArray<[number, ValidationRule]>) {

    definitions.forEach(d => this.definitionsMap.set(d[0], Typed.value(d[1])));
    refs.forEach(r => this.refsMap.set(r[0], Typed.value(r[1])));

  }

  getAllDefinitionsIds(): Array<ScreenComponentId> {
    return this.definitions.map(d => Typed.value(d[1]).id);
  }

  getAllRefsIds(): Array<ScreenComponentRefId> {
    return this.refs.map(d => Typed.value(d[1]).id);
  }

  maxComponentId() {
    return ___(this.getAllDefinitionsIds()).map(d => d.id).maxOrDefault(0);
  }

  maxRefId() {
    return ___(this.getAllRefsIds()).map(d => d.id).maxOrDefault(0);
  }

  getDefinition(componentId: ScreenComponentId): ScreenComponentDefinition {
    const found = this.definitionsMap.get(componentId.id);
    if (found) {
      return found
    } else {
      throw new Error("Component definition not found for id " + componentId.id);
    }
  }


  findDefinition(componentId: ScreenComponentId): Option<ScreenComponentDefinition> {
    const found = this.definitionsMap.get(componentId.id);
    if (found) {
      return Some(found);
    } else {
      return None();
    }
  }

  findRef(componentRefId: ScreenComponentRefId): ScreenComponentRef|undefined {
    return this.refsMap.get(componentRefId.id);
  }

  getRef(componentRefId: ScreenComponentRefId): ScreenComponentRef {
    const found = this.refsMap.get(componentRefId.id);
    if (found) {
      return found;
    } else {
      throw new Error("Component ref not found for id " + componentRefId.id);
    }
  }

  findRefToComponent(componentId: ScreenComponentId): Option<ScreenComponentRef> {
    const found = __(this.refs).find(d => Typed.value(d[1]).componentId.id == componentId.id);
    if (found.isDefined()) {
      return Option.of(Typed.value(found.get()[1]));
    } else {
      return None();
    }
  }

  getRemoteScreenIds(): Array<ScreenId> {
    return __(this.refs.filter(r => Typed.value(r[1]).applicationScreen.isDefined()).map(r => Typed.value(r[1]).applicationScreen.get())).uniqueBy(id => id.id);
  }

  actionsUnwrapped() {
    return this.actions.map(a => Typed.value(a[1]));
  }


  static copy(other: ScreenComponents) {
    return new ScreenComponents(
      other.definitions.map((a: [number, Typed<ScreenComponentDefinition>]) => <[number, Typed<ScreenComponentDefinition>]>[a[0], ScreenComponentDefinitionFactory.copyTyped(a[1])]),
      other.refs.map((a: [number, Typed<ScreenComponentRef>]) => <[number, Typed<ScreenComponentRef>]>[a[0], ScreenComponentRefFactory.copyTyped(a[1])]),
      other.actions.map((a: [number, Typed<AutomaticAction>]) => <[number, Typed<AutomaticAction>]>[a[0], AutomaticActionFactory.copyTyped(a[1])]),
      other.validationRules.map((a: [number, ValidationRule]) => <[number, ValidationRule]>[a[0], ValidationRule.copy(a[1])])
    );
  }

}

export abstract class GenericScreenComponentDefinition {


  //IMPORTANT // comment: string; // designer comment
  //IMPORTANT // visibleInSummary: boolean;
  abstract className(): string;

  abstract typeName(): string;
  typeNameTranslated() {
    return i18n("screen_"+this.typeName());
  }

  sizeProperties: SizeProperties;

  constructor(readonly id: ScreenComponentId,
              readonly identifier: Option<string>,
              readonly properties: ComponentProperties,
              readonly validationRules: ComponentValidationRules,
              readonly defaultPropertiesProvider: DefaultPropertyProvider) {
    this.sizeProperties = new SizeProperties(this.properties, this.defaultPropertiesProvider);
  }


  get uncovered(): BooleanProperty {
    return this.properties.getBooleanProperty("uncovered", this.defaultPropertiesProvider)
  };

  get visible(): BooleanProperty {
    return this.properties.getBooleanProperty("visible", this.defaultPropertiesProvider)
  };

  get editable(): BooleanProperty {
    return this.properties.getBooleanProperty("editable", this.defaultPropertiesProvider)
  };

  get initialized(): BooleanProperty {
    return this.properties.getBooleanProperty("initialized", this.defaultPropertiesProvider)
  };

  abstract getModelNames(): Array<string>;

  modelIsReadOnly() {
    const modelNames = this.getModelNames();
    if (modelNames.length == 0) {
      return false;
    } else {
      return __(modelNames).all(model => {
        const property = this.properties.getAnyModelProperty(model, this.defaultPropertiesProvider);
        if (property instanceof ModelProperty) {
          return !property.settingsUnwrapped().toSettingsType().isBoundVariablePath();
        } else {
          return property.enabled && !property.settingsUnwrapped().toSettingsType().isBoundVariablePath();
        }
      });
    }
  }

  getChildren(): Array<ScreenComponentRefId> {
    return [];
  }

  hasLabel() {
    return false;
  }

  displayName() {
    return this.typeName();
  }

}


export abstract class ScreenComponentDefinition extends GenericScreenComponentDefinition {

  constructor(override readonly id: ScreenComponentId,
              override readonly identifier: Option<string>,
              override readonly properties: ComponentProperties,
              override readonly validationRules: ComponentValidationRules,
              override readonly defaultPropertiesProvider: DefaultPropertyProvider) {
    super(id, identifier, properties, validationRules, defaultPropertiesProvider);
  }


}


export abstract class ScreenComponentDefinitionWithLabel extends ScreenComponentDefinition {

  override hasLabel(): boolean {
    return true;
  }

  abstract get labelProperties(): LabelProperties;

  get tooltip(): OptionalI18nTextProperty {
    return this.properties.getOptionalI18nTextProperty("tooltip", this.defaultPropertiesProvider)
  };


  override displayName(): string {
    const label = this.labelProperties.label(this.defaultPropertiesProvider);
    return (label.settingsUnwrapped().toSettingsType().isStaticValue() ? label.staticValue.getCurrentWithFallback() : "");
  }

}

export abstract class NoContextContainerDefinition extends GenericScreenComponentDefinition {
  constructor(override readonly id: ScreenComponentId,
              override readonly identifier: Option<string>,
              override readonly properties: ComponentProperties,
              override readonly validationRules: ComponentValidationRules,
              override readonly defaultPropertiesProvider: DefaultPropertyProvider) {
    super(id, identifier, properties, validationRules, defaultPropertiesProvider);
  }
}

export abstract class OptionalContextContainerDefinition extends GenericScreenComponentDefinition {
  abstract model: OptionalModelProperty;

  constructor(override readonly id: ScreenComponentId,
              override readonly identifier: Option<string>,
              override readonly properties: ComponentProperties,
              override readonly validationRules: ComponentValidationRules,
              override readonly defaultPropertiesProvider: DefaultPropertyProvider) {
    super(id, identifier, properties, validationRules, defaultPropertiesProvider);
  }

  getModelNames(): Array<string> {
    return ["model"];
  }
}

export abstract class RepeatableContextContainerDefinition extends GenericScreenComponentDefinition {
  abstract model: ModelProperty;

  constructor(override readonly id: ScreenComponentId,
              override readonly identifier: Option<string>,
              override readonly properties: ComponentProperties,
              override readonly validationRules: ComponentValidationRules,
              override readonly defaultPropertiesProvider: DefaultPropertyProvider) {
    super(id, identifier, properties, validationRules, defaultPropertiesProvider);
  }

  getModelNames(): Array<string> {
    return ["model"];
  }
}


export class OtherScreenComponents {
  constructor(readonly screenId: ScreenId,
              readonly components: ScreenComponents) {
  }
}



export class DefaultPropertyProvider {
  constructor(readonly providerName: string,
              readonly properties: ((name: string) => ComponentProperty<any> | null) | null,
              readonly propertiesSerialized: ((name: string, propertyType: PropertyType) => ComponentProperty<any> | null) | null,
              readonly fallbacks: Array<DefaultPropertyProvider>) {
  }

  // static constant(returnValue: () => ComponentProperty<any>) {
  //   return new DefaultPropertyProvider("noop", name => returnValue(), null, []);
  // }
  //
  // static noop = new DefaultPropertyProvider("noop", name => null, null, []);

  private get(propertyName: string, propertyType: PropertyType): ComponentProperty<any> | null {
    const defaultValue = this.properties !== null ?
      this.properties(propertyName) : (this.propertiesSerialized !== null ? this.propertiesSerialized(propertyName, propertyType) : null);
    if (defaultValue !== null) {
      return defaultValue;
    } else {
      for (let i = 0; i < this.fallbacks.length; i++) {
        const value = this.fallbacks[i].getIfExistsOrNull(propertyName);
        if (value != null) {
          return value;
        }
      }
      return null;
      // throw new Error("Provider "+this.providerName+": property ["+propertyName+"] not found");
    }
  }

  getAnyProperty(propertyName: string): ComponentProperty<any> | null {
    const defaultValue = this.properties !== null ? this.properties(propertyName) : null;
    if (defaultValue !== null) {
      return defaultValue;
    } else {
      for (let i = 0; i < this.fallbacks.length; i++) {
        const value = this.fallbacks[i].getIfExistsOrNull(propertyName);
        if (value != null) {
          return value;
        }
      }
      return null;
      // throw new Error("Provider "+this.providerName+": property ["+propertyName+"] not found");
    }
  }

  getOptionalStringProperty(propertyName: string): OptionalStringProperty | null {
    return <OptionalStringProperty | null>this.get(propertyName, PropertyType.OptionalString);
  }

  getStringProperty(propertyName: string): StringProperty {
    return <StringProperty>this.get(propertyName, PropertyType.String);
  }

  getOptionalNumberProperty(propertyName: string): OptionalNumberProperty {
    return <OptionalNumberProperty>this.get(propertyName, PropertyType.OptionalNumber);
  }

  getNumberProperty(propertyName: string): NumberProperty {
    return <NumberProperty>this.get(propertyName, PropertyType.Number);
  }

  getOptionalBooleanProperty(propertyName: string): OptionalBooleanProperty {
    return <OptionalBooleanProperty>this.get(propertyName, PropertyType.OptionalBoolean);
  }

  getBooleanProperty(propertyName: string): BooleanProperty {
    return <BooleanProperty>this.get(propertyName, PropertyType.Boolean);
  }

  getTrileanProperty(propertyName: string): TrileanProperty {
    return <TrileanProperty>this.get(propertyName, PropertyType.Trilean);
  }

  getDateProperty(propertyName: string): DateProperty {
    return <DateProperty>this.get(propertyName, PropertyType.Date);
  }

  getI18nTextProperty(propertyName: string): I18nTextProperty {
    return <I18nTextProperty>this.get(propertyName, PropertyType.I18nText);
  }

  getDateTimeProperty(propertyName: string): DateTimeProperty {
    return <DateTimeProperty>this.get(propertyName, PropertyType.DateTime);
  }

  getTimeProperty(propertyName: string): TimeProperty {
    return <TimeProperty>this.get(propertyName, PropertyType.Time);
  }

  getFileProperty(propertyName: string): FileProperty {
    return <FileProperty>this.get(propertyName, PropertyType.File);
  }

  getOptionalFileProperty(propertyName: string): OptionalFileProperty {
    return <OptionalFileProperty>this.get(propertyName, PropertyType.OptionalFile);
  }

  getModelProperty(propertyName: string): ModelProperty {
    return <ModelProperty>this.get(propertyName, PropertyType.Model);
  }

  getOptionalModel(propertyName: string): OptionalModelProperty {
    return <OptionalModelProperty>this.get(propertyName, PropertyType.OptionalModel);
  }

  getAnyModelProperty(propertyName: string): ModelProperty | OptionalModelProperty {
    return <ModelProperty | OptionalModelProperty>this.get(propertyName, PropertyType.Model);
  }

  getOptionalI18nProperty(propertyName: string): OptionalI18nTextProperty {
    return <OptionalI18nTextProperty>this.get(propertyName, PropertyType.OptionalI18nText);
  }

  getOptionalDateProperty(propertyName: string): OptionalDateProperty {
    return <OptionalDateProperty>this.get(propertyName, PropertyType.OptionalDate);
  }

  getOptionalDateTimeProperty(propertyName: string): OptionalDateTimeProperty {
    return <OptionalDateTimeProperty>this.get(propertyName, PropertyType.OptionalDateTime);
  }

  getOptionalTimeProperty(propertyName: string): OptionalTimeProperty {
    return <OptionalTimeProperty>this.get(propertyName, PropertyType.OptionalTime);
  }

  getOptionalDurationProperty(propertyName: string): OptionalDurationProperty {
    return <OptionalDurationProperty>this.get(propertyName, PropertyType.OptionalDuration);
  }

  getLabeledValuesProperty(propertyName: string): LabeledValuesProperty {
    return <LabeledValuesProperty>this.get(propertyName, PropertyType.LabeledValues);
  }

  getOptionalOrganizationNodesProperty(propertyName: string): OptionalOrganizationNodesProperty {
    return <OptionalOrganizationNodesProperty>this.get(propertyName, PropertyType.OptionalOrganizationNodes);
  }

  getOptionalStringArrayProperty(propertyName: string): OptionalStringArrayProperty {
    return <OptionalStringArrayProperty>this.get(propertyName, PropertyType.OptionalStringArray);
  }


  /*****************/

  getOptionalStringPropertyIfExists(propertyName: string): Option<OptionalStringProperty> {
    return <Option<OptionalStringProperty>>this.getIfExists(propertyName, PropertyType.OptionalString);
  }

  getStringPropertyIfExists(propertyName: string): Option<StringProperty> {
    return <Option<StringProperty>>this.getIfExists(propertyName, PropertyType.String);
  }

  getOptionalNumberPropertyIfExists(propertyName: string): Option<OptionalNumberProperty> {
    return <Option<OptionalNumberProperty>>this.getIfExists(propertyName, PropertyType.OptionalNumber);
  }

  getNumberPropertyIfExists(propertyName: string): Option<NumberProperty> {
    return <Option<NumberProperty>>this.getIfExists(propertyName, PropertyType.Number);
  }

  getOptionalBooleanPropertyIfExists(propertyName: string): Option<OptionalBooleanProperty> {
    return <Option<OptionalBooleanProperty>>this.getIfExists(propertyName, PropertyType.OptionalBoolean);
  }

  getBooleanPropertyIfExists(propertyName: string): Option<BooleanProperty> {
    return <Option<BooleanProperty>>this.getIfExists(propertyName, PropertyType.Boolean);
  }

  getTrileanPropertyIfExists(propertyName: string): Option<TrileanProperty> {
    return <Option<TrileanProperty>>this.getIfExists(propertyName, PropertyType.Trilean);
  }

  getDatePropertyIfExists(propertyName: string): Option<DateProperty> {
    return <Option<DateProperty>>this.getIfExists(propertyName, PropertyType.Date);
  }

  getI18nTextPropertyIfExists(propertyName: string): Option<I18nTextProperty> {
    return <Option<I18nTextProperty>>this.getIfExists(propertyName, PropertyType.I18nText);
  }

  getDateTimePropertyIfExists(propertyName: string): Option<DateTimeProperty> {
    return <Option<DateTimeProperty>>this.getIfExists(propertyName, PropertyType.DateTime);
  }

  getTimePropertyIfExists(propertyName: string): Option<TimeProperty> {
    return <Option<TimeProperty>>this.getIfExists(propertyName, PropertyType.Time);
  }

  getFilePropertyIfExists(propertyName: string): Option<FileProperty> {
    return <Option<FileProperty>>this.getIfExists(propertyName, PropertyType.File);
  }

  getOptionalFilePropertyIfExists(propertyName: string): Option<OptionalFileProperty> {
    return <Option<OptionalFileProperty>>this.getIfExists(propertyName, PropertyType.OptionalFile);
  }

  getModelPropertyIfExists(propertyName: string): Option<ModelProperty> {
    return <Option<ModelProperty>>this.getIfExists(propertyName, PropertyType.Model);
  }

  getOptionalModelPropertyIfExists(propertyName: string): Option<OptionalModelProperty> {
    return <Option<OptionalModelProperty>>this.getIfExists(propertyName, PropertyType.Model);
  }

  getAnyModelPropertyIfExists(propertyName: string): Option<ModelProperty | OptionalModelProperty> {
    return <Option<ModelProperty | OptionalModelProperty>>this.getIfExists(propertyName, PropertyType.Model);
  }

  getOptionalI18nTextPropertyIfExists(propertyName: string): Option<OptionalI18nTextProperty> {
    return <Option<OptionalI18nTextProperty>>this.getIfExists(propertyName, PropertyType.OptionalI18nText);
  }

  getOptionalDatePropertyIfExists(propertyName: string): Option<OptionalDateProperty> {
    return <Option<OptionalDateProperty>>this.getIfExists(propertyName, PropertyType.OptionalDate);
  }

  getOptionalDateTimePropertyIfExists(propertyName: string): Option<OptionalDateTimeProperty> {
    return <Option<OptionalDateTimeProperty>>this.getIfExists(propertyName, PropertyType.OptionalDateTime);
  }

  getOptionalTimePropertyIfExists(propertyName: string): Option<OptionalTimeProperty> {
    return <Option<OptionalTimeProperty>>this.getIfExists(propertyName, PropertyType.OptionalTime);
  }

  getDurationPropertyIfExists(propertyName: string): Option<DurationProperty> {
    return <Option<DurationProperty>>this.getIfExists(propertyName, PropertyType.Duration);
  }

  getOptionalDurationPropertyIfExists(propertyName: string): Option<OptionalDurationProperty> {
    return <Option<OptionalDurationProperty>>this.getIfExists(propertyName, PropertyType.OptionalDuration);
  }

  getLabeledValuesPropertyIfExists(propertyName: string): Option<LabeledValuesProperty> {
    return <Option<LabeledValuesProperty>>this.getIfExists(propertyName, PropertyType.LabeledValues);
  }

  getOptionalOrganizationNodesPropertyIfExists(propertyName: string): Option<OptionalOrganizationNodesProperty> {
    return <Option<OptionalOrganizationNodesProperty>>this.getIfExists(propertyName, PropertyType.OptionalOrganizationNodes);
  }

  getOptionalStringArrayPropertyIfExists(propertyName: string): Option<OptionalStringArrayProperty> {
    return <Option<OptionalStringArrayProperty>>this.getIfExists(propertyName, PropertyType.OptionalStringArray);
  }


  getIfExistsOrNull(propertyName: string): ComponentProperty<any> | null {
    const defaultValue = this.properties !== null ?
      this.properties(propertyName) : (this.propertiesSerialized !== null ? this.propertiesSerialized(propertyName, PropertyType.None) : null);
    if (defaultValue != null) {
      return defaultValue;
    } else {
      for (let i = 0; i < this.fallbacks.length; i++) {
        const value = this.fallbacks[i].getIfExistsOrNull(propertyName);
        if (value != null) {
          return value;
        }
      }
      return null;
    }
  }

  getIfExists(propertyName: string, propertyType: PropertyType): Option<ComponentProperty<any>> {
    const defaultValue = this.properties !== null ?
      this.properties(propertyName) : (this.propertiesSerialized !== null ? this.propertiesSerialized(propertyName, propertyType) : null);
    if (defaultValue != null) {
      return Some(defaultValue);
    } else {
      for (let i = 0; i < this.fallbacks.length; i++) {
        const value = this.fallbacks[i].getIfExistsOrNull(propertyName);
        if (value != null) {
          return Some(value);
        }
      }
      return None();
    }
  }

  static ofFallbacks(providerName: string, ...fallbacks: Array<DefaultPropertyProvider>) {
    return new DefaultPropertyProvider(providerName, null, null, fallbacks);
  }

  static of(providerName: string, properties: (propertyName: string) => ComponentProperty<any> | null, ...fallbacks: Array<DefaultPropertyProvider>) {
    return new DefaultPropertyProvider(providerName, properties, null, fallbacks);
  }

  static ofSkins(skinsPropertiesProvider: SkinsPropertiesProvider, ...fallbacks: Array<DefaultPropertyProvider>) {
    return new DefaultPropertyProvider("skins", null, (propertyName: string, propertyType: PropertyType) => {
      return skinsPropertiesProvider.getIfExists(propertyName, propertyType).getOrNull()
    }, fallbacks);
  }


  static ref: DefaultPropertyProvider = DefaultPropertyProvider.of("ref", (name: string) => {
    switch (name) {
      case "enabled":
        return BooleanProperty.ofTrue(); // enabled is deprecated
      case "uncovered":
        return BooleanProperty.ofTrue();
      case "visible":
        return BooleanProperty.ofTrue();
      case "mobile":
        return BooleanProperty.ofTrue();
      case "tablet":
        return BooleanProperty.ofTrue();
      case "desktop":
        return BooleanProperty.ofTrue();
      case "editable":
        return BooleanProperty.ofTrue();
      case "preview":
        return OptionalBooleanProperty.disabled(true);
      case "desiredWidth":
        return OptionalStringProperty.disabled("4cm");
      case "desiredHeight":
        return OptionalStringProperty.disabled("2cm");
      case "left":
        return OptionalStringProperty.disabled("0cm");
      case "top":
        return OptionalStringProperty.disabled("0cm");
      case "right":
        return OptionalStringProperty.disabled("0cm");
      case "bottom":
        return OptionalStringProperty.disabled("0cm");
      case "column":
        return OptionalNumberProperty.disabled(1);
      case "row":
        return OptionalNumberProperty.disabled(1);
      case "columnSpan":
        return NumberProperty.of(1);
      case "rowSpan":
        return NumberProperty.of(1);
      case "marginTop":
        return OptionalStringProperty.disabled("0cm");
      case "marginLeft":
        return OptionalStringProperty.disabled("0cm");
      case "marginBottom":
        return OptionalStringProperty.disabled("0cm");
      case "marginRight":
        return OptionalStringProperty.disabled("0cm");
      default:
        return null;
    }
  });

  static refRequiredInput: DefaultPropertyProvider = DefaultPropertyProvider.of("refRequiredInput", (name: string) => {
    switch (name) {
      case "required":
        return BooleanProperty.ofFalse();
      default:
        return null;
    }
  });

  static containerPaddings: DefaultPropertyProvider = DefaultPropertyProvider.of("containerPaddings", (name: string) => {
    switch (name) {
      case "paddingTop":
        return OptionalStringProperty.disabled("0.0cm");
      case "paddingLeft":
        return OptionalStringProperty.disabled("0.0cm");
      case "paddingBottom":
        return OptionalStringProperty.disabled("0.0cm");
      case "paddingRight":
        return OptionalStringProperty.disabled("0.0cm");
      default:
        return null;
    }
  });

  static definitionPaddings: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionPaddings", (name: string) => {
    switch (name) {
      case "paddingTop":
        return OptionalStringProperty.disabled("0.0cm");
      case "paddingLeft":
        return OptionalStringProperty.disabled("0.0cm");
      case "paddingBottom":
        return OptionalStringProperty.disabled("0.0cm");
      case "paddingRight":
        return OptionalStringProperty.disabled("0.0cm");
      default:
        return null;
    }
  });

  static definitionBackgrounds: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionBackgrounds", (name: string) => {
    switch (name) {
      case "backgroundColor":
        return OptionalStringProperty.disabled("$content-background-color");
      default:
        return null;
    }
  });

  static definitionEntriesLayout: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionEntriesLayout", (name: string) => {
    switch (name) {
      case "entriesLayout":
        return StringProperty.of("vertical");
      default:
        return null;
    }
  });

  static definitionBorders: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionBorders", (name: string) => {
    switch (name) {
      case "borderTop":
        return OptionalStringProperty.disabled("1px")
      case "borderLeft":
        return OptionalStringProperty.disabled("1px")
      case "borderBottom":
        return OptionalStringProperty.disabled("1px")
      case "borderRight":
        return OptionalStringProperty.disabled("1px")
      case "borderRadius":
        return OptionalStringProperty.disabled("0.5cm")
      case "borderColor":
        return OptionalStringProperty.disabled("$main-text-color");
      case "borderColorTop":
        return OptionalStringProperty.disabled("$main-text-color");
      case "borderColorLeft":
        return OptionalStringProperty.disabled("$main-text-color");
      case "borderColorBottom":
        return OptionalStringProperty.disabled("$main-text-color");
      case "borderColorRight":
        return OptionalStringProperty.disabled("$main-text-color");
      case "outerShadow":
        return BooleanProperty.ofFalse();
      default:
        return null;
    }
  });

  static definitionComponent: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionComponent", (name: string) => {
    switch (name) {
      case "label":
        return OptionalI18nTextProperty.disabled();
      case "enabled":
        return BooleanProperty.ofTrue(); // enabled is deprecated
      case "uncovered":
        return BooleanProperty.ofTrue();
      case "visible":
        return BooleanProperty.ofTrue();
      case "editable":
        return BooleanProperty.ofTrue();
      case "customCss":
        return OptionalStringProperty.disabled("");
      case "customIdentifier":
        return OptionalStringProperty.disabled("");
      case "componentClass":
        return StringProperty.of("$default");
      case "componentSkin":
        return OptionalStringProperty.disabled("");
      case "cssClass":
        return OptionalStringProperty.disabled("");
      case "contentMinWidth":
        return OptionalStringProperty.disabled("1cm");
      case "contentMinHeight":
        return OptionalStringProperty.disabled("1cm");
      case "maxWidth":
        return OptionalStringProperty.disabled("8cm");
      case "maxHeight":
        return OptionalStringProperty.disabled("8cm");
      default:
        return null;
    }
  });

  static containerPlain: DefaultPropertyProvider = DefaultPropertyProvider.of("plainContainer", (name: string) => {
    switch (name) {
      case "header":
        return OptionalI18nTextProperty.disabled();
      case "identifier":
        return StringProperty.of("");
      case "gapColumn":
        return OptionalStringProperty.of("0.0cm");
      case "gapRow":
        return OptionalStringProperty.of("0.0cm");
      case "contentTextSize":
        return OptionalStringProperty.disabled("14px");
      case "contentTextColor":
        return OptionalStringProperty.disabled("$main-text-color");
      case "contentTextFont":
        return OptionalStringProperty.disabled(TextFont.DEFAULT.name);
      case "layout":
        return StringProperty.of(LayoutType.horizontal.name);
      case "layoutAlign":
        return StringProperty.of(LayoutAlign.DEFAULT.name);
      case "layoutStretch":
        return StringProperty.of(LayoutStretch.DEFAULT.name);
      case "layoutWrap":
        return StringProperty.of(LayoutWrap.DEFAULT.name);
      case "editable":
        return BooleanProperty.ofTrue();
      case "preview":
        return OptionalBooleanProperty.disabled(true);
      case "visible":
        return BooleanProperty.ofTrue();
      case "enabled":
        return BooleanProperty.ofTrue(); // enabled is used e.g. in tabs
      case "uncovered":
        return BooleanProperty.ofTrue();
      case "rows":
        return NumberProperty.of(2);
      case "columns":
        return NumberProperty.of(2);
      default:
        return null;
    }
  });

  static definitionText: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionText", (name: string) => {
    switch (name) {
      case "textSize":
        return OptionalStringProperty.disabled("14px");
      case "textColor":
        return OptionalStringProperty.disabled("$main-text-color");
      case "textAlign":
        return StringProperty.of(TextAlign.DEFAULT.name);
      case "textVerticalAlign":
        return StringProperty.of(TextVerticalAlign.DEFAULT.name);
      case "textFont":
        return OptionalStringProperty.disabled(TextFont.DEFAULT.name);
      case "bold":
        return BooleanProperty.ofFalse();
      case "italic":
        return BooleanProperty.ofFalse();
      case "underline":
        return BooleanProperty.ofFalse();
      case "lineThrough":
        return BooleanProperty.ofFalse();
      default:
        return null;
    }
  });

  static definitionTooltip: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionTooltip", (name: string) => {
    switch (name) {
      case "tooltip":
        return OptionalI18nTextProperty.disabled();
      default:
        return null;
    }
  });

  static definitionLabel: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionLabel", (name: string) => {
    switch (name) {
      case "labelPosition":
        return StringProperty.of(LabelPosition.top.name);
      case "labelSize":
        return OptionalStringProperty.disabled("14px");
      case "labelX":
        return StringProperty.of("0cm");
      case "labelY":
        return StringProperty.of("0cm");
      case "labelWidth":
        return OptionalStringProperty.disabled("5cm");
      case "labelHeight":
        return OptionalStringProperty.disabled("2cm");
      case "labelColor":
        return OptionalStringProperty.disabled("$main-text-color");
      case "labelAlign":
        return OptionalStringProperty.disabled(TextAlign.DEFAULT.name);
      case "labelVerticalAlign":
        return OptionalStringProperty.disabled(TextVerticalAlign.DEFAULT.name);
      case "labelFont":
        return OptionalStringProperty.disabled(TextFont.DEFAULT.name);
      case "labelBold":
        return BooleanProperty.ofFalse();
      case "labelItalic":
        return BooleanProperty.ofFalse();
      case "labelUnderline":
        return BooleanProperty.ofFalse();
      case "labelLineThrough":
        return BooleanProperty.ofFalse();
      default:
        return null;
    }
  });

  static definitionSectionHeader: DefaultPropertyProvider = DefaultPropertyProvider.of("definitionSectionHeader", (name: string) => {
    switch (name) {
      case "headerTextSize":
        return OptionalStringProperty.disabled("14px");
      case "headerTextColor":
        return OptionalStringProperty.disabled("$main-text-color");
      case "headerTextAlign":
        return OptionalStringProperty.disabled(TextAlign.DEFAULT.name);
      case "headerTextVerticalAlign":
        return OptionalStringProperty.disabled(TextVerticalAlign.center.name);
      case "headerTextFont":
        return OptionalStringProperty.disabled(TextAlign.DEFAULT.name);
      case "headerTextBold":
        return BooleanProperty.ofFalse();
      case "headerTextItalic":
        return BooleanProperty.ofFalse();
      case "headerTextUnderline":
        return BooleanProperty.ofFalse();
      case "headerTextLineThrough":
        return BooleanProperty.ofFalse();
      case "headerBackgroundColor":
        return OptionalStringProperty.disabled("$main-text-color");
      case "headerMinHeight":
        return OptionalStringProperty.disabled("1cm");

      case "headerBorderTop":
        return OptionalStringProperty.disabled("1px");
      case "headerBorderLeft":
        return OptionalStringProperty.disabled("1px");
      case "headerBorderBottom":
        return OptionalStringProperty.disabled("1px");
      case "headerBorderRight":
        return OptionalStringProperty.disabled("1px");
      case "headerBorderRadius":
        return OptionalStringProperty.disabled("1cm");
      case "headerBorderColorTop":
        return OptionalStringProperty.disabled("$main-text-color");
      case "headerBorderColorLeft":
        return OptionalStringProperty.disabled("$main-text-color");
      case "headerBorderColorBottom":
        return OptionalStringProperty.disabled("$main-text-color");
      case "headerBorderColorRight":
        return OptionalStringProperty.disabled("$main-text-color");
      // case "headerOuterShadow": return BooleanProperty.ofFalse();

      case "headerPaddingTop":
        return OptionalStringProperty.disabled("0.0cm");
      case "headerPaddingLeft":
        return OptionalStringProperty.disabled("0.0cm");
      case "headerPaddingBottom":
        return OptionalStringProperty.disabled("0.0cm");
      case "headerPaddingRight":
        return OptionalStringProperty.disabled("0.0cm");

      default:
        return null;
    }
  });


}

export class ComponentValidationRules {
  constructor(readonly rules: Array<ValidationRuleRef>) {
  }

  static copy(other: ComponentValidationRules) {
    return new ComponentValidationRules(other.rules.map(ValidationRuleRef.copy));
  }

  static newEmpty() {
    return new ComponentValidationRules([]);
  }
}


export class ActionValidationRule {
  constructor(readonly id: number,
              readonly expression: string,
              readonly message: I18nText,
              readonly messageExpression: Option<string>,
              readonly stopOnError: boolean) {
  }

  static copy(other: ActionValidationRule) {
    return new ActionValidationRule(other.id, other.expression, I18nText.copy(other.message), Option.copy(other.messageExpression), other.stopOnError);
  }
}


export class ComponentActionProperty {
  constructor(readonly componentsToValidate: Array<ScreenComponentRefId>,
              readonly validationRules: Array<ValidationRuleRef>,
              readonly actions: Array<AutomaticActionRef>) {
  }

  static copy(other: ComponentActionProperty): ComponentActionProperty {
    return new ComponentActionProperty(other.componentsToValidate.map(ScreenComponentRefId.copy),
      other.validationRules.map(ValidationRuleRef.copy),
      other.actions.map(AutomaticActionRef.copy));
  }
}




export class ComponentActionProperties {
  constructor(readonly properties: Array<[string, ComponentActionProperty]>) {
  }

  static copy(other: ComponentActionProperties) {
    return new ComponentActionProperties(other.properties.map((p: [string, ComponentActionProperty]) => <[string, ComponentActionProperty]>[p[0], ComponentActionProperty.copy(p[1])]));
  }

  static newEmpty() {
    return new ComponentActionProperties([]);
  }

  getActions(name: string): Array<AutomaticActionRef> {
    const actions = this.properties.filter(p => p[0] == name);
    if (actions.length > 0) {
      return actions[0][1].actions;
    } else {
      return [];
    }
  }

  getComponentsToValidate(name: string): Array<ScreenComponentRefId> {
    const actions = this.properties.filter(p => p[0] == name);
    if (actions.length > 0) {
      return actions[0][1].componentsToValidate;
    } else {
      return [];
    }
  }

  getValidationRules(name: string) {
    const actions = this.properties.filter(p => p[0] == name);
    if (actions.length > 0) {
      return actions[0][1].validationRules;
    } else {
      return [];
    }
  }


  // mapProperties<T>(handler: (propertyName: string, value: Array<Typed<AutomaticAction>>) => T): Array<T> {
  //   return this.properties.map(p => handler(p[0], p[1]));
  // }


}

export class ComponentProperties {
  constructor(readonly properties: Array<[string, Typed<ComponentProperty<any>>]>) {
  }

  static empty() {
    return new ComponentProperties([]);
  }

  static copy(other: ComponentProperties) {
    return new ComponentProperties(other.properties.map((p: [string, Typed<ComponentProperty<any>>]) => <[string, Typed<ComponentProperty<any>>]>[p[0], ComponentPropertyFactory.copyTyped(p[1])]));
  }

  mapProperties<T>(handler: (propertyName: string, value: ComponentProperty<any>) => T): Array<T> {
    return this.properties.map(p => handler(p[0], Typed.value(p[1])));
  }

  private getPropertyOption<P extends ComponentProperty<any>>(name: string, defaultValue: () => P): P {
    return __(this.properties).find(p => p[0] == name).map(p => <P>Typed.value(p[1])).getOrElseLazy(() => defaultValue());
  }

  private getEnabledPropertyOption<P extends ComponentProperty<any>>(name: string, defaultValue: () => P | null): P {
    const propertyOption = __(this.properties).find(p => p[0] == name).map(p => <P>Typed.value(p[1]))

    if (propertyOption.isDefined() && propertyOption.get().isEnabled()) {
      return propertyOption.get();
    } else if (propertyOption.isDefined()) {
      const def = defaultValue();
      if (def == null) {
        return propertyOption.get();
      } else {
        return def;
      }
    } else {
      const def = defaultValue();
      if (def === null) {
        throw new Error("Property not available '" + name + "'");
      } else {
        return def;
      }
    }
  }

  getTrileanProperty(name: string, defaultProvider: DefaultPropertyProvider): TrileanProperty {
    return this.getPropertyOption<TrileanProperty>(name, () => <TrileanProperty>defaultProvider.getTrileanProperty(name)).toRequired();
  }

  getBooleanProperty(name: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.getEnabledPropertyOption<BooleanProperty | OptionalBooleanProperty>(name, () => <BooleanProperty>defaultProvider.getBooleanProperty(name)).toRequired();
  }

  getOptionalBooleanProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.getEnabledPropertyOption<BooleanProperty | OptionalBooleanProperty>(name, () => <BooleanProperty | OptionalBooleanProperty>defaultProvider.getOptionalBooleanProperty(name)).toOptional();
  }

  // This is to have proper value when user enables it
  getOptionalBooleanPropertyNonDefault(name: string, defaultProvider: DefaultPropertyProvider): OptionalBooleanProperty {
    return this.getPropertyOption<OptionalBooleanProperty>(name, () => {
      const defaultValue: OptionalBooleanProperty | BooleanProperty = <OptionalBooleanProperty | BooleanProperty>defaultProvider.getOptionalBooleanProperty(name);
      return OptionalBooleanProperty.disabled(defaultValue.staticValue);
    });
  }

  getOptionalStringProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.getEnabledPropertyOption<StringProperty | OptionalStringProperty>(name, () => <StringProperty | OptionalStringProperty>defaultProvider.getOptionalStringProperty(name)).toOptional();
  }

  getI18nTextProperty(name: string, defaultProvider: DefaultPropertyProvider): I18nTextProperty {
    return this.getPropertyOption<I18nTextProperty>(name, () => <I18nTextProperty>defaultProvider.getI18nTextProperty(name));
  }

  getStringProperty(name: string, defaultProvider: DefaultPropertyProvider): StringProperty {
    return this.getEnabledPropertyOption<StringProperty | OptionalStringProperty>(name, () => <StringProperty | OptionalStringProperty>defaultProvider.getStringProperty(name)).toRequired();
  }

  getDateProperty(name: string, defaultProvider: DefaultPropertyProvider): DateProperty {
    return this.getPropertyOption<DateProperty>(name, () => <DateProperty>defaultProvider.getDateProperty(name));
  }

  getDateTimeProperty(name: string, defaultProvider: DefaultPropertyProvider): DateTimeProperty {
    return this.getPropertyOption<DateTimeProperty>(name, () => <DateTimeProperty>defaultProvider.getDateTimeProperty(name));
  }

  getTimeProperty(name: string, defaultProvider: DefaultPropertyProvider): TimeProperty {
    return this.getPropertyOption<TimeProperty>(name, () => <TimeProperty>defaultProvider.getTimeProperty(name));
  }

  getFileProperty(name: string, defaultProvider: DefaultPropertyProvider): FileProperty {
    return this.getPropertyOption<FileProperty>(name, () => <FileProperty>defaultProvider.getFileProperty(name));
  }

  getOptionalFileProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalFileProperty {
    return this.getPropertyOption<OptionalFileProperty>(name, () => <OptionalFileProperty>defaultProvider.getOptionalFileProperty(name));
  }

  getNumberProperty(name: string, defaultProvider: DefaultPropertyProvider): NumberProperty {
    return this.getEnabledPropertyOption<NumberProperty>(name, () => <NumberProperty>defaultProvider.getNumberProperty(name));
  }

  getModelProperty(name: string, defaultProvider: DefaultPropertyProvider): ModelProperty {
    return this.getPropertyOption<ModelProperty>(name, () => <ModelProperty>defaultProvider.getModelProperty(name));
  }

  getOptionalModelProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalModelProperty {
    return this.getPropertyOption<OptionalModelProperty>(name, () => <OptionalModelProperty>defaultProvider.getOptionalModel(name));
  }

  getAnyModelProperty(name: string, defaultProvider: DefaultPropertyProvider): ModelProperty | OptionalModelProperty {
    return this.getPropertyOption<ModelProperty | OptionalModelProperty>(name, () => <ModelProperty | OptionalModelProperty>defaultProvider.getAnyModelProperty(name));
  }

  /* Returns tuples of variableName and propety value*/
  getAnyModelPropertiesByPrefix(propertyNamePrefix: string): Array<[string, ModelProperty | OptionalModelProperty]> {
    return __(this.properties).filter(p => p[0].indexOf(propertyNamePrefix + "|") == 0).map(p => <[string, ModelProperty | OptionalModelProperty]>[p[0].substring(propertyNamePrefix.length + 1), <ModelProperty | OptionalModelProperty>Typed.value(p[1])]);
  }


  getOptionalI18nTextProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalI18nTextProperty {
    return this.getPropertyOption<OptionalI18nTextProperty>(name, () => <OptionalI18nTextProperty>defaultProvider.getOptionalI18nProperty(name));
  }

  // This is to have proper value when user enables it
  getOptionalStringPropertyNonDefault(name: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.getPropertyOption<OptionalStringProperty>(name, () => {
      const defaultValue: OptionalStringProperty | StringProperty = <OptionalStringProperty | StringProperty>defaultProvider.getOptionalStringProperty(name);
      return OptionalStringProperty.disabled(defaultValue.staticValue);
    });
  }

  getOptionalDateProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalDateProperty {
    return this.getPropertyOption<OptionalDateProperty>(name, () => <OptionalDateProperty>defaultProvider.getOptionalDateProperty(name));
  }

  getOptionalDateTimeProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalDateTimeProperty {
    return this.getPropertyOption<OptionalDateTimeProperty>(name, () => <OptionalDateTimeProperty>defaultProvider.getOptionalDateTimeProperty(name));
  }

  getOptionalTimeProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalTimeProperty {
    return this.getPropertyOption<OptionalTimeProperty>(name, () => <OptionalTimeProperty>defaultProvider.getOptionalTimeProperty(name));
  }

  getOptionalDurationProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalDurationProperty {
    return this.getPropertyOption<OptionalDurationProperty>(name, () => <OptionalDurationProperty>defaultProvider.getOptionalDurationProperty(name));
  }

  getOptionalNumberProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalNumberProperty {
    return this.getEnabledPropertyOption<OptionalNumberProperty>(name, () => <OptionalNumberProperty>defaultProvider.getOptionalNumberProperty(name));
  }

  getLabeledValuesProperty(name: string, defaultProvider: DefaultPropertyProvider): LabeledValuesProperty {
    return this.getPropertyOption<LabeledValuesProperty>(name, () => <LabeledValuesProperty>defaultProvider.getLabeledValuesProperty(name));
  }

  getOptionalOrganizationNodesProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalOrganizationNodesProperty {
    return this.getPropertyOption<OptionalOrganizationNodesProperty>(name, () => <OptionalOrganizationNodesProperty>defaultProvider.getOptionalOrganizationNodesProperty(name));
  }

  getOptionalStringArrayProperty(name: string, defaultProvider: DefaultPropertyProvider): OptionalStringArrayProperty {
    return this.getPropertyOption<OptionalStringArrayProperty>(name, () => <OptionalStringArrayProperty>defaultProvider.getOptionalStringArrayProperty(name));
  }

  putProperty(name: string, property: ComponentProperty<any>) {
    const index = __(this.properties).findIndexOf(e => e[0] == name);
    index.forEach(i => this.properties.splice(i, 1));
    this.properties.push([name, Typed.of(property)]);
  }

  deleteProperty(name: string) {
    const index = __(this.properties).findIndexOf(e => e[0] == name);
    index.forEach(i => this.properties.splice(i, 1));
  }

  putPropertyIfNotEmpty(name: string, property: ComponentProperty<any>) {
    const index = __(this.properties).findIndexOf(e => e[0] == name);
    index.forEach(i => this.properties.splice(i, 1));
    if (property.isOptional()) {
      const optional = <OptionalComponentProperty<any>>property;
      if (optional.enabled) {
        this.properties.push([name, Typed.of(property)]);
      } // otherwise do nothing
    } else {
      this.properties.push([name, Typed.of(property)]);
    }

  }

  getAllPropertiesIds(): Array<string> {
    return this.properties.map(p => p[0]);
  }

  getAnyProperty(name: string): Option<ComponentProperty<any>> {
    return __(this.properties).find(p => p[0] == name).map(p => Typed.value(p[1]));
  }
}

export class PaddingsProperties {

  constructor(readonly prefix: string,
              readonly properties: ComponentProperties) {
  }

  paddingTop(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty(this.prefix + "paddingTop", defaultProvider)
  }

  paddingLeft(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty(this.prefix + "paddingLeft", defaultProvider)
  }

  paddingBottom(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty(this.prefix + "paddingBottom", defaultProvider)
  }

  paddingRight(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty(this.prefix + "paddingRight", defaultProvider)
  }
}


export class BordersProperties {

  constructor(readonly properties: ComponentProperties) {
  }

  borderTop(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderTop", defaultProvider)
  }

  borderLeft(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderLeft", defaultProvider)
  }

  borderBottom(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderBottom", defaultProvider)
  }

  borderRight(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderRight", defaultProvider)
  }

  borderRadius(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderRadius", defaultProvider)
  }

  borderColorTop(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderColorTop", defaultProvider)
  }

  borderColorLeft(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderColorLeft", defaultProvider)
  }

  borderColorBottom(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderColorBottom", defaultProvider)
  }

  borderColorRight(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("borderColorRight", defaultProvider)
  }

  outerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("outerShadow", defaultProvider)
  }
}

export class BackgroundsProperties {

  constructor(readonly prefix: string,
              readonly properties: ComponentProperties) {
  }

  backgroundColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty(this.prefix + "backgroundColor", defaultProvider);
  }

}


export class SizeProperties {

  constructor(readonly properties: ComponentProperties,
              readonly defaultProvider: DefaultPropertyProvider) {
  }

  get componentClass(): StringProperty {
    return this.properties.getStringProperty("componentClass", this.defaultProvider)
  }

  get componentSkin(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("componentSkin", this.defaultProvider)
  }

  get cssClass(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("cssClass", this.defaultProvider)
  }

  get contentMinWidth(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("contentMinWidth", this.defaultProvider)
  }

  get contentMinHeight(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("contentMinHeight", this.defaultProvider)
  }

  get maxWidth(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("maxWidth", this.defaultProvider)
  }

  get maxHeight(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("maxHeight", this.defaultProvider)
  }

}


export class TextsProperties {

  constructor(readonly properties: ComponentProperties) {
  }


  textSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("textSize", defaultProvider)
  }

  textColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("textColor", defaultProvider)
  }

  textFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("textFont", defaultProvider)
  }

  textAlign(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): StringProperty {
    return this.properties.getStringProperty("textAlign", defaultProvider)
  }

  textVerticalAlign(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): StringProperty {
    return this.properties.getStringProperty("textVerticalAlign", defaultProvider)
  }

  bold(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("bold", defaultProvider)
  }

  italic(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("italic", defaultProvider)
  }

  underline(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("underline", defaultProvider)
  }

  lineThrough(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("lineThrough", defaultProvider)
  }

}

export class HeaderProperties {

  constructor(readonly properties: ComponentProperties) {
  }


  headerTextSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerTextSize", defaultProvider)
  }

  headerTextColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerTextColor", defaultProvider)
  }

  headerTextFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerTextFont", defaultProvider)
  }

  headerTextAlign(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerTextAlign", defaultProvider)
  }

  headerTextVerticalAlign(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerTextVerticalAlign", defaultProvider)
  }

  headerTextBold(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("headerTextBold", defaultProvider)
  }

  headerTextItalic(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("headerTextItalic", defaultProvider)
  }

  headerTextUnderline(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("headerTextUnderline", defaultProvider)
  }

  headerTextLineThrough(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("headerTextLineThrough", defaultProvider)
  }

  headerBackgroundColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBackgroundColor", defaultProvider)
  }

  headerMinHeight(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerMinHeight", defaultProvider)
  }

  // Header borders
  borderTop(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderTop", defaultProvider)
  }

  borderLeft(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderLeft", defaultProvider)
  }

  borderBottom(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderBottom", defaultProvider)
  }

  borderRight(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderRight", defaultProvider)
  }

  borderRadius(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderRadius", defaultProvider)
  }

  borderColorTop(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderColorTop", defaultProvider)
  }

  borderColorLeft(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderColorLeft", defaultProvider)
  }

  borderColorBottom(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderColorBottom", defaultProvider)
  }

  borderColorRight(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerBorderColorRight", defaultProvider)
  }

  outerShadow(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("headerOuterShadow", defaultProvider)
  }

  // Header paddings
  paddingTop(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerPaddingTop", defaultProvider)
  }

  paddingLeft(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerPaddingLeft", defaultProvider)
  }

  paddingBottom(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerPaddingBottom", defaultProvider)
  }

  paddingRight(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("headerPaddingRight", defaultProvider)
  }
}

export class LayoutsProperties {

  constructor(readonly prefix: string,
              readonly properties: ComponentProperties,
              readonly defaultProvider: DefaultPropertyProvider) {
  }


  get gapColumn(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty(this.prefix + "gapColumn", this.defaultProvider)
  };

  get gapRow(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty(this.prefix + "gapRow", this.defaultProvider)
  };

  get layout(): StringProperty {
    return this.properties.getStringProperty(this.prefix + "layout", this.defaultProvider)
  };

  get layoutAlign(): StringProperty {
    return this.properties.getStringProperty(this.prefix + "layoutAlign", this.defaultProvider)
  };

  get layoutStretch(): StringProperty {
    return this.properties.getStringProperty(this.prefix + "layoutStretch", this.defaultProvider)
  };

  get layoutWrap(): StringProperty {
    return this.properties.getStringProperty(this.prefix + "layoutWrap", this.defaultProvider)
  };

  get columns(): NumberProperty {
    return this.properties.getNumberProperty(this.prefix + "columns", this.defaultProvider)
  };

  get rows(): NumberProperty {
    return this.properties.getNumberProperty(this.prefix + "rows", this.defaultProvider)
  };

}

export class LabelProperties {

  constructor(readonly properties: ComponentProperties) {
  }

  label(defaultProvider: DefaultPropertyProvider): OptionalI18nTextProperty {
    return this.properties.getOptionalI18nTextProperty("label", defaultProvider)
  }

  labelPosition(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): StringProperty {
    return this.properties.getStringProperty("labelPosition", defaultProvider)
  }

  labelWidth(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("labelWidth", defaultProvider)
  }

  labelHeight(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("labelHeight", defaultProvider)
  }

  labelSize(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("labelSize", defaultProvider)
  }

  labelColor(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("labelColor", defaultProvider)
  }

  labelFont(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("labelFont", defaultProvider)
  }

  labelAlign(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("labelAlign", defaultProvider)
  }

  labelVerticalAlign(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("labelVerticalAlign", defaultProvider)
  }

  labelBold(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("labelBold", defaultProvider)
  }

  labelItalic(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("labelItalic", defaultProvider)
  }

  labelUnderline(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("labelUnderline", defaultProvider)
  }

  labelLineThrough(skinName: Option<string>, componentTypeName: string, componentClass: string, defaultProvider: DefaultPropertyProvider): BooleanProperty {
    return this.properties.getBooleanProperty("labelLineThrough", defaultProvider)
  }
}


export class PositionProperties {

  constructor(readonly properties: ComponentProperties,
              readonly defaultProvider: DefaultPropertyProvider) {
  }


  get desiredWidth(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("desiredWidth", this.defaultProvider)
  }

  get desiredHeight(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("desiredHeight", this.defaultProvider)
  }

  get left(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("left", this.defaultProvider)
  }

  get top(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("top", this.defaultProvider)
  }

  get right(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("right", this.defaultProvider)
  }

  get bottom(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("bottom", this.defaultProvider)
  }

  get marginTop(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("marginTop", this.defaultProvider)
  }

  get marginLeft(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("marginLeft", this.defaultProvider)
  }

  get marginBottom(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("marginBottom", this.defaultProvider)
  }

  get marginRight(): OptionalStringProperty {
    return this.properties.getOptionalStringProperty("marginRight", this.defaultProvider)
  }

  get column(): OptionalNumberProperty {
    return this.properties.getOptionalNumberProperty("column", this.defaultProvider)
  }

  get row(): OptionalNumberProperty {
    return this.properties.getOptionalNumberProperty("row", this.defaultProvider)
  }

  get columnSpan(): NumberProperty {
    return this.properties.getNumberProperty("columnSpan", this.defaultProvider)
  }

  get rowSpan(): NumberProperty {
    return this.properties.getNumberProperty("rowSpan", this.defaultProvider)
  }

}


export class FileSource {
  public fileUri: FileUri;
  public downloadUrl: string;
  public simpleUrl: string;

  constructor(public url: string) {
    try {
      this.fileUri = FileUri.parse(url);
    } catch (e) {
      this.fileUri = new FileUri(FileProtocol.Https, url);
    }
    this.downloadUrl = "";
    if (this.fileUri.isRepositoryFile()) {
      this.simpleUrl = this.fileUri.path;
    } else {
      this.simpleUrl = this.url;
    }

  }

  update() {
    if (this.simpleUrl.trim().length > 0) {
      if (this.simpleUrl.trim().charAt(0) == "/") {
        this.fileUri = new FileUri(FileProtocol.RepositoryFile, this.simpleUrl.trim());
        this.url = this.fileUri.serialize();
      } else {
        try {
          this.fileUri = FileUri.parse(this.simpleUrl.trim());
          this.url = this.fileUri.serialize();
        } catch (e) {
          this.url = "";
        }
      }
    } else {
      this.fileUri = new FileUri(FileProtocol.Https, "");
      this.url = "";
      this.downloadUrl = "";
    }

  }

  hasDownloadUrl() {
    return this.downloadUrl.trim() !== ""
  }

  isRepositoryFile() {
    return this.isSimpleUrlDefined() && this.simpleUrl.trim().charAt(0) == "/";
  }

  isSimpleUrlDefined() {
    return this.simpleUrl.trim().length > 0;
  }

}


export class AutoVariableFill {
  constructor(readonly path: VariablePath,
              readonly script: string) {
  }

  static copy(other: AutoVariableFill) {
    return new AutoVariableFill(VariablePath.copy(other.path), other.script);
  }
}


export class ScreenComponentRefFactory {

  static copy(other: ScreenComponentRef): ScreenComponentRef {
    return ScreenComponentRefFactory.copyByType(other, other.className());
  }

  static copyTyped(variable: Typed<ScreenComponentRef>): Typed<ScreenComponentRef> {
    return Typed.of(ScreenComponentRefFactory.copyByType(Typed.value(variable), Typed.className(variable)));
  }

  static copyByType(other: ScreenComponentRef, className: string): ScreenComponentRef {
    switch (className) {
      // case BarChartComponentRef.className: return BarChartComponentRef.copy(<BarChartComponentRef>other);
      case ButtonComponentRef.className: return ButtonComponentRef.copy(<ButtonComponentRef>other);
      // case DataTableComponentRef.className: return DataTableComponentRef.copy(<DataTableComponentRef>other);
      case LabelComponentRef.className: return LabelComponentRef.copy(<LabelComponentRef>other);
      case LinkComponentRef.className: return LinkComponentRef.copy(<LinkComponentRef>other);
      case ImageComponentRef.className: return ImageComponentRef.copy(<ImageComponentRef>other);
      case HtmlComponentRef.className: return HtmlComponentRef.copy(<HtmlComponentRef>other);
      case MapComponentRef.className: return MapComponentRef.copy(<MapComponentRef>other);
      case CalendarComponentRef.className: return CalendarComponentRef.copy(<CalendarComponentRef>other);
      case NumberInputComponentRef.className: return NumberInputComponentRef.copy(<NumberInputComponentRef>other);
      case SectionContainerRef.className:
        return SectionContainerRef.copy(<SectionContainerRef>other);
      case ModalContainerRef.className: return ModalContainerRef.copy(<ModalContainerRef>other);
      case RepeatableContainerRef.className: return RepeatableContainerRef.copy(<RepeatableContainerRef>other);
      case TableContainerRef.className: return TableContainerRef.copy(<TableContainerRef>other);
      case SwitchComponentRef.className: return SwitchComponentRef.copy(<SwitchComponentRef>other);
      case TabsContainerRef.className: return TabsContainerRef.copy(<TabsContainerRef>other);
      case ViewSwitcherContainerRef.className: return ViewSwitcherContainerRef.copy(<ViewSwitcherContainerRef>other);
      case TextInputComponentRef.className: return TextInputComponentRef.copy(<TextInputComponentRef>other);
      case PasswordInputComponentRef.className: return PasswordInputComponentRef.copy(<PasswordInputComponentRef>other);
      case DateInputComponentRef.className: return DateInputComponentRef.copy(<DateInputComponentRef>other);
      case DateTimeInputComponentRef.className: return DateTimeInputComponentRef.copy(<DateTimeInputComponentRef>other);
      case TimeInputComponentRef.className: return TimeInputComponentRef.copy(<TimeInputComponentRef>other);
      case DurationInputComponentRef.className: return DurationInputComponentRef.copy(<DurationInputComponentRef>other);
      case DropListComponentRef.className: return DropListComponentRef.copy(<DropListComponentRef>other);
      case RadioButtonComponentRef.className: return RadioButtonComponentRef.copy(<RadioButtonComponentRef>other);
      case MultiCheckboxComponentRef.className: return MultiCheckboxComponentRef.copy(<MultiCheckboxComponentRef>other);
      case SingleCheckboxComponentRef.className: return SingleCheckboxComponentRef.copy(<SingleCheckboxComponentRef>other);
      case MultiAttachmentInputComponentRef.className: return MultiAttachmentInputComponentRef.copy(<MultiAttachmentInputComponentRef>other);
      case SingleAttachmentInputComponentRef.className: return SingleAttachmentInputComponentRef.copy(<SingleAttachmentInputComponentRef>other);
      case PersonSelectComponentRef.className: return PersonSelectComponentRef.copy(<PersonSelectComponentRef>other);
      case WidgetComponentRef.className: return WidgetComponentRef.copy(<WidgetComponentRef>other);
      default:
        const n = <LabelComponentRef>other;
        return new LabelComponentRef(n.id, n.componentId, None(), new ComponentProperties([]));
      // throw new Error("Unsupported ViewComponentRef [" + className + "]");
    }

  }

}


export abstract class ScreenComponentRef {
  abstract id: ScreenComponentRefId; // for identification of modification event
  abstract componentId: ScreenComponentId;
  abstract applicationScreen: Option<ScreenId>;
  abstract properties: ComponentProperties;

  abstract className(): string;

  defaultPropertiesProvider: DefaultPropertyProvider|undefined = DefaultPropertyProvider.ofFallbacks("DefaultRef", DefaultPropertyProvider.ref);

  get positionProperties(): PositionProperties {
    return new PositionProperties(this.properties, <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };

  get uncovered(): BooleanProperty {
    return this.properties.getBooleanProperty("uncovered", <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };

  get visible(): BooleanProperty {
    return this.properties.getBooleanProperty("visible", <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };

  get editable(): BooleanProperty {
    return this.properties.getBooleanProperty("editable", <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };

  get preview(): OptionalBooleanProperty {
    return this.properties.getOptionalBooleanProperty("preview", <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };

  get desktopVisible(): BooleanProperty {
    return this.properties.getBooleanProperty("desktop", <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };

  get tabletVisible(): BooleanProperty {
    return this.properties.getBooleanProperty("tablet", <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };

  get mobileVisible(): BooleanProperty {
    return this.properties.getBooleanProperty("mobile", <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };

  createDefaultDefinition(id: ScreenComponentId): ScreenComponentDefinition {
    throw new Error("Not implemented createDefaultDefinition in '" + this.className() + "'");
  }
}


export abstract class InputComponentRef extends ScreenComponentRef {
  get required(): BooleanProperty {
    return this.properties.getBooleanProperty("required", <DefaultPropertyProvider>this.defaultPropertiesProvider)
  };
}

export abstract class ScreenContainerRef extends ScreenComponentRef {
}




export class ScreenComponentDefinitionFactory {
  static copy(other: ScreenComponentDefinition): ScreenComponentDefinition {
    return ScreenComponentDefinitionFactory.copyByType(other, other.className());
  }

  static copyTyped(variable: Typed<ScreenComponentDefinition>): Typed<ScreenComponentDefinition> {
    return Typed.of(ScreenComponentDefinitionFactory.copyByType(Typed.value(variable), Typed.className(variable)));
  }

  static copyByType(other: ScreenComponentDefinition, className: string): ScreenComponentDefinition {
    switch (className) {
      // case BarChartComponentDefinition.className: return BarChartComponentDefinition.copy(<BarChartComponentDefinition>other);
      case ButtonComponentDefinition.className: return ButtonComponentDefinition.copy(<ButtonComponentDefinition>other);
      // case DataTableComponentDefinition.className: return DataTableComponentDefinition.copy(<DataTableComponentDefinition>other);
      case LabelComponentDefinition.className: return LabelComponentDefinition.copy(<LabelComponentDefinition>other);
      case LinkComponentDefinition.className: return LinkComponentDefinition.copy(<LinkComponentDefinition>other);
      case ImageComponentDefinition.className: return ImageComponentDefinition.copy(<ImageComponentDefinition>other);
      case HtmlComponentDefinition.className: return HtmlComponentDefinition.copy(<HtmlComponentDefinition>other);
      case MapComponentDefinition.className: return MapComponentDefinition.copy(<MapComponentDefinition>other);
      case CalendarComponentDefinition.className: return CalendarComponentDefinition.copy(<CalendarComponentDefinition>other);
      case NumberInputComponentDefinition.className: return NumberInputComponentDefinition.copy(<NumberInputComponentDefinition>other);
      case SectionContainerDefinition.className: return SectionContainerDefinition.copy(<SectionContainerDefinition>other);
      case ModalContainerDefinition.className: return ModalContainerDefinition.copy(<ModalContainerDefinition>other);
      case RepeatableContainerDefinition.className: return RepeatableContainerDefinition.copy(<RepeatableContainerDefinition>other);
      case TableContainerDefinition.className: return TableContainerDefinition.copy(<TableContainerDefinition>other);
      case SwitchComponentDefinition.className: return SwitchComponentDefinition.copy(<SwitchComponentDefinition>other);
      case TabsContainerDefinition.className: return TabsContainerDefinition.copy(<TabsContainerDefinition>other);
      case ViewSwitcherContainerDefinition.className: return ViewSwitcherContainerDefinition.copy(<ViewSwitcherContainerDefinition>other);
      case TextInputComponentDefinition.className: return TextInputComponentDefinition.copy(<TextInputComponentDefinition>other);
      case PasswordInputComponentDefinition.className: return PasswordInputComponentDefinition.copy(<PasswordInputComponentDefinition>other);
      case DateInputComponentDefinition.className: return DateInputComponentDefinition.copy(<DateInputComponentDefinition>other);
      case DateTimeInputComponentDefinition.className: return DateTimeInputComponentDefinition.copy(<DateTimeInputComponentDefinition>other);
      case TimeInputComponentDefinition.className: return TimeInputComponentDefinition.copy(<TimeInputComponentDefinition>other);
      case DurationInputComponentDefinition.className: return DurationInputComponentDefinition.copy(<DurationInputComponentDefinition>other);
      case DropListComponentDefinition.className: return DropListComponentDefinition.copy(<DropListComponentDefinition>other);
      case RadioButtonComponentDefinition.className: return RadioButtonComponentDefinition.copy(<RadioButtonComponentDefinition>other);
      case MultiCheckboxComponentDefinition.className: return MultiCheckboxComponentDefinition.copy(<MultiCheckboxComponentDefinition>other);
      case SingleCheckboxComponentDefinition.className: return SingleCheckboxComponentDefinition.copy(<SingleCheckboxComponentDefinition>other);
      case MultiAttachmentInputComponentDefinition.className: return MultiAttachmentInputComponentDefinition.copy(<MultiAttachmentInputComponentDefinition>other);
      case SingleAttachmentInputComponentDefinition.className: return SingleAttachmentInputComponentDefinition.copy(<SingleAttachmentInputComponentDefinition>other);
      case PersonSelectComponentDefinition.className: return PersonSelectComponentDefinition.copy(<PersonSelectComponentDefinition>other);
      case WidgetComponentDefinition.className: return WidgetComponentDefinition.copy(<WidgetComponentDefinition>other);
      default:
        const n = <LabelComponentDefinition>other;
        return new LabelComponentDefinition(n.id, None(), new ComponentProperties([["text", Typed.of(I18nTextProperty.of(I18nText.en(`Not supported component '${className}'`)))]]), new ComponentActionProperties([]), new ComponentValidationRules([]));
      // throw new Error("Unsupported ViewComponentDefinition [" + className + "]");
    }

  }
}





export class ScriptValue {
  constructor(readonly script: string) {
  }

  static copy(other: ScriptValue) {
    return new ScriptValue(other.script);
  }
}

export class PropertyTryError {
  constructor(readonly timestamp: Option<LocalDateTime>,
              readonly message: string) {}

  static copy(other: PropertyTryError) {
    return new PropertyTryError(Option.copy(other.timestamp).map(LocalDateTime.copy), other.message);
  }

  toString() {
    if(this.timestamp.isDefined()) {
      return this.timestamp.get()+": "+this.message;
    } else {
      return this.message;
    }
  }
}
