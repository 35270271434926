import {
  ComponentStatus,
  ComponentViewModelUtils,
  GridSizeProperty,
  RepeatableContainerRefState,
  RepeatableContainerState,
  ScreenComponentsState,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenFormConfig,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel,
  SizeProperty,
  SizeUtils,
} from "../..";
import {__, ___, arrayMove, clearArray, I18nText, None, Option, range, required, Some, VariableId} from "@utils";
import {ScreenComponentRefId} from "@shared";
import {
  AddedSpace, CssBuilder, CssUtils,
  EntriesLayoutType, LayoutAlign, LayoutStretch,
  LayoutType, LayoutWrap,
  RepeatableContainerDefinition,
  RepeatableContainerRef, ScreenComponentRefIdInScreen, TextFont
} from "@screen-common";
import {Observable, Subject} from "rxjs";

export class RepeatableContainerEntryViewModel {


    static GRID_REM_SIZE: number = 2.5;

    css = "";

    childrenPanelDeltaHeightRem = 0;
    private minWidthCss: string = "";
    private minHeightCss: string = "";
    readonly visibleChildren: Array<ScreenComponentViewModel> = [];
    constructor(readonly contextId: VariableId,
                readonly serverModel: ScreenInstanceServerModel,
                readonly innerLayout: LayoutType,
                readonly children: Array<ScreenComponentViewModel>,
                readonly entriesLayout: EntriesLayoutType,
                readonly ref: RepeatableContainerRef,
                readonly refState: RepeatableContainerRefState) {}

    getDeltaHeight() {
      return 0;
    }

    updateCss(minWidthCss: string, minHeightCss: string) {
      this.minWidthCss = minWidthCss;
      this.minHeightCss = minHeightCss;
      this.css = "";

      const minWidthValue = minWidthCss && minWidthCss.length > 0 ? Some(minWidthCss) : None();
      const minHeightValue = minHeightCss && minHeightCss.length > 0 ? Some(minHeightCss): None();
      const childrenPanelDeltaHeightValue = Some(this.childrenPanelDeltaHeightRem + 'rem');

      CssUtils.repeatableEntryLayout(this.innerLayout, this.entriesLayout, minWidthValue, minHeightValue, childrenPanelDeltaHeightValue);
    }

    getComponentById(id: number): Option<ScreenComponentViewModel> {
      for(let i = 0; i < this.children.length; i++) {
        const child = this.children[i];
        if(child.id == id) {
          return Some(child);
        } else if(child.isContainer()) {
          const childSuccessor = (<ScreenContainerViewModel>child).getComponentById(id);
          if(childSuccessor.isDefined()) {
            return childSuccessor;
          }
        }
      }
      return None();
    }

    childDeltaHeightChanged() {
      const allDeltas: Array<AddedSpace> = ___(this.children).filter(t => t.getDefaultStaticBottom().isDefined()).map(t => {
        const bottomRem = t.getDefaultStaticBottom().get() * RepeatableContainerEntryViewModel.GRID_REM_SIZE;
        const deltaHeightRem = t.deltaHeightRem;
        return new AddedSpace(bottomRem, deltaHeightRem);
      }).filter(d => d.sizeRem > 0).value();

      this.childrenPanelDeltaHeightRem = ___(this.combineDeltas(allDeltas)).map(d => d.sizeRem).sum();

      this.children.forEach(child => {
        const componentTop = child.getDefaultStaticTop();
        if(componentTop.isDefined()) {
          const top = componentTop.get() * RepeatableContainerEntryViewModel.GRID_REM_SIZE;
          const deltasAbove = allDeltas.filter(d => d.fromRem <= top);
          const combinedDeltasAbove = this.combineDeltas(deltasAbove);
          child.changeDeltaTopRem(___(combinedDeltasAbove).map(d => d.sizeRem).sum());
        }

      });

      this.updateCss(this.minWidthCss, this.minHeightCss);
    }


    // TODO this is repeated, unify
    private combineDeltas(deltas: Array<AddedSpace>) {
      if(deltas.length > 0) {
        const sorted = __(deltas).sortBy(d => d.fromRem);

        const current = sorted[0];
        let last: AddedSpace = new AddedSpace(current.fromRem, current.sizeRem);
        const combined: Array<AddedSpace> = [last];
        sorted.forEach(current => {
          const lastTo = last.fromRem + last.sizeRem;
          if(current.fromRem >= last.fromRem && current.fromRem <= lastTo) {
            last.sizeRem = Math.max(last.sizeRem, current.fromRem + current.sizeRem - last.fromRem);
          } else {
            last = new AddedSpace(current.fromRem, current.sizeRem);
            combined.push(last);
          }
        });
        return combined;
      } else {
        return deltas;
      }

    }
  }

  export class RepeatableContainerViewModel extends ScreenContainerViewModel {

    override typeName = "Repeatable";

    readonly entries: Array<RepeatableContainerEntryViewModel>;
    public canAddEntry: boolean = false;
    public possiblyCanDeleteEntries: boolean = false;
    public possiblyCanMoveEntries: boolean = false;
    public canDeleteEntries: boolean = false;
    public canMoveEntries: boolean = false;

    public layout: string = "";
    public entriesLayoutClass: string = "";
    public entriesLayoutWrapClass: string = "";
    public override customCssClass: string = "";
    public header: string = "";
    public headerVisible: boolean = false;
    public addLabel: string = "";

    public override layoutClass: string = "";
    public layoutAlignClass: string = "";
    public layoutStretchClass: string = "";
    public layoutWrapClass: string = "";

    public css = "";
    public cssClasses = "";
    public entriesCss = "";
    public childrenPlainCss = "";
    public childrenPlainCssClasses = "";

    public headerCss: string = "";
    public headerCssClasses: string = "";

    public clickableEntry: boolean;

    private readonly reinitDragSubject: Subject<void> = new Subject<void>();
    readonly reinitDrag: Observable<void> = this.reinitDragSubject.asObservable();

    constructor(override readonly shared: ScreenSharedViewModel,
                override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                override readonly definition: RepeatableContainerDefinition,
                override readonly componentScreenId: string,
                override readonly ref: RepeatableContainerRef,
                override readonly refScreenId: string,
                override readonly componentState: RepeatableContainerState,
                override readonly refState: RepeatableContainerRefState,
                readonly context: VariableId,
                readonly serverModel: ScreenInstanceServerModel,
                readonly componentsState: ScreenComponentsState,
                readonly createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {
      super(parent, componentState, refState, definition, shared);
      this.clickableEntry = definition.actionProperties.getActions("onEntryClick").length > 0;
      this.entries = [];
      this.update();
    }

    onEntryClick(entry: RepeatableContainerEntryViewModel) {
      if(this.definition.actionProperties.getActions("onEntryClick").length > 0) {
        this.serverModel.executeEntryAction(this.componentRefPath(), entry.contextId, "onEntryClick")
      }
    }

    getAllChildren(): Array<ScreenComponentViewModel> {
      return __(this.entries).flatMap(e => e.children);
    }

    override componentName(): string {
      if(this.headerVisible && this.header.trim().length > 0) {
        return this.header;
      } else {
        return super.componentName();
      }
    }

    getLayoutForComponent(componentRefId: ScreenComponentRefId): LayoutType {
      return LayoutType.byName(this.definition.layoutsProperties.layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));
    }

    updateContainer(deep: boolean): void {
      // must be after entries initialization

      if (!this.uncoveredAndVisible || this.status != ComponentStatus.initialized) {
        clearArray(this.entries);
      } else {

        const headerCssBuilder = CssBuilder.create();

        const layout = LayoutType.byName(this.definition.layoutsProperties.layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));
        const unit = ScreenFormConfig.panelUnitRemSize;

        const cssBuilder = CssBuilder.create();
        const childrenPlainCssBuilder = CssBuilder.create();
        const entriesCssBuilder = CssBuilder.create();

        const textSize = this.definition.contentTextSize(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
        const textColor = this.definition.contentTextColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextColor).valueOrDefault(None());
        const textFont = this.definition.contentTextFont(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.contentTextFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));

        CssUtils.fontCss(childrenPlainCssBuilder, textFont, textSize, false, false, false, false, textColor);
        ComponentViewModelUtils.toPaddingsCssAsMargins(childrenPlainCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);

        const contentMinHeight = this.definition.sizeProperties.contentMinHeight.currentValue(() => this.componentState.boxState.contentMinHeight).valueOrDefault(None());
        const contentMinWidth = this.definition.sizeProperties.contentMinWidth.currentValue(() => this.componentState.boxState.contentMinWidth).valueOrDefault(None());

        const headerOption = this.definition.header.currentValue(() => this.componentState.header).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
        this.header = headerOption.getOrElse("");
        this.headerVisible = headerOption.isDefined();

        const headerHeightValue = this.definition.headerProperties.headerMinHeight(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider)
          .currentValue(() => this.componentState.headerState.headerMinHeight).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));

        // TODO ?
        const childrenPanelDeltaHeightRem = 0;

        CssUtils.childrenPlainMinHeight(childrenPlainCssBuilder,
          contentMinHeight.map(v => SizeProperty.sizeToCss(unit, v)),
          headerHeightValue,
          this.headerVisible,
          Some(childrenPanelDeltaHeightRem + "rem")
        );

        CssUtils.childrenPlainMinWidth(childrenPlainCssBuilder,
          contentMinWidth.orElse(Some("0cm")).map(v => SizeProperty.sizeToCss(unit, v))
        );

        if (layout.isGrid()) {

          const rows = this.definition.layoutsProperties.rows.currentValue(() => this.componentState.layoutsState.rows).valueOrDefault(2);
          const columns = this.definition.layoutsProperties.columns.currentValue(() => this.componentState.layoutsState.columns).valueOrDefault(2);

          const columnsCss =  range(columns).map(r =>  GridSizeProperty.sizeToCss(unit, this.definition.properties.getStringProperty("column|"+r+"|width", RepeatableContainerDefinition.DEFAULT_PROPERTIES).currentValue(() => this.componentState.properties.stringProperty("column|"+r+"|width")).valueOrDefault("auto")));
          const rowsCss =  range(rows).map(r =>  GridSizeProperty.sizeToCss(unit, this.definition.properties.getStringProperty("row|"+r+"|height", RepeatableContainerDefinition.DEFAULT_PROPERTIES).currentValue(() => this.componentState.properties.stringProperty("row|"+r+"|height")).valueOrDefault("auto")));

          childrenPlainCssBuilder.addProperty("grid-template-columns: " + columnsCss.join(" ") + ";");
          childrenPlainCssBuilder.addProperty("grid-template-rows: " + rowsCss.join(" ") + ";");
        }

        if(!layout.isStatic()) {
          const gapColumn = this.definition.layoutsProperties.gapColumn.currentValue(() => this.componentState.layoutsState.gapColumn).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
          const gapRow = this.definition.layoutsProperties.gapRow.currentValue(() => this.componentState.layoutsState.gapRow).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
          CssUtils.gaps(childrenPlainCssBuilder, gapRow, gapColumn);
        }

        const backgroundColorValue = this.definition.backgroundsProperties.backgroundColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.backgroundsState.backgroundColor).valueOrDefault(None());
        CssUtils.backgroundColor(cssBuilder, backgroundColorValue);

        ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

        ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

        this.layoutClass = layout.name;

        const layoutAlign = LayoutAlign.byName(this.definition.layoutsProperties.layoutAlign.currentValue(() => this.componentState.layoutsState.layoutAlign).valueOrDefault(LayoutAlign.DEFAULT.name));
        this.layoutAlignClass = layoutAlign.name;
        const layoutStretch = LayoutStretch.byName(this.definition.layoutsProperties.layoutStretch.currentValue(() => this.componentState.layoutsState.layoutStretch).valueOrDefault(LayoutStretch.DEFAULT.name));
        this.layoutStretchClass = layoutStretch.name;
        const layoutWrap = LayoutWrap.byName(this.definition.layoutsProperties.layoutWrap.currentValue(() => this.componentState.layoutsState.layoutWrap).valueOrDefault(LayoutWrap.wrap.name));
        this.layoutWrapClass = layoutWrap.name;


        const gapColumn = this.definition.entriesGapColumn.currentValue(() => this.componentState.entriesGapColumn).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
        const gapRow = this.definition.entriesGapRow.currentValue(() => this.componentState.entriesGapRow).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));;
        CssUtils.gaps(entriesCssBuilder, gapRow, gapColumn);

        const maxHeight = this.definition.sizeProperties.maxHeight.currentValue(() => this.componentState.boxState.maxHeight).valueOrDefault(None());
        const maxWidth = this.definition.sizeProperties.maxWidth.currentValue(() => this.componentState.boxState.maxWidth).valueOrDefault(None());

        // if(maxHeight.isDefined()) {
        //   this.entriesCss += `max-height: calc(${SizeProperty.sizeToCss(unit, maxHeight.get())} - ${headerHeight});`;
        // }
        // if(maxWidth.isDefined()) {
        //   this.entriesCss += `max-width: ${SizeProperty.sizeToCss(unit, maxWidth.get())};`;
        // }

        CssUtils.entriesMaxSizes(entriesCssBuilder,
          maxWidth.map(v => SizeProperty.sizeToCss(unit, v)),
          maxHeight.map(v => SizeProperty.sizeToCss(unit, v)),
          headerHeightValue,
          this.headerVisible
        );

        const entriesLayout = EntriesLayoutType.byName(this.definition.entriesLayout.currentValue(() => this.componentState.entriesLayout).valueOrDefault(EntriesLayoutType.horizontal.name));
        this.entriesLayoutClass = entriesLayout.name;
        const entriesLayoutWrap = LayoutWrap.byName(this.definition.entriesLayoutWrap.currentValue(() => this.componentState.entriesLayoutWrap).valueOrDefault(LayoutWrap.DEFAULT.name));
        this.entriesLayoutWrapClass = entriesLayoutWrap.name;

        const innerLayout = LayoutType.byName(this.definition.layoutsProperties.layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));

        if(this.componentState.innerContext.isDefined() && !this.hasVisibilityError) {

          const existingEntries = this.entries.slice();
          clearArray(this.entries);

          const modelProperty = this.definition.model.currentValue(() => this.componentState.properties.modelProperty("model"));
          if(modelProperty.isSuccess()) {
            this.componentState.innerContext.getOrError("No inner context available").entries.forEach(contextEntry => {

              const existing = __(existingEntries).find(e => e.contextId.id == contextEntry.id);
              if (existing.isDefined()) {
                this.entries.push(existing.get());
              } else {
                const children = this.definition.children.map((childRefId, index) => {
                  return this.createViewModel(this, ScreenComponentRefIdInScreen.of(this.componentScreenId, childRefId), Some(contextEntry));
                });
                this.entries.push(new RepeatableContainerEntryViewModel(contextEntry, this.serverModel, innerLayout, children, entriesLayout, this.ref, this.refState));
              }
            });
          }

        } else {
          clearArray(this.entries);
        }


        this.entries.forEach(entry => {

          clearArray(entry.visibleChildren);
          entry.visibleChildren.push(... entry.children.filter(c => c.visible));

          entry.updateCss(
            SizeProperty.sizeToCss(unit, contentMinWidth.getOrElse("0cm")),
            SizeProperty.sizeToCss(unit, contentMinHeight.getOrElse("0cm"))
          )
        });

        this.updateAddRemoveAllowed();



        this.layout = layout.name;

        this.addLabel = this.definition.addLabel.currentValue(() => this.componentState.properties.i18nTextProperty("addLabel")).valueOrDefault(I18nText.empty()).getCurrentWithFallback();


        ComponentViewModelUtils.toHeaderCss(headerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.headerProperties, this.componentState.headerState);

        this.css = cssBuilder.toCss();
        this.cssClasses = cssBuilder.toCssClasses();

        this.entriesCss = cssBuilder.toCss();

        this.childrenPlainCss = childrenPlainCssBuilder.toCss();
        this.childrenPlainCssClasses = childrenPlainCssBuilder.toCssClasses();

        this.headerCss = headerCssBuilder.toCss();
        this.headerCssClasses = headerCssBuilder.toCssClasses();

        this.reinitDragSubject.next();

      }


      super.updatePosition();
      if(this.parent instanceof ScreenContainerViewModel) {
        this.parent.updateTop();
      }
    }


    override updateEditableAndPreview() {
      super.updateEditableAndPreview();
      this.updateAddRemoveAllowed();
    }

    private updateAddRemoveAllowed() {
      const lengthEditable = this.ref.lengthEditable.currentValue(() => this.refState.lengthEditable).valueOrDefault(false);
      const sortable = this.ref.sortable.currentValue(() => this.refState.sortable).valueOrDefault(false);
      const minEntries = this.definition.minEntries.currentValue(() => this.componentState.minEntries).valueOrDefault(None()).getOrElse(0);
      const maxEntries = this.definition.maxEntries.currentValue(() => this.componentState.maxEntries).valueOrDefault(None()).getOrElse(1000000);
      this.canAddEntry = !this.disabled && lengthEditable && this.entries.length < maxEntries;
      this.possiblyCanDeleteEntries = !this.disabled && lengthEditable;
      this.possiblyCanMoveEntries = !this.disabled && sortable;
      this.canDeleteEntries = this.possiblyCanDeleteEntries && this.entries.length > minEntries;
      this.canMoveEntries = this.possiblyCanMoveEntries && this.entries.length > 1;

    }


    addEntry(): void {
      this.serverModel.addRepeatableContextEntry(this.entries.length, this.componentRefPath(), RepeatableContainerDefinition.ON_CHANGE, () => {

      });
    }

    deleteEntry(entry: RepeatableContainerEntryViewModel): void {
      this.serverModel.deleteRepeatableContextEntry(this.componentRefPath(), entry.contextId, this.entries.indexOf(entry), RepeatableContainerDefinition.ON_CHANGE, () => {

      });
    }

    moveEntry(fromIndex: number, toIndex: number): void {
      const entry = required(this.entries[fromIndex], "entry");
      arrayMove(this.entries, fromIndex, toIndex);
      this.serverModel.moveEntry(this.componentRefPath(), entry.contextId, fromIndex, toIndex, RepeatableContainerDefinition.ON_CHANGE, () => {

      });
    }


    static create(shared: ScreenSharedViewModel,
                  parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                  context: VariableId,
                  definition: RepeatableContainerDefinition,
                  componentScreenId: string,
                  ref: RepeatableContainerRef,
                  refScreenId: string,
                  componentState: RepeatableContainerState,
                  refState: RepeatableContainerRefState,
                  serverModel: ScreenInstanceServerModel,
                  componentsState: ScreenComponentsState,
                  createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {

      return new RepeatableContainerViewModel(shared, parent, definition, componentScreenId, ref, refScreenId, componentState, refState, context, serverModel, componentsState,
        createViewModel);

    }

    childDeltaHeightChanged(): void {

      this.entries.forEach(entry => {
        entry.childDeltaHeightChanged();
      });
    }




    getComponentById(id: number): Option<ScreenComponentViewModel> {
      for(let i = 0; i < this.entries.length; i++) {
        const fromEntry = this.entries[i].getComponentById(id);
        if (fromEntry.isDefined()) {
          return fromEntry;
        }

      }
      return None();
    }


  }
