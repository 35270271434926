import {
  __,
  ___,
  AnyInstanceId,
  AnyPersonIdHelper,
  ApplicationId,
  None,
  Option,
  PersonId,
  RemoteOrganizationIdentifier,
  Constants,
  Some
} from "@utils";
import {
  GlobalEventBus,
  InputSuggestionProvider,
  ServerEventsService,
  SessionEventBus,
  SessionServiceProvider
} from "@shared";
import {TaskIdentifier, TaskModelSummary, TasksChanged} from "./TaskModel";
import {FlowsSharedService, ProcessNodeId, RemoteOrganizationsSharedService, TasksStatusManager} from "..";
import {Injectable} from "@angular/core";
import {isStandalonePage} from "../../utils/Standalone";

export class OrganizationSubscriptionsInfo {
  public lastTasksChangeSubscriptionCode: Option<string> = None();

  constructor(readonly organizationIdentifier: Option<RemoteOrganizationIdentifier>) {}
}

export class TasksLabelSuggestionProvider implements InputSuggestionProvider {
  constructor(readonly tasksService: TaskListManagerService) {}

  suggestions(): Promise<Array<string>> {

    return this.tasksService.getTasksPromise().then(tasks => {
      const suggestions = ___(tasks)
        .flatMap(t => t.labels)
        .unique()
        .sortByAlphanumeric(l => l).value();

      return suggestions;
    });
  }

}


@Injectable({
  providedIn: "root",
})
export class TaskListManagerService {

  tasks?: Promise<Array<TaskModelSummary>>;

  public subscriptions: Array<number> = [];

  local = new OrganizationSubscriptionsInfo(None());
  remotes: Array<OrganizationSubscriptionsInfo> = [];

  myTasksCount = 0;
  myNewTasksCount = 0;

  readonly labelsSuggestionProvider = new TasksLabelSuggestionProvider(this);
  private personId?: PersonId;

  constructor(readonly serverEventsService: ServerEventsService,
              readonly flowsSharedService: FlowsSharedService,
              readonly sessionServiceProvider: SessionServiceProvider,
              readonly globalEventBus: GlobalEventBus,
              readonly sessionEventBus: SessionEventBus,
              readonly remoteOrganizationsSharedService: RemoteOrganizationsSharedService,
              readonly tasksStatusManager: TasksStatusManager) {


    if(!isStandalonePage()) {

      sessionServiceProvider.getSessionService(sessionService => {
        if (sessionService.isLoggedIn() && sessionService.organizationSessionInfo) {
          this.destroy();
          this.clear();
          this.personId = sessionService.organizationSessionInfo.getPersonId();
          this.init();
        }
      });

      sessionEventBus.on(sessionEventBus.userLoggedOut, (sessionId: string) => {
        this.destroy();
        this.clear();
      });

      sessionEventBus.on(sessionEventBus.userLoggedIn, () => {
        sessionServiceProvider.getOrganizationSessionInfo(organizationSessionInfo => {
          if (!this.personId || !this.personId.equals(organizationSessionInfo.getPersonId())) {
            this.destroy();
            this.clear();
            this.personId = organizationSessionInfo.getPersonId();
            this.init();
          }
        });
      });

    }
  }

  destroy() {

    this.subscriptions.forEach(s => this.serverEventsService.serverEventBus.unsubscribe(s));
    this.subscriptions = [];

    this.unsubscribeFromTasksChange(None());
    this.remotes.forEach(remote => {
      this.unsubscribeFromTasksChange(remote.organizationIdentifier);
    });


  }

  unsubscribeFromTasksChange(remoteOrganization: Option<RemoteOrganizationIdentifier>) {

    const info = this.getOrganizationSubscriptionInfo(remoteOrganization);

    const code = info.lastTasksChangeSubscriptionCode;
    if(code.isDefined()) {
      this.serverEventsService.unsubscribe(code.get());
      info.lastTasksChangeSubscriptionCode = None();
    }
  }

  subscribeForTasksChange(remoteOrganization: Option<RemoteOrganizationIdentifier>) {
    this.unsubscribeFromTasksChange(remoteOrganization);
    const info = this.getOrganizationSubscriptionInfo(remoteOrganization);
    this.serverEventsService.subscribeForTasksChange(remoteOrganization, (subscriptionCode: string) => {
      info.lastTasksChangeSubscriptionCode = Some(subscriptionCode);
    }, () => {
      this.loadTasks();
      this.subscribeForTasksChange(remoteOrganization);
    });
  }

  getTasks(onSuccess: (tasks: Array<TaskModelSummary>) => void) {
    if(this.tasks) {
      this.tasks.then(tasks => onSuccess(tasks));
    }
  }

  getTask(taskId: TaskIdentifier): Promise<Option<TaskModelSummary>> {
    if(this.tasks) {
      return this.tasks.then(tasks => {
        return __(tasks).find(t => taskId.equals(t.toTaskIdentifier()));
      });
    } else {
      throw new Error("Tasks not loaded yet");
    }
  }

  getTasksPromise(): Promise<Array<TaskModelSummary>> {
    if(this.tasks) {
      return this.tasks;
    } else {
      throw new Error("Tasks not loaded yet");
    }
  }

  async getTasksForApplications(applicationIds: ApplicationId[]): Promise<Array<TaskModelSummary>> {
    if(!this.tasks) {
      throw new Error("Tasks not loaded yet");
    }

    if(applicationIds.length === 0) {
      return this.tasks;
    }

    const applicationIdsSet = new Set(applicationIds.map(id => id.id));
    return (await this.tasks)
      .filter(task => applicationIdsSet.has(task.applicationId.getOrElse(Constants.globalApplicationId).id));
  }

  findNotStartedTaskForTask(): Promise<Array<TaskModelSummary>> {
    return this.findNotStartedTasksForInstance(Constants.taskProcessInstanceId, Constants.taskProcessStartNodeId);
  }

  findNotStartedTasksForInstance(instanceId: AnyInstanceId, startNodeId: ProcessNodeId): Promise<Array<TaskModelSummary>> {
    if(this.tasks) {
      return this.tasks.then(tasks => {
        return tasks.filter(t =>
          !t.working && t.flowCode.isEmpty() && t.completed.isEmpty() &&
          t.instanceId.id === instanceId.id && t.nodeId === startNodeId);
      });
    } else {
      throw new Error("Tasks not loaded yet");
    }
  }

  findNotStartedTasksForInstances(instancesStartNodes: Array<{
    instanceId: AnyInstanceId,
    nodeId: ProcessNodeId
  }>): Promise<Array<TaskModelSummary>> {
    if(this.tasks) {

      const startNodes = __(instancesStartNodes);

      return this.tasks.then(tasks => {
        return tasks.filter(t => startNodes.exists(n =>
          !t.working && t.flowCode.isEmpty() && t.completed.isEmpty() &&
          t.instanceId.id === n.instanceId.id && t.nodeId === n.nodeId));
      });
    } else {
      throw new Error("Tasks not loaded yet");
    }
  }

  private loadTasks() {
    this.tasks = new Promise((resolve, reject) => {


      let localTasks: Option<Array<TaskModelSummary>> = None();
      let organizationsCount: Option<number> = None();
      let remoteTasks: Array<Array<TaskModelSummary>> = [];

      let that = this;

      function onLoaded(remoteOrganization: Option<RemoteOrganizationIdentifier>) {
        if(localTasks.isDefined() && organizationsCount.isDefined() && remoteTasks.length == organizationsCount.get()) {
          const tasks = localTasks.get().concat(__(remoteTasks).flatMap(t => t));
          resolve(tasks);
          that.updateCounts();
          that.tasksStatusManager.setTasks(tasks);
          that.globalEventBus.tasksListLoaded(tasks);
        }
        that.subscribeForTasksChange(remoteOrganization);
      }

      this.remoteOrganizationsSharedService.getRemoteOrganizationsForMe(remoteOrganizations => {

        this.flowsSharedService.getTasksForMe((tasks: Array<TaskModelSummary>) => {
          localTasks = Some(tasks);
          onLoaded(None());
        });

        organizationsCount = Some(remoteOrganizations.length);
        remoteOrganizations.forEach(remoteOrganization => {
          this.flowsSharedService.getRemoteTasksForMe(remoteOrganization, (tasks: Array<TaskModelSummary>) => {
            remoteTasks.push(tasks);
            onLoaded(Some(remoteOrganization));
          });
        });
      });

    });
  }

  private updateCounts() {

    this.sessionServiceProvider.getOrganizationSessionInfo(organizationSessionInfo => {

      if(this.tasks) {
        this.tasks.then(tasks => {
          this.myTasksCount = __(tasks).count(t => t.completed.isEmpty() && __(t.personsAssignedUnwrapped()).exists(p => AnyPersonIdHelper.equals(p, PersonId.of(organizationSessionInfo.personId))));
          this.myNewTasksCount = __(tasks).count(t => !t.seen && t.completed.isEmpty() && __(t.personsAssignedUnwrapped()).exists(p => AnyPersonIdHelper.equals(p, PersonId.of(organizationSessionInfo.personId))));
        });
      }

    });

  }

  private clearCounts() {
    this.myTasksCount = 0;
    this.myNewTasksCount = 0;
  }

  private clear() {
    this.tasks = undefined;
    this.personId = undefined;
    this.clearCounts();
  }

  private init() {

    this.loadTasks();

    this.subscriptions.push(this.serverEventsService.serverEventBus.on(this.serverEventsService.serverEventBus.tasksUpdated, (tasksChanged: TasksChanged) => {

      if(this.tasks) {
        this.tasks.then(tasks => {
          // update tasks

          // filter out removed tasks
          let newTasks = tasks.filter(t => !tasksChanged.containsTask(t.flowIdUnwrapped(), t.nodeId));

          // add new tasks
          newTasks = newTasks.concat(tasksChanged.added).concat(tasksChanged.updated);
          this.tasks = Promise.resolve(newTasks);

          this.updateCounts();

          // update tasks statuses
          this.tasksStatusManager.setTasks(newTasks);

          this.globalEventBus.tasksListUpdated(tasksChanged.added, tasksChanged.updated, tasksChanged.removed);

        });
      }

    }));


    this.subscriptions.push(this.serverEventsService.serverEventBus.on(this.serverEventsService.serverEventBus.myRolesUpdated, () => {
        // refresh tasks & subscriptions immediately
        this.loadTasks();
      }
    ));
  }

  private getOrganizationSubscriptionInfo(remote: Option<RemoteOrganizationIdentifier>): OrganizationSubscriptionsInfo {
    if(remote.isDefined()) {
      const existing = __(this.remotes).find(r => r.organizationIdentifier.exists(i => i.id == remote.get().id));
      if(existing.isDefined()) {
        return existing.get();
      } else {
        const created = new OrganizationSubscriptionsInfo(remote);
        this.remotes.push(created);
        return created;
      }
    } else {
      return this.local;
    }
  }

}
