import {Option} from "@utils";

export class ProcessedDocument {
  constructor(readonly pages: Array<ProcessedPage>) {
  }

  static copy(other: ProcessedDocument) {
    return new ProcessedDocument(other.pages.map(ProcessedPage.copy));
  }
}

export class ProcessedPage {
  constructor(readonly lines: Array<ProcessedLine>,
              readonly words: Array<ReadWord>,
              readonly bbox: BoundingBox) {
  }

  static copy(other: ProcessedPage) {
    return new ProcessedPage(other.lines.map(ProcessedLine.copy), other.words.map(ReadWord.copy) ,BoundingBox.copy(other.bbox));
  }


}

export class ProcessedLine {
  constructor(readonly text: string,
              readonly bbox: BoundingBox,
              readonly words: Array<ReadWord>) {}

  static copy(other: ProcessedLine) {
    return new ProcessedLine(other.text, BoundingBox.copy(other.bbox), other.words.map(ReadWord.copy));
  }
}

export class ReadDocument {
  constructor(readonly pages: Array<ReadPage>) {
  }

  static copy(other: ReadDocument) {
    return new ReadDocument(other.pages.map(ReadPage.copy));
  }
}

export class ReadPage {
  constructor(readonly words: Array<ReadWord>,
              readonly cuttingLines: Array<CuttingLine>,
              readonly bbox: BoundingBox) {
  }

  static copy(other: ReadPage) {
    return new ReadPage(other.words.map(ReadWord.copy), other.cuttingLines.map(CuttingLine.copy), BoundingBox.copy(other.bbox));
  }
}

export class CuttingLine {
  constructor(readonly x0: number,
              readonly y0: number,
              readonly x1: number,
              readonly y1: number) {
  }

  static copy(other: CuttingLine) {
    return new CuttingLine(other.x0, other.y0, other.x1, other.y1);
  }
}

export class ReadWord {
  constructor(readonly text: string,
              readonly bbox: BoundingBox,
              readonly fontSize: Option<number>) {
  }

  static copy(other: ReadWord) {
    return new ReadWord(other.text, BoundingBox.copy(other.bbox), Option.copy(other.fontSize));
  }
}

export class BoundingBox {
  constructor(readonly x0: number,
              readonly y0: number,
              readonly x1: number,
              readonly y1: number) {}

  static copy(other: BoundingBox) {
    return new BoundingBox(other.x0, other.y0, other.x1, other.y1);
  }

  height() {
    return this.y1 - this.y0;
  }

  contains(x: number, y: number) {
    return x >= this.x0 && x <=this.x1 && y >= this.y0 && y <= this.y1;
  }

  equals(other: BoundingBox) {
    return this.x0 === other.x0 && this.y0 === other.y0 && this.x1 === other.x1 && this.y1 === other.y1;
  }
}

