import {
  AnyFlowId,
  AnyPersonId, ApplicationIdentifier,
  EventBus,
  FileUri,
  FormElementId,
  FormElementRefId,
  LocalDateTime,
  ObjectId,
  Option
} from "@utils";
import {FormSectionId, FormSectionRefId, ViewableFile} from "@shared"
import {
  ApplicationIcon,
  BusinessVariable,
  ContextPath,
  ContextVariable,
  ObjectVariable, ProcessNodeId,
  TaskIdentifier
} from "@shared-model";
import {InputFieldViewModel} from "./task-form.view-model";
import {TaskModel} from "./model/ProcessFlow";
import {SectionValidationError, VariableValidationError} from "./model/ProcessFlowValidationErrors";


export class TaskEventBus extends EventBus {

  static counter = 0;
  id = TaskEventBus.counter++;


  taskStarted(taskId: TaskIdentifier) {}
  taskStopped(taskId: TaskIdentifier) {}

  taskLoaded(task: TaskModel, applicationName: string, applicationIdentifier: ApplicationIdentifier, applicationIcon: ApplicationIcon, openForm: boolean) {}

  taskAutoUpdated(flowId: AnyFlowId,
                  modifiedVariables: ContextVariable<BusinessVariable>[],
                  variablesCleared: ContextPath[],
                  variablesEvaluated: ContextPath[]): void {}

  variablesWillBeEvaluated(flowId: AnyFlowId, variables: ContextPath[]) {}

  sectionVisibilityChanged(flowId: AnyFlowId, shownSections: FormSectionId[], hiddenSections: FormSectionId[]) {}

  fieldStateUpdated(flowId: AnyFlowId, elementId: FormElementId, contextObjectId: Option<ObjectId>, propertyName: string, value: Option<BusinessVariable>) {}

  taskUpdated(started: Option<LocalDateTime>) {}

  taskRequiredFieldsFilled(check: boolean) {}

  sectionLengthAutoUpdated(flowId: AnyFlowId, sectionVariableName: string, removedRows:Array<ObjectId>, addedRows:Array<ObjectVariable>) {}

  formElementHeightChanged(): void {}

  formElementsPositionChanged(): void {}

  actionButtonClicked(sectionId: FormSectionId, elementId: FormElementId, contextObjectId: Option<ObjectId>): void {}

  submitFormValidationFailed(): void {}

  taskRedirected(flowId: AnyFlowId, nodeId: ProcessNodeId): void {}

  activityStateChanged(flowId: AnyFlowId, nodeId: ProcessNodeId, activityId: number, checked: boolean) {}

  commentAdded(flowId: AnyFlowId, personId: AnyPersonId, commentId: number, extraRecipients: Array<AnyPersonId>, attachments: Array<FileUri>, commentText: string) {}

  // flowIdChanged(oldId: AnyFlowId, newId: AnyFlowId) {}

  newFlowMaterialized(flowId: AnyFlowId, nodeId: ProcessNodeId) {}

  emailViewRequested(attachmentInputField: InputFieldViewModel, emailUri: FileUri) {}

  fileViewRequested(attachmentInputField: InputFieldViewModel, files: Array<ViewableFile>, index: number) {}

  fileListChanged(attachmentInputField: InputFieldViewModel, files: Array<ViewableFile>) {}

  taskUpdateFailed(labels: Option<Array<string>>, personsAssigned: Option<Array<AnyPersonId>>, started: Option<Option<LocalDateTime>>) {}


  inputFieldValidationUpdated(flowId: AnyFlowId, nodeId: ProcessNodeId, elementRefId: FormElementRefId, contextId: Option<ObjectId>, error: VariableValidationError) {}

  formRepeatableSectionValidationUpdated(flowId: AnyFlowId, nodeId: ProcessNodeId, sectionRefId: FormSectionRefId, error: SectionValidationError) {}

  activityValidationFailed() {}

  printTaskForm() {}

  importanceChanged(flowId: AnyFlowId, importance: number) {}

  urgencyChanged(flowId: AnyFlowId, urgency: number) {}

  colorChanged(flowId: AnyFlowId, color: Option<string>) {}

  fileViewerCloseRequested() {}

  reinitNeeded() {}
}

