import {
  ComponentStatus,
  ComponentViewModelUtils,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenFormConfig,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel,
  SizeProperty,
  SizeUtils,
  ViewSwitcherContainerRefState,
  ViewSwitcherContainerState,
  TabState, ModelToChange,
} from "../..";
import {
  __,
  ___,
  clearArray,
  I18nText,
  None,
  nullIfUndefined,
  Option,
  overwriteArray, required,
  Some,
  toastr,
  VariableId
} from "@utils";
import {NavigationService, ScreenComponentRefId} from "@shared";
import {
  CssBuilder, CssUtils, ModalContainerDefinition,
  ViewSwitcherContainerDefinition, ViewSwitcherContainerRef, ViewSwitcherViewDefinition
} from "@screen-common";
import {
  LayoutAlign,
  LayoutStretch,
  LayoutType,
  LayoutWrap,
  ScreenComponentRefIdInScreen,
  TextFont
} from "@screen-common";
import {ViewSwitcherViewState} from "./ViewSwitcherContainerState";
import {BooleanVariable, BusinessVariable, StringVariable} from "@shared-model";
import {Subject} from "rxjs";

export class ViewSwitcherViewViewModel {

  public header: string = "";
  public visible: boolean = false;
  public editable: boolean = false;
  public uncovered: boolean = false;
  public enabled: boolean = false;

  public uncoveredAndVisible: boolean = false;

  public layoutClass: string = "";
  public layoutAlignClass: string = "";
  public layoutStretchClass: string = "";
  public layoutWrapClass: string = "";

  public childrenPlainCss = "";
  public childrenPlainCssClasses = "";


  public childrenPanelDeltaHeightRem = 0;

  readonly children: Array<ScreenComponentViewModel> = [];

  active: boolean = false;

  constructor(readonly parent: ViewSwitcherContainerViewModel,
              readonly definition: ViewSwitcherViewDefinition,
              readonly componentState: ViewSwitcherViewState,
              readonly prefix: string) {}

  update(deep: boolean, activeViewIdentifier: string) {

    const childrenPlainCssBuilder = CssBuilder.create();

    const unit = ScreenFormConfig.panelUnitRemSize;

    const layout = LayoutType.byName(this.definition.layoutsProperties(this.parent.definition.properties).layout.currentValue(() => this.componentState.layoutsState.layout).valueOrDefault(LayoutType.horizontal.name));
    const layoutAlign = LayoutAlign.byName(this.definition.layoutsProperties(this.parent.definition.properties).layoutAlign.currentValue(() => this.componentState.layoutsState.layoutAlign).valueOrDefault(LayoutAlign.DEFAULT.name));
    const layoutStretch = LayoutStretch.byName(this.definition.layoutsProperties(this.parent.definition.properties).layoutStretch.currentValue(() => this.componentState.layoutsState.layoutStretch).valueOrDefault(LayoutStretch.DEFAULT.name));
    const layoutWrap = LayoutWrap.byName(this.definition.layoutsProperties(this.parent.definition.properties).layoutWrap.currentValue(() => this.componentState.layoutsState.layoutWrap).valueOrDefault(LayoutWrap.wrap.name));
    this.layoutClass = layout.name;
    this.layoutAlignClass = layoutAlign.name;
    this.layoutStretchClass = layoutStretch.name;
    this.layoutWrapClass = layoutWrap.name;

    const identifier = this.definition.getIdentifier(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.stringProperty("view|"+this.definition.id +"|identifier")).valueOrDefault("");
    this.active = activeViewIdentifier === identifier;

    if(this.active && !this.parent.hasVisibilityError) {
      if (this.parent.definition.model.enabled) {
        const modelProperty = this.parent.definition.model.currentValue(() => this.parent.componentState.properties.optionalModelProperty("model"));
        if (this.parent.componentState.innerContext.isDefined() && modelProperty.isSuccess()) {
          this.updateChildren(this.parent.componentState.innerContext.getOrError("Should be defined"));
        } else {
          clearArray(this.children);
        }
      } else {
        this.updateChildren(this.parent.context);
      }
    } else {
      clearArray(this.children);
    }

    this.children.forEach(child => child.updateComponent(deep));

    this.header = this.definition.getHeader(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.i18nTextProperty("view|"+this.definition.id +"|header")).valueOrDefault(I18nText.empty()).getCurrentWithFallback();
    this.visible = this.definition.getVisible(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.booleanProperty("view|"+this.definition.id +"|visible")).valueOrDefault(false);
    this.editable = this.definition.getEditable(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.booleanProperty("view|"+this.definition.id +"|editable")).valueOrDefault(true);
    this.uncovered = this.definition.getUncovered(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.booleanProperty("view|"+this.definition.id +"|uncovered")).valueOrDefault(true);
    this.enabled = this.definition.getEnabled(this.parent.definition.properties).currentValue(() => this.parent.componentState.properties.booleanProperty("view|"+this.definition.id +"|enabled")).valueOrDefault(true);

    this.uncoveredAndVisible = this.uncovered && this.visible;

    const textSize = this.definition.contentTextSize(this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider, this.parent.definition.properties).currentValue(() => this.componentState.contentTextSize).valueOrDefault(None()).map(v => SizeUtils.sizeToCss(v));
    const textColor = this.definition.contentTextColor(this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider, this.parent.definition.properties).currentValue(() => this.componentState.contentTextColor).valueOrDefault(None());
    const textFont = this.definition.contentTextFont(this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider, this.parent.definition.properties).currentValue(() => this.componentState.contentTextFont).valueOrDefault(None()).map(f => TextFont.getFontCss(f));

    CssUtils.fontCss(childrenPlainCssBuilder, textFont, textSize, false, false, false, false, textColor);

    const contentMinHeight = this.parent.definition.sizeProperties.contentMinHeight.currentValue(() => this.parent.componentState.boxState.contentMinHeight).valueOrDefault(None());
    const contentMinWidth = this.parent.definition.sizeProperties.contentMinWidth.currentValue(() => this.parent.componentState.boxState.contentMinWidth).valueOrDefault(None());

    ComponentViewModelUtils.toPaddingsCss(childrenPlainCssBuilder, this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider, this.definition.paddingsProperties(this.parent.definition.properties), this.componentState.paddingsState);

    const headerMinHeight = Some("2.625rem");

    CssUtils.childrenPlainMinHeight(childrenPlainCssBuilder,
      contentMinHeight.map(v => SizeProperty.sizeToCss(unit, v)),
      headerMinHeight, true,
      Some(this.childrenPanelDeltaHeightRem + "rem")
    );

    CssUtils.childrenPlainMinWidth(childrenPlainCssBuilder,
      contentMinWidth.orElse(Some("1cm")).map(v => SizeProperty.sizeToCss(unit, v))
    );

    const backgroundColorValue = this.definition.backgroundsProperties(this.parent.definition.properties).backgroundColor(this.parent.skinName, this.parent.typeName, this.parent.componentClass, this.parent.defaultPropertyProvider).currentValue(() => this.componentState.backgroundsState.backgroundColor).valueOrDefault(None());
    CssUtils.backgroundColor(childrenPlainCssBuilder, backgroundColorValue);

    if(!layout.isStatic()) {
      const gapColumn = this.definition.layoutsProperties(this.parent.definition.properties).gapColumn.currentValue(() => this.componentState.layoutsState.gapColumn).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
      const gapRow = this.definition.layoutsProperties(this.parent.definition.properties).gapRow.currentValue(() => this.componentState.layoutsState.gapRow).valueOrDefault(None()).map(v => SizeProperty.sizeToCss(unit, v));
      CssUtils.gaps(childrenPlainCssBuilder, gapRow, gapColumn);
    }

    this.childrenPlainCss = childrenPlainCssBuilder.toCss();
    this.childrenPlainCssClasses = childrenPlainCssBuilder.toCssClasses();


  }

  updateChildren(childrenContext: VariableId) {
    const children = this.definition.children.map(childRefId => {
      return __(this.children).find(child => child.ref.id.id === childRefId.id && child.context.id === childrenContext.id)
        .getOrElseLazy(() => this.parent.createViewModel(this.parent, ScreenComponentRefIdInScreen.of(this.parent.componentScreenId, childRefId), this.parent.componentState.innerContext));
    });
    overwriteArray(this.children, children);
  }


  getComponentById(id: number): Option<ScreenComponentViewModel> {
    for(let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if(child.id == id) {
        return Some(child);
      } else if(child.isContainer()) {
        const childSuccessor = (<ScreenContainerViewModel>child).getComponentById(id);
        if(childSuccessor.isDefined()) {
          return childSuccessor;
        }
      }
    }
    return None();
  }

  static create(parent: ViewSwitcherContainerViewModel, definition: ViewSwitcherViewDefinition, componentState: ViewSwitcherViewState, prefix: string) {

    return new ViewSwitcherViewViewModel(parent, definition, componentState, prefix);
  }

  clearChildren() {
    clearArray(this.children);
  }
}

export class ViewSwitcherContainerViewModel extends ScreenContainerViewModel {

  override typeName = "ViewSwitcher";

  public activeView: ViewSwitcherViewViewModel|null = null;

  public override customCssClass: string = "";
  public css: string = "";
  public cssClasses: string = "";
  public headerCss: string = "";
  public headerCssClasses: string = "";

  public activeViewIdentifier = "";
  public viewParam: BusinessVariable|null = null;
  private previousView?: {viewIdentifier: string, param: BusinessVariable|null};

  private justPoppedFromHistory?: {viewIdentifier: string, param: BusinessVariable|null};
  private navigationHistory: Array<{viewIdentifier: string, param: BusinessVariable|null}> = [];

  private readonly scrollRequest: Subject<void> = new Subject<void>();
  readonly scrollRequestObservable = this.scrollRequest.asObservable();

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              override readonly definition: ViewSwitcherContainerDefinition,
              override readonly componentScreenId: string,
              readonly ref: ViewSwitcherContainerRef,
              override readonly refScreenId: string,
              readonly componentState: ViewSwitcherContainerState,
              readonly refState: ViewSwitcherContainerRefState,
              readonly context: VariableId,
              readonly serverModel: ScreenInstanceServerModel,
              public views: Array<ViewSwitcherViewViewModel>,
              readonly navigationService: NavigationService,
              readonly createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {
    super(parent, componentState, refState, definition, shared);
  }

  getLayoutForComponent(componentRefId: ScreenComponentRefId) {
    const viewDefinition = __(this.definition.views).find(t => __(t.children).exists(c => c.id == componentRefId.id)).getOrError("No view found");
    const view = __(this.views).find(t => t.definition.id === viewDefinition.id).getOrError("No view view model found");
    const layoutName = viewDefinition.layoutsProperties(this.definition.properties)
      .layout.currentValue(() => view.componentState.layoutsState.layout)
      .valueOrDefault(LayoutType.horizontal.name)
    return LayoutType.byName(layoutName);
  }

  injectViewSwitcher(views: Array<ViewSwitcherViewViewModel>) {
    this.views = views;
    this.update();
  }

  getAllChildren(): Array<ScreenComponentViewModel> {
    return __(this.views).flatMap(t => t.children);
  }

  updateContainer(deep: boolean) {

    if (!this.uncoveredAndVisible || this.status != ComponentStatus.initialized) {
      this.views.forEach(t => t.clearChildren());
    } else {

      const headerCssBuilder = CssBuilder.create();
      const cssBuilder = CssBuilder.create();

      ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
      ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);

      ComponentViewModelUtils.toHeaderCss(headerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.headerProperties, this.componentState.headerState);


      this.headerCss = headerCssBuilder.toCss();
      this.headerCssClasses = headerCssBuilder.toCssClasses();

      this.css = cssBuilder.toCss();
      this.cssClasses = cssBuilder.toCssClasses();

      this.activeViewIdentifier = this.componentState.activeView.valueOrDefault("-none-");

      this.viewParam = this.componentState.viewParam.valueOrDefault(None()).getOrNull();

      const previousViewIdentifier = Option.of(this.previousView).map(v => v.viewIdentifier).getOrNull();
      const previousViewParam = Option.of(this.previousView).map(v => v.param).getOrNull();
      if(this.activeViewIdentifier != previousViewIdentifier || Option.of(this.viewParam).notEquals(Option.of(previousViewParam), (a, b) => a.isEqual(b)) ) {
        if(previousViewIdentifier &&
          (
            this.activeViewIdentifier !== Option.of(this.justPoppedFromHistory).map(v => v.viewIdentifier).getOrNull()
            || Option.of(this.viewParam).notEquals(Option.of(Option.of(this.justPoppedFromHistory).map(v => v.param).getOrNull()), (a, b) => a.isEqual(b))
          )
        ) { // for first state we do not want to add temporary state
          this.navigationHistory.push(required(this.previousView, "previousView"));

          this.navigationService.pushTemporaryState(() => {
            if(this.navigationHistory.length > 0) {
              this.justPoppedFromHistory = required(this.navigationHistory.pop(), "history");
              this.switchViewTo(this.justPoppedFromHistory.viewIdentifier, this.justPoppedFromHistory.param);
            }
          });
          this.scrollRequest.next();
        }

        this.justPoppedFromHistory = undefined;
        this.previousView = {viewIdentifier: this.activeViewIdentifier, param: this.viewParam};

      }

      this.views.forEach(t => t.update(deep, this.activeViewIdentifier));

      this.activeView = nullIfUndefined(this.views.find(v => v.active));



    }
    super.updatePosition();
  }


  private switchViewTo(viewIdentifier: string, param: BusinessVariable|null) {

    if(this.uncoveredAndVisible && !this.disabled) {
      this.activeViewIdentifier = viewIdentifier;
      this.viewParam = param;
      this.componentState.updateModel(ViewSwitcherContainerDefinition.ACTIVE_VIEW, Some(new StringVariable(this.activeViewIdentifier)));
      this.componentState.updateModel(ViewSwitcherContainerDefinition.VIEW_PARAM, Option.of(this.viewParam));
      this.serverModel.changeModelsWithAction(this.componentRefPath(),
        [ModelToChange.of(ViewSwitcherContainerDefinition.ACTIVE_VIEW, Some(new StringVariable(this.activeViewIdentifier))),
                 ModelToChange.of(ViewSwitcherContainerDefinition.VIEW_PARAM, Option.of(this.viewParam))], "onViewChanged");
    }
  }

  static create(shared: ScreenSharedViewModel,
                parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                context: VariableId,
                definition: ViewSwitcherContainerDefinition,
                componentScreenId: string,
                ref: ViewSwitcherContainerRef,
                refScreenId: string,
                componentState: ViewSwitcherContainerState,
                refState: ViewSwitcherContainerRefState,
                serverModel: ScreenInstanceServerModel,
                navigationService: NavigationService,
                createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {

    const viewModel =  new ViewSwitcherContainerViewModel(shared, parent, definition, componentScreenId, ref, refScreenId, componentState, refState, context, serverModel, [], navigationService, createViewModel);

    const views = definition.views.map((view, index) => {
      return ViewSwitcherViewViewModel.create(viewModel, view, new ViewSwitcherViewState(componentState.properties, index), "view|"+index+"|");
    });

    viewModel.injectViewSwitcher(views);

    return viewModel;
  }

  childDeltaHeightChanged(): void {
  }

  getComponentById(id: number): Option<ScreenComponentViewModel> {
    for(let i = 0; i < this.views.length; i++) {
      const fromEntry = this.views[i].getComponentById(id);
      if (fromEntry.isDefined()) {
        return fromEntry;
      }

    }
    return None();
  }

}
